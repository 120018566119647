/**
 * External Dependencies
 */
import React, {Component} from 'react';
/**
 * Internal Dependencies
 */
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import './style.scss';

/**
 * Component
 */
class BuyerTerms extends Component {
	render() {
		return (
			<PageWrap>
				<PageTitle>
					<h1 className="title-style">Matchly Marketplace Platform – Buyer Agreement Terms and Conditions</h1>
				</PageTitle>
				<PageContent className="buyer-terms">
					<br/>
					<p><span>This Buyer Agreement: Terms and Conditions ("</span><strong>Agreement</strong><span>") is made by and between Matchly Pty Ltd ("</span><strong>Matchly</strong><span>", &ldquo;</span><strong>us</strong><span>&rdquo;, &ldquo;</span><strong>we</strong><span>&rdquo;), and you ("</span><strong>you</strong><span>", &ldquo;</span><strong>your</strong><span>&rdquo; or &ldquo;</span><strong>Buyer</strong><span>&rdquo;, whom together with Matchly, the &ldquo;</span><strong>Parties</strong><span>&rdquo; and each a &ldquo;</span><strong>Party</strong><span>&rdquo;).&nbsp;&nbsp;</span>
					</p>
					<p><strong>Background</strong></p>
					<p>
						<span>Matchly operates the Matchly marketplace platform and related software applications (&ldquo;</span><strong>Matchly
						Marketplace Platform</strong><span>&rdquo;), and makes the service available via </span><a
						href="http://www.matchly.com.au"><span>www.matchly.com.au</span></a><span> (the "</span><strong>Matchly</strong>
						<strong>Website</strong><span>"), web scripts and other interfaces (&ldquo;</span><strong>Matchly
						API</strong><span>&rdquo;).&nbsp; The Matchly Marketplace Platform is made available to subscribers for the purpose of exchanging information about potential customers who have indicated an interest in particular types of financial (or related) products (&lsquo;</span><strong>Referrals&rsquo;</strong><span>, defined below) by comparison or other website owners (&ldquo;</span><strong>Seller(s)</strong><span>&rdquo;) to appropriately licensed lenders or other financial product providers (&ldquo;</span><strong>Buyer(s)</strong><span>&rdquo;).&nbsp; The Matchly Marketplace Platform also allows Buyers to submit and distribute product information, promotion, advertising, creative or other content (&ldquo;</span><strong>Ads</strong><span>&rdquo;) for presentation on Seller&rsquo;s websites to its audience.&nbsp; Matchly collects a service fee (&ldquo;</span><strong>Referral
						Fee</strong><span>&rdquo;) from each Referral generated by a Seller in connection with Ad campaigns created by and for the benefit of Buyer.&nbsp; If accepted by Matchly as a Buyer (in Matchly's sole and absolute discretion), Buyer agrees to take and pay for access to the Matchly Marketplace Platform and any other services (&ldquo;</span><strong>Services</strong><span>&rdquo;) subject to the terms and conditions of this Agreement and any other rules and requirements set forth by Matchly on the Matchly Website.&nbsp; Use of any of the Services shall constitute agreement with and acceptance of this Agreement.</span>
					</p>
					<p><strong>Agreed terms</strong></p>
					<ol>
						<li><strong><strong>Ads</strong></strong>
							<ol>
								<li><span>Ads may be lodged with Matchly by providing your advertising content copy, images, links, and format files.&nbsp;&nbsp;</span>
								</li>
								<li><span>Ads are subject to acceptance by Matchly.</span>
								</li>
								<li><span>Your Ads must adhere to the Matchly Ad Policies published at https://policies.matchly.com and as updated from time to time (&ldquo;</span><strong>Ad
									Policies</strong><span>&rdquo;).&nbsp; Matchly may reject or remove Ads at any time, including for non-compliance with the Ad Policies.&nbsp; Matchly will use its commercially reasonable efforts to timely notify your of any non-compliance with the Ad Policies.&nbsp; Matchly is not responsible for any errors or omissions in any Ads provided by your or on your behalf.</span>
								</li>
								<li><span>Matchly does not provide creative or design services for hire.</span>
								</li>
							</ol>
						</li>
						<li><strong><strong>Display and Billing</strong></strong>
							<ol>
								<li><span>Following Matchly&rsquo;s review and acceptance/ submission of your Ad, Matchly make the Ad available for display by Sellers on their websites or apps (&ldquo;</span><strong>Seller
									Sites</strong><span>&rdquo;), subject to you having a credit balance in your Matchly account (&ldquo;</span><strong>Account</strong><span
								>&rdquo;) or, if available, your having provided valid, up-to-date and complete credit card details.&nbsp;&nbsp;</span>
								</li>
								<li><span>Bids and budgets are</span> <span
								>set by you in the Matchly Marketplace Platform. Referral Fees are determined by the bid price you nominate plus an additional commission amount.&nbsp;&nbsp;&nbsp;</span>
								</li>
								<li><span>You agree to pay us Referral Fees when a website visitor engages with your Ad on Seller Sites in the specified manner, such as clicks, actions or referrals generated (&ldquo;</span><strong>Referrals</strong><span
								>&rdquo;).&nbsp;&nbsp;</span></li>
								<li><span>Matchly does not guarantee that your Ad will receive impressions or be displayed at any given associated price, any minimum number of impressions or Referrals or the performance of any Ad or Referral.</span>
								</li>
								<li><span>You acknowledge and agree that while Matchly will use its commercially reasonable efforts to implement Ads as directed by you and accepted by Matchly, the accuracy of end-user data is outside of Matchly&rsquo;s control, and Matchly makes no representation, warranty or guarantee as to the accuracy of such data.&nbsp;&nbsp;</span>
								</li>
							</ol>
						</li>
						<li><strong><strong>Payment</strong></strong>
							<ol>
								<li><span>By creating, publishing or activating an Ad within the Matchly Marketplace Platform, Buyer is confirming the terms of the Ad and agrees to pay Referral Fees for Referrals generated and any other applicable fees notified to you (&ldquo;</span><strong>Fees</strong><span
								>&rdquo;) plus applicable taxes (such as GST or VAT).&nbsp;&nbsp;</span>
								</li>
								<li><span>You authorise us to deduct any Fees payable by you from your Matchly Account (if in credit) or otherwise via your nominated payment method.</span>
								</li>
								<li><span>The Matchly Marketplace Platform will be used for calculation of all Fees, including whether a valid Referral has been generated.&nbsp; No disputes or returns will be considered under any circumstances.</span>
								</li>
								<li><span>If you set a daily or other cap on spend, you understand and agree that while such caps are set on a &lsquo;best efforts&rsquo; basis only and Matchly provides no guarantees that actual daily spend will not exceed or your desired daily budget.&nbsp;&nbsp;</span>
								</li>
								<li><span>Where any payment owing to Matchly by you is overdue, Matchly may, without prejudice to any other rights and remedies of Matchly and without liability to you, remove and suspend the placement of your Ads, disable your password, account and access to all or part of the Services and Matchly shall be under no obligation to provide any or all of the Services while the payment is owing.&nbsp;&nbsp;</span>
								</li>
								<li><span>If your Matchly Account goes into a negative balance, you agree that we may debit your nominated payment method to bring the Account back to zero.&nbsp; If we are unable to debit your nominated payment method, we may seek to collect those monies from you via other methods and may elect to treat the failure to pay as a repudiation of these Terms.&nbsp;&nbsp;</span>
								</li>
								<li><span>Without limiting Matchly&rsquo;s rights or remedies, Matchly reserves the right to charge interest on any overdue payments at the rate of 2% of the outstanding balance per month, or the maximum rate permitted by law, whichever is lower.&nbsp; You agree to reimburse Matchly for any legal and recovery costs incurred by Matchly in enforcing any overdue payments or debt you owe Matchly.&nbsp;&nbsp;</span>
								</li>
								<li><span>Matchly may set-off monies received from, or owed by, you against any amounts payable under this or other agreements Matchly has with You.</span>
								</li>
								<li><span>You are responsible for all activity that occurs under your Account, including any activity which incurs Fees, and you agree to maintain the security and secrecy of your Account username and password at all times.&nbsp; Matchly takes no responsibility for unauthorised uses of your Account.&nbsp; You may not assign or otherwise transfer your Account to any other entity, without Matchly&rsquo;s prior written consent.</span>
								</li>
								<li><span>The amounts that we charge you may be subject to and include applicable taxes and levies, including without limitation withholding taxes.&nbsp; You are responsible for bearing and remitting any taxes that apply to your transactions.&nbsp; You will indemnify and hold us harmless from and against any claim arising out of your failure to do so.</span>
								</li>
							</ol>
						</li>
						<li><strong><strong>Cancellation</strong></strong>
							<ol>
								<li><span>You can cancel an Ad at any time, but your Ad may run for 24 hours after you notify us, and you are still responsible for paying for all Ads that run.</span>
								</li>
							</ol>
						</li>
						<li><strong><strong>Buyer&rsquo;s Obligations</strong></strong>
							<ol>
								<li><span>You represent and warrant to Matchly that:</span>
									<ol style={{listStyleType: "lower-latin"}}>
										<li><span>You hold all necessary licences, permits and authorisations necessary for the performance of your obligations under this Agreement or that are required to carry out the services or provide the product included in or promoted by your Ads.</span>
										</li>
										<li><span>You own or have acquired all of the rights, interests, licences and authorisations in and to the Ads (including without limitation all copyrights, trademarks and all other intellectual property rights) necessary to make the Ads available to Matchly and for Matchly to provide the Services and implement the Ads and can verify this on request; and</span>
										</li>
										<li><span>in connection with Matchly&rsquo;s performance of the Services and display and/or implementation of the Ads, none of your Ads will:&nbsp;</span>
											<ol style={{listStyleType: "lower-roman"}}>
												<li><span>infringe or misappropriate any copyright, trademark, trade secret, patent or any other intellectual property right of a third party;&nbsp;</span>
												</li>
												<li><span>constitute passing off, defamation, breach of confidence or privacy or contravention of any other law or legal or equitable right; or&nbsp;</span>
												</li>
												<li><span>contain any material or information which is defamatory, obscene, fraudulent or misleading, or violates any law, rule or regulation, including without limitation any law, rule or regulation relating to privacy, false advertising or consumer protection.&nbsp;&nbsp;</span>
												</li>
											</ol>
										</li>
									</ol>
								</li>
								<li><span>You acknowledge and agree that:</span></li>
								<ol>
									<li><span>You will comply with all applicable laws and regulations when using the Services, and you may only use the Services for lawful purposes;&nbsp;</span>
									</li>
									<li><span>You will comply with Matchly Ad Policies and other policies related to the Matchly Marketplace Platform notified to you from time to time;</span>
									</li>
									<li><span>You will display a clearly labelled and easily accessible privacy policy on any website or other asset to which an end user may be directed from the Ads, which privacy policy should comply with applicable laws and regulations, including providing end-users with clear and comprehensive information about information stored on, accessed on, or collected from end users&rsquo; devices in connection with the site(s) including cookies, device-specific information, location information and other information.&nbsp; Any personal Information or personal data you may receive that is collected from end-users via an Ad on a Buyer&rsquo;s site may not be disclosed, sold, rented, forwarded, made public or shared in any way with any other third party or used other than to facilitate the services that the end user has consented to participate in or receive from you.&nbsp; &ldquo;</span><strong>Personal
										information</strong><span>&rdquo; or &ldquo;</span><strong>personal
										data</strong><span>&rdquo; means information about an individual that identifies them, or from which the person&rsquo;s identity can be reasonably determined, and shall include "personal data" within the meaning of Australian privacy law or any other applicable law.&nbsp; Examples of personal information include name, address, email address and phone number;&nbsp;</span>
									</li>
									<li><span>Matchly may control the display of Ads in its sole and absolute discretion, including preventing the display of an Ad where you or the Ad is included by Seller on any exclusion list of companies, content or product verticals;</span>
									</li>
									<li><span>Systems or technological failures, service interruptions, data transmission errors or any other loss or damage resulting from the transfer of data over communications networks and facilities, including the internet (collectively, &ldquo;</span><strong>Delays</strong><span
										className="font-weight-bold">&rdquo;) may impede or prevent access to the Services or your Ads from time to time.&nbsp; Matchly shall not be liable to you for any such Delays;</span>
									</li>
									<li><span>Matchly may modify the organisation, structure, &ldquo;look and feel&rdquo; and other elements of the Matchly Marketplace Platform at its sole discretion; and</span>
									</li>
									<li><span>Matchly will provide Ad performance data in substance and form as determined by Matchly in its sole discretion.&nbsp; You shall not distribute or disclose performance data to any third party without Matchly&rsquo;s prior written consent.&nbsp; Matchly makes no, and expressly disclaims all, representations, warranties or guarantees regarding the accuracy, reliability or completeness of any performance data provided to You.</span>
									</li>
								</ol>
							</ol>
						</li>
						<li><strong><strong>Termination, Suspension and Non-Availability</strong></strong>
							<ol>
								<li><span>Without limiting its other rights, Matchly may, upon notice to You, immediately suspend the placement of an Ad or terminate this Agreement if:</span>
								</li>
								<ol>
									<li><span>You enter into bankruptcy, liquidation, administration, receivership, a composition or arrangement with your creditors, have a receiver or manager appointed over all or any part of your assets or become or are deemed to become insolvent;</span>
									</li>
									<li><span>You die, or if you are a partnership are dissolved or an application to dissolve the partnership is filed, or if you are a company, the company is wound up or an application for the company&rsquo;s winding up is filed;&nbsp;</span>
									</li>
									<li><span>You suspend or cease, or threaten to suspend or cease, carrying on all or a substantial part of your business;&nbsp;</span>
									</li>
									<li><span>any warranty or acknowledgement given by you in this Agreement is found to be untrue or misleading;&nbsp;</span>
									</li>
									<li><span>You fail to comply with applicable privacy requirements set out in clause 9; or</span>
									</li>
									<li><span>You misuse the Matchly Marketplace Platform; bring Matchly, its Sellers or other Buyers into disrepute; or otherwise in Matchly&rsquo;s discretion.</span>
									</li>
								</ol>
								<li><span>Either party may terminate this Agreement where the other party is in material breach of this Agreement by providing written notice to such other party.&nbsp; A party will be considered to be in material breach where (i) an administrator, liquidator or receiver is appointed to the party, or (ii) the party has committed a breach which cannot be rectified within 7 days of notice in writing specifying the breach having been given by the party not in default.</span>
								</li>
								<li><span>On termination of this Agreement for any reason:</span>
								</li>
								<ol>
									<li><span>all licences we have granted to you under this Agreement shall immediately terminate and the Buyer shall immediately cease all use of the Services;&nbsp;</span>
									</li>
									<li><span>any rights, remedies, obligations or liabilities of the Parties that have accrued up to the date of termination, including the right to claim damages in respect of any breach of the agreement which existed at or before the date of termination shall not be affected or prejudiced; and</span>
									</li>
									<li><span>you must pay all outstanding amounts for Services performed to Matchly within seven (7) days of termination.</span>
									</li>
								</ol>
							</ol>
						</li>
						<li><strong><strong>Limitation of Liability</strong></strong>
							<ol>
								<li><span>Except as expressly and specifically provided in this Agreement:</span>
								</li>
								<ol>
									<li><span>the Buyer assumes sole responsibility for results obtained from the use of the Services by the Buyer, and for conclusions drawn from such use.&nbsp; Matchly shall have no liability for any damage caused by errors or omissions in any information, instructions scripts or interfaces provided to Matchly by the Buyer in connection with the Services, or any actions taken by Matchly at the Buyer's direction;</span>
									</li>
									<li><span>all warranties, representations, conditions and all other terms of any kind whatsoever implied by statute or common law are, to the fullest extent permitted by applicable law, excluded from this Agreement; and</span>
									</li>
									<li><span>the Services are provided to the Buyer on an "as is" basis.</span>
									</li>
								</ol>
								<li>
									<span>This clause 7 does not exclude or limit the application of any statute where to do so would:</span>
								</li>
								<ol>
									<li><span>contravene that statute; or</span>
									</li>
									<li><span>cause any part of this Agreement to be void.&nbsp;&nbsp;</span>
									</li>
								</ol>
								<li><span>In no event will Matchly be liable to you in an action under tort, contract, warranty, breach of statutory duty, misrepresentation, restitution or any other theory of liability for any special, indirect, consequential, incidental or punitive/exemplary damages or losses arising from or related to a breach of this Agreement, the display or implementation of any Ad, or the performance of the Services, including, without limitation, such damages or losses arising from (i) loss of business, profit or revenues, (ii) loss of content, data, or programming (iii) failure to realise savings or other benefits, (iv) substitute procurement, (v) damage to equipment or systems or (vi) any ad content suggested by Matchly, in each case incurred by you or any third party, even if Matchly has been advised of the possibility of such damages or losses or if such damages or losses are foreseeable.&nbsp; You are wholly responsible for the conduct and content of your ad, including any and all content presented via your account, including ad content provided by Matchly.&nbsp; Matchly&rsquo;s total liability to you for damages or losses in connection with any ad, Matchly&rsquo;s performance of the Services or under your agreement with Matchly, whether in an action under tort, contract, warranty, breach of statutory duty, misrepresentation, restitution or other theory of liability, will in no event exceed the amount actually paid by you for the applicable ad or service, or the amount paid by you in the three (3) months preceding the incident, whichever is less.&nbsp;&nbsp;</span>
								</li>
							</ol>
						</li>
						<li><strong><strong>Indemnification</strong></strong>
							<ol>
								<li><span>You shall at all times indemnify and hold harmless Matchly, its Affiliates (defined below), any related bodies corporate, its directors, employees, servants and agents and Sellers (the &ldquo;Matchly Indemnified Parties&rdquo;) from and against, and shall reimburse each such Matchly Indemnified Party for, any loss, expense, including reasonable costs of litigation or arbitration, damages, judgements, settlements or other liability incurred or suffered by the Matchly Indemnified Parties arising from any claim, demand, action, suit or proceedings in relation to an Ad, including but not limited to those relating to the conduct or content of an Ad, or otherwise arising from:</span>
								</li>
								<ol>
									<li><span
										className="font-weight-bold">your breach of the Ad Policies;</span></li>
									<li><span>any claim that your Ads infringe or misappropriate any copyright, trademark, trade secret, patent or any other intellectual property of any third party;</span>
									</li>
									<li>
										<span>any material breach by you of any duty, representation or warranty under this Agreement; and</span>
									</li>
									<li><span>any contaminated file, virus, Trojan horse or any other malware originating from your Ads or any linked pages.</span>
									</li>
								</ol>
								<li><span>Matchly shall at all times indemnify and hold harmless You, your related bodies corporate, your directors, employees, servants and agents (the &ldquo;</span><strong>Buyer
									Indemnified Parties</strong><span>&rdquo;) from and against, and shall reimburse each such Buyer Indemnified Party for, any loss, expense, including reasonable and documented out-of-pocket costs of litigation or arbitration, damages, judgements, settlements or other liability reasonably incurred or suffered by Buyer Indemnified Parties arising from any claim, demand, action, suit or proceedings brought by a third party in relation to:&nbsp;</span>
								</li>
								<ol>
									<li><span>any claim that Matchly&rsquo;s platform infringes or misappropriate any copyright, trademark, trade secret, patent or any other intellectual property of any third party;</span>
									</li>
									<li><span>arising out of any material breach by Matchly of any duty, representation or warranty under this Agreement; or</span>
									</li>
									<li><span>relating to any contaminated file, virus, Trojan horse or any other malware originating from the Matchly Marketplace Platform.</span>
									</li>
								</ol>
								<li><span>The party seeking indemnification hereunder shall give prompt written notice, cooperation and assistance to the indemnifying party relative to any such claim, action, or suit and the indemnifying party shall have the option to undertake and conduct the defence of such claim or suit (including, without limitation, selecting in its sole discretion, counsel therefor) and to engage in settlement thereof, subject to the indemnified party's prior approval of any such settlement not to be unreasonably withheld.&nbsp;&nbsp;</span>
								</li>
							</ol>
						</li>
						<li><strong><strong>Privacy&nbsp;</strong></strong>
							<ol>
								<li><span>All data or other information which is supplied from time to time by Matchly to you in relation to the Services or any Ad, end user or website visitor, including performance data ("</span><strong>Matchly
									Data</strong><span>"), is supplied to you specifically for the specific purpose of its use in connection with the Services, and for no other purpose.&nbsp; The use and disclosure of such data will also be subject to such limitations for the use and disclosure of such data as apply at the time of collection of such data.</span>
								</li>
								<li><span>Both Parties will comply with all applicable requirements of applicable privacy, electronic communications, spam, cookie, data protection, telemarketing, do‐not‐call or other applicable legislation.&nbsp; This clause 9 is in addition to, and does not relieve, remove or replace, a party's obligations or rights under the </span><em><span>Privacy Act 1998</span></em><span> (Cth).</span>
								</li>
								<li>
									<span>Where you provide data (including any personal information) to Matchly during the term ("</span><strong>Buyer
									Data</strong><span>"), you warrant that you are authorised to disclose that Buyer Data to Matchly in accordance with your own privacy policy and applicable privacy and data protection laws.</span>
								</li>
								<li><span>Each party shall ensure that it has in place appropriate technical and organisational measures, to protect against unauthorised or unlawful processing of personal data and against accidental loss or destruction of, or damage to, personal data, appropriate to the harm that might result from the unauthorised or unlawful processing or accidental loss, destruction or damage and the nature of the data to be protected, having regard to the state of technological development and the cost of implementing any measures (those measures may include, where appropriate, pseudonymising and encrypting personal data, ensuring confidentiality, integrity, availability and resilience of its systems and services, ensuring that availability of and access to personal data can be restored in a timely manner after an incident, and regularly assessing and evaluating the effectiveness of the technical and organisational measures adopted by it).&nbsp;&nbsp;</span>
								</li>
								<li><span>Matchly shall, in providing the Services, comply with its Privacy Policy relating to the privacy and security of the Buyer Data available at </span><span>https://matchly.com.au/privacy</span><span> or such other website address as may be notified to the Buyer from time to time, as such document may be amended from time to time by Matchly in its sole discretion.</span>
								</li>
							</ol>
						</li>
						<li><strong><strong>Confidentiality</strong></strong>
							<ol>
								<li><span>For the purposes of this Agreement, "</span><strong>Confidential
									Information</strong><span>" means this Agreement, the Matchly Data, the Buyer Data and all information about the disclosing party's business or activities that is proprietary or by its nature confidential.&nbsp; This includes, without limitation, (i) all business, financial, technical and other information of a party marked or designated by such party as "confidential" or "proprietary" at the time of disclosure, or (ii) by the nature of the circumstances surrounding disclosure, ought to be treated as &ldquo;confidential&rdquo; or &ldquo;proprietary&rdquo;.</span>
								</li>
								<li><span>Each party will use commercially reasonable efforts to maintain all Confidential Information of the other party in confidence and will safeguard such Confidential Information with the same care as its own confidential information.&nbsp; Each party may use such Confidential Information only for the purposes of performing that party&rsquo;s obligations under this Agreement, subject to the remaining provisions of this clause 10.</span>
								</li>
								<li><span>Confidential Information will not include information that (i) is in or enters the public domain without breach of this Agreement, (ii) the receiving party lawfully receives from a third party without restriction on disclosure and without breach of a non-disclosure obligation, (iii) the receiving party rightfully knew prior to receiving such information from the disclosing party or (iv) the receiving party develops independently of any information originating from the disclosing party.</span>
								</li>
								<li><span>Each party agrees (i) that it will not disclose to any third party or use any Confidential Information disclosed to it by the other except as expressly permitted in this Agreement and (ii) that it will take all reasonable measures to maintain the confidentiality of all Confidential Information of the other party in its possession or control, which will in no event be less than the measures it uses to maintain the confidentiality of its own information of similar importance.&nbsp; Notwithstanding the foregoing, each party may disclose Confidential Information (i) to the extent required by a court of competent jurisdiction or other governmental authority or otherwise as required by law; (ii) on a "need-to-know" basis under an obligation of confidentiality to its subcontractors, legal counsel, accountants, banks and other financing resources and their advisors, or (iii) to members of its own corporate group for the purposes of performing its obligations under this Agreement.</span>
								</li>
								<li><span>Each party acknowledges and agrees that the Ads will be displayed on and disclosed to Matchly's Sellers and that Matchly will not be restricted from doing so by virtue of this clause 10.</span>
								</li>
								<li><span>Each party acknowledges that any material violation of a party of the rights and obligations provided in this clause 10 may result in immediate and irreparable injury to the other party, and hereby agrees that the other party may be entitled to seek immediate temporary, preliminary and permanent injunctive relief against any continued violations upon adequate proof, as required by law.</span>
								</li>
							</ol>
						</li>

						<li><strong><strong>Intellectual Property Rights</strong></strong>
							<ol>
								<li><span>You acknowledge and agrees that as between you on the one hand, and Matchly on the other, Matchly owns all right, title and interest in the Matchly Data, Matchly Marketplace Platform, Matchly Website, Matchly API and all software, tools, reporting or websites (including code or the use of any Matchly Marketplace Platform) ("</span><strong>Matchly
									Software</strong><span>") provided in connection with this Agreement.</span></li>
								<li><span>Matchly acknowledges and agrees that as between Matchly on the one hand, and you on the other, you own all right, title and interest in the Ads and the Buyer Data.&nbsp;&nbsp;</span>
								</li>
								<li><span>Other than as expressly set out in this Agreement, neither Party has or will acquire any right, title or interest in any intellectual property rights (including any patents, copyright, database right, trade secrets, trade names, trademarks (whether registered or unregistered)) owned or licensed by the other party or by any Seller.</span>
								</li>
								<li><span>Matchly grants you a royalty free, non-exclusive, non-sublicensable license to use the Matchly Software solely as necessary for the provision of the Services.&nbsp; Except for the foregoing license, you have no other rights in the Matchly Software and may not modify, edit, copy, reproduce, create derivative works of, reverse engineer, alter, enhance or in any way exploit the Matchly Software in any manner.&nbsp; On expiration or termination of this Agreement, you will cease using the Matchly Software.&nbsp;&nbsp;</span>
								</li>
								<li><span>You grant to Matchly a non-exclusive, royalty-free, worldwide, perpetual license to use the Ads for the purposes of providing the Services (including for the purposes of displaying Ads on its Seller&rsquo;s websites).&nbsp; On expiration or termination of this Agreement, Matchly will cease displaying the Ads.</span>
								</li>
							</ol>
						</li>
						<li><strong><strong>Force majeure</strong></strong>
							<ul>
								<li>
									<span>Matchly shall have no liability to the Buyer under this Agreement if it is prevented from or delayed in performing its obligations under this Agreement, or from carrying on its business, by acts, events, omissions or accidents beyond its reasonable control, including, without limitation, strikes, lock-outs or other industrial disputes (whether involving the workforce of Matchly or any other party), failure of a utility service or transport or telecommunications network, act of God, war, riot, civil commotion, malicious damage, compliance with any law or governmental order, rule, regulation or direction, accident, breakdown of plant or machinery, fire, flood, storm or default of suppliers or sub-contractors, provided that the Buyer is notified of such an event and its expected duration.</span>
								</li>
							</ul>
						</li>
						<li><strong><strong>Disclaimers</strong></strong>
							<ol>
								<li><span>Except as expressly provided herein, neither party makes any warranty of any kind, whether express, implied, statutory or otherwise, and each party specifically disclaims all implied warranties, including any implied warranty of merchantability, fitness for a particular purpose or non-infringement, to the maximum extent permitted by applicable law.&nbsp; Matchly specifically disclaims any warranty, representation or guarantee regarding:</span>
								</li>
								<ol>
									<li>
										<span>the number of persons who will access, &ldquo;accept&rdquo;, &ldquo;click-through&rdquo;, &ldquo;convert&rdquo;, &ldquo;open&rdquo; or are &ldquo;contactable&rdquo; from your Ads;</span>
									</li>
									<li><span>any benefit you might obtain from including the Ad within the Services; and</span>
									</li>
									<li><span>the functionality, performance or operation of the Services with respect to the Ad.</span>
									</li>
								</ol>
							</ol>
						</li>
						<li><strong><strong>General</strong></strong>
							<ol>
								<li><span>(</span><strong>Authority</strong><span>) If you are accepting this Agreement on behalf of a company or other legal entity (whether as an authorised employee, attorney or agent), you represent that you have the authority to bind such entity and its Affiliates to this Agreement, in which case the terms "you" or "your" in this Agreement refers to such entity and its Affiliates.&nbsp; If you do not have such authority, or if you do not agree with this Agreement, you must not accept this Agreement and may not use the services.&nbsp; In this Agreement, an "</span><strong>Affiliate</strong><span>" of a party means any entity that directly or indirectly controls, is controlled by, or is under common control with such party (&ldquo;</span><strong>control</strong><span>&rdquo; of an entity meaning having &gt;50% ownership or the right to direct the management of the entity).&nbsp;&nbsp;</span>
								</li>
								<li><span>(</span><strong>Marketing
									Consent</strong><span>) You may elect to receive Matchly marketing materials that Matchly sends from time to time.&nbsp; You also may unsubscribe from these materials at any time.&nbsp; By agreeing to this Agreement, you also acknowledge that you will receive certain service, billing and administrative messages to your nominated email or other address that may not be unsubscribed from.</span>
								</li>
								<li><span>(</span><strong>Modification</strong><span>) Matchly may modify this Agreement or any additional terms that apply to the Services to, for example, reflect changes to the Services or changes to the law.&nbsp; You should look at these Terms regularly.&nbsp; Matchly will post notice of material modifications to this Agreement on the Matchly Marketplace Platform or policies referenced in this Agreement at applicable URLs for such policies.&nbsp; Changes will not apply retroactively and will become effective no sooner than 14 days after they are posted.&nbsp; If you do not agree to the modified Agreement, you should discontinue your use of the Services.&nbsp; No amendment to or modification of this Agreement by Matchly will be binding unless (i) you accept updated Terms online, or (ii) you continue to use the Services after Matchly has posted updates to this Agreement or to any policy governing the Service.&nbsp; No amendment to or modification of this Agreement by you shall be binding unless in writing and signed by a duly authorised representative of Matchly (in which case those Terms shall prevail to the extent of any inconsistency).</span>
								</li>
								<li><span>(</span><strong>Survival,
									Waiver</strong><span>) Clauses 5 through 14 (inclusive) will survive the termination or fulfillment of this Agreement.&nbsp; No delay or failure by a party to enforce any provision of this Agreement will be deemed to be a waiver, create a precedent or prejudice such party.</span>
								</li>
								<li><span>(</span><strong>Notices</strong><span>) </span><em><span>A</span></em><span> notice may be sent by one party to the other by e-mail or prepaid post.&nbsp; In the case of you sending notice to Matchly, to info@matchly.com or the address listed on our website, or in the case of Matchly sending notice to You, by your e-mail or last known address as specified on the Matchly Marketplace Platform.</span>
								</li>
								<li><span>(</span><strong>Assignment</strong><span>) You may not assign, transfer, charge, sub-contract or deal in this Agreement, or any document referred to in this Agreement, without Matchly&rsquo;s prior written consent.&nbsp; Any purported assignment made without the required consent from Matchly will be null and void.&nbsp; Matchly may at any time assign, transfer, charge, sub-contract or deal in any other manner with all or any of its rights or obligations under this Agreement.</span>
								</li>
								<li><span>(</span><strong>Relationship</strong><span>) The Parties to this Agreement are independent contractors.&nbsp; Neither party is an agent, employee, fiduciary, authorised representative, franchise, joint venture, or partner of the other party.</span>
								</li>
								<li><span>(</span><strong>Entire
									Agreement</strong><span>) This Agreement, and all other documents referred to in them, comprise the entire agreement between the Parties.&nbsp; They supersede all prior understandings, agreements or representations.&nbsp; The terms or conditions of any purchase order issued by you in connection with any Ad, Matchly Marketplace Platform or other Services will not apply.</span>
								</li>
								<li><span>(</span><strong>Severance</strong><span>) Each provision of this Agreement is severable from every other provision in determining the enforceability of any provision.&nbsp;&nbsp;</span>
								</li>
								<li><span>(</span><strong>Choice of
									Law</strong><span>) This Agreement and any dispute or claim arising out of or in connection with it or its subject matter or formation (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of New South Wales.&nbsp; Each party irrevocably agrees that the courts of New South Wales shall have exclusive jurisdiction to settle any dispute or claim arising out of or in connection with this Agreement or its subject matter or formation (including non-contractual disputes or claims).</span>
								</li>
								<li><span>(</span><strong>Dispute</strong><span>) If a dispute arises between the Parties, the Parties agree to give the other party written notice specifying the nature of the dispute.&nbsp; Prior to commencing proceedings in relation to such breach the Parties agree to engage in mediation in accordance with the Law Society of NSW Rules at an agreed location in Sydney, NSW by a mediator, if one cannot be jointly agreed, to be appointed by the President of the Law Society of NSW.&nbsp; This clause does not apply to debt recovery or an application for urgent interlocutory relief.&nbsp;&nbsp;</span>
								</li>
							</ol>
						</li>
					</ol>
				</PageContent>
			</PageWrap>
		);
	}
}

export default BuyerTerms;
