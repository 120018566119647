/**
 * Styles.
 */
import './style.scss';
/**
 * External Dependencies
 */
import React, {Component} from 'react';
/**
 * Internal Dependencies
 */
import PageWrap from '../../../../components/page-wrap';
import PageTitle from '../../../../components/page-title';
import PageContent from '../../../../components/page-content';
import {getSellerTransactions} from "../../../../api/seller/account";
import moment from 'moment';
import Icon from "../../../../components/icon";

/**
 * Component
 */
class Transactions extends Component {
	state = {
		lastTransactions: [],
		loading: true
	}


	componentDidMount() {
		getSellerTransactions()
			.then(data => this.setState({lastTransactions: data, loading: false}))
			.catch(console.log);
	}

	render() {
		const {lastTransactions} = this.state;
		return (
			<PageWrap>
				<PageTitle>
					<h1 className="account-style">Account</h1>
				</PageTitle>
				<PageContent>
					{this.state.loading ? (
						<div className="d-flex justify-content-center align-items-center">
							<div className="spinner">
								<span className="ball-1"></span>
								<span className="ball-2"></span>
								<span className="ball-3"></span>
								<span className="ball-4"></span>
								<span className="ball-5"></span>
								<span className="ball-6"></span>
								<span className="ball-7"></span>
								<span className="ball-8"></span>
							</div>
						</div>
					) : (
						<div className="row">
							<div className="col-12 col-md-12 mt-20">
								<div className="card ">
									<div className="card-body pb-2">
										<h1 className="transaction-style">Transactions</h1>
										<div className="row mb-5">
											<div className="col-md-4">
												<span style={{fontSize: "12pt"}}>Date Range</span>
											</div>
											<div className="col-md-4">
												<h6 className="date-style">Type</h6>
											</div>
											<div className="col-md-4" style={{textAlign: "left"}}>
												<span style={{fontSize: "12pt"}}>Total</span>
											</div>
										</div>
										{lastTransactions.map(t => (
											<div className="row mb-5 align-items-center">
												<div className="col-4">
													<h6 className="tot-date-style">
														{moment(t.createdOn).format("MMM DD,YYYY")}
													</h6>
												</div>
												<div className="col-4">
													<h6 className="tot-date-style">{t.type}</h6>
												</div>
												<div className="col-3" style={{textAlign: "left"}}>
                        <span style={{fontSize: "12pt"}}>
                          A${(+t.amount).toFixed(2).toLocaleString()}
                        </span>
												</div>
												<div>
													<a
														href={`https://affiliate-admin.s3-ap-southeast-2.amazonaws.com/invoices/${t.referenceId}.pdf`}
														className="btn-link"
														style={{fontSize: "12pt"}}
													>
														<Icon name="download"/>
													</a>
												</div>
											</div>
										))}
									</div>
									<hr className="m-0"/>
								</div>
							</div>
						</div>
					)}
				</PageContent>
			</PageWrap>
		);
	}
}

export default Transactions;
