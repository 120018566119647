/**
 * Styles.
 */
import './style.scss';
/**
 * External Dependencies
 */
import React, {Component} from 'react';
import {Bar, Line} from 'react-chartjs-2';
import {Button, Col, Row, Table} from 'reactstrap';
import {DateRangePicker} from 'react-dates';
import {END_DATE, START_DATE} from "react-dates/constants";
/**
 * Internal Dependencies
 */
import PageWrap from '../../../components/page-wrap';
import PageTitle from '../../../components/page-title';
import PageContent from '../../../components/page-content';
import {getDashboardData} from "../../../api/admin/dashboard";
import ColumnsModal from './EditColumns.jsx';
import moment from "moment";
import {saveDashboardColumns} from "../../../api/buyer/dashboard";


/**
 * Component
 */

const barChartOptions = {
	tooltips: {
		mode: 'nearest',
		intersect: false,
		backgroundColor: '#393f49',
		titleMarginBottom: 8,
		titleFontSize: 14,
		titleFontColor: '#f8f9fa',
		titleFontFamily: "'Open Sans', sans-serif",
		bodyFontSize: 11,
		bodyFontColor: '#d7d9e0',
		bodyFontFamily: "'Open Sans', sans-serif",
		footerMarginTop: 10,
		footerFontSize: 11,
		footerFontColor: '#f8f9fa',
		footerFontFamily: "'Open Sans', sans-serif",
		xPadding: 10,
		yPadding: 10,
		caretPadding: 5,
		cornerRadius: 4,
	},
	legend: {
		display: true,
		labels: {
			fontSize: 9,
			boxWidth: 20,
		}
	},
	scales: {
		xAxes: [{
			gridLines: {
				lineWidth: 0,
				color: 'transparent',
				zeroLineWidth: 0,
				zeroLineColor: 'transparent',
			},
			ticks: {
				fontSize: 12,
				fontColor: '#bcbec0',
				fontFamily: "'Open Sans', sans-serif",
			},
		}],
		yAxes: [{
			gridLines: {
				lineWidth: 0,
				color: 'transparent',
				zeroLineWidth: 0,
				zeroLineColor: 'transparent',
			},
			ticks: {
				beginAtZero: true,
				precision: 0,
				fontSize: 12,
				fontColor: '#bcbec0',
				fontFamily: "'Open Sans', sans-serif",
			},
		}],
	},
};
const lineOptions = {
	tooltips: {
		mode: 'nearest',
		intersect: false,
		backgroundColor: '#393f49',
		titleMarginBottom: 8,
		titleFontSize: 14,
		titleFontColor: '#f8f9fa',
		titleFontFamily: "'Open Sans', sans-serif",
		bodyFontSize: 11,
		bodyFontColor: '#d7d9e0',
		bodyFontFamily: "'Open Sans', sans-serif",
		footerMarginTop: 10,
		footerFontSize: 11,
		footerFontColor: '#f8f9fa',
		footerFontFamily: "'Open Sans', sans-serif",
		xPadding: 10,
		yPadding: 10,
		caretPadding: 5,
		cornerRadius: 4,
	},
	legend: {
		display: true,
		labels: {
			fontSize: 9,
			boxWidth: 20,
		}
	},
	scales: {
		xAxes: [{
			gridLines: {
				lineWidth: 0,
				color: 'transparent',
				zeroLineWidth: 0,
				zeroLineColor: 'transparent',
			},
			ticks: {
				fontSize: 12,
				fontColor: '#bcbec0',
				fontFamily: "'Open Sans', sans-serif",
			},
		}],
		yAxes: [{
			gridLines: {
				lineWidth: 0,
				color: 'transparent',
				zeroLineWidth: 0,
				zeroLineColor: 'transparent',
			},
			ticks: {
				stepSize: 20,
				callback: function (label, index, labels) {
					return `${label}%`;
				},
				fontSize: 12,
				fontColor: '#bcbec0',
				fontFamily: "'Open Sans', sans-serif",
			},
		}],
	},
}


class Dashboard extends Component {
	state = {
		isOpen: false,
		membersSummary: [],
		clickThroughData: [],
		referralRateData: [],
		clicksChartData: [],
		referralsChartData: [],
		dateFrom: moment().add(-7, 'd'),
		dateTo: moment(),
		isLoading: true,
		editColumns: {
			seller: true,
			buyer: true,
			uniqueBidRequest: true,
			impressions: true,
			defaultImpressions: true,
			clicks: true,
			referrals: true,
			clickRate: true,
			referralRate: true,
			costPerReferral: true,
			totalCost: true,
			formCompletions: true,
			costPerFormCompletions: true,
			commission: true,


		}
	}

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		getDashboardData().then(console.log);
		this.getData(this.state.dateFrom, this.state.dateTo);
	}

	handleDateChange = ({startDate, endDate}) => {
		this.setState(s => {
			if (s.dateFrom === startDate) {
				return {dateFrom: startDate, dateTo: endDate}
			}
			return {dateFrom: startDate, dateTo: null,}
		});
		if (!startDate || !endDate || startDate !== this.state.dateFrom) return;
		this.setState({isLoading: true});
		this.getData(startDate, endDate);
	}

	isOutsideRange = day => {
		// if (this.state.dateFrom && this.state.dateTo) return false;
		// if (day.isBefore(this.state.dateFrom)) return false;
		// const lastDate = moment(this.state.dateFrom).add(7, 'd');
		// return !day.isSameOrBefore(lastDate);
		return false;
	}


	getData = (dateFrom, dateTo) => {
		this.setState({isLoading: true});
		getDashboardData(encodeURIComponent(dateFrom.startOf('day').toISOString(true))
			, encodeURIComponent(dateTo.endOf('day').toISOString(true))).then(data => {
			let {totalImpressions, totalClicks, totalReferrals, totalCost, totalFormCompletions, totalCommission, totalDefaultImpressions} = data.membersSummary
				.reduce((p, c) => ({
					totalImpressions: p.totalImpressions + +c.impressions,
					totalDefaultImpressions: p.totalDefaultImpressions + (+c.defaultImpressions || 0),
					totalClicks: p.totalClicks + +c.clicks,
					totalReferrals: p.totalReferrals + +c.referrals,
					totalCost: p.totalCost + +c.totalCost,
					totalFormCompletions: p.totalFormCompletions + +c.totalFormCompletions,
					totalCommission: p.totalCommission + +c.commission
				}), {
					totalImpressions: 0,
					totalClicks: 0,
					totalReferrals: 0,
					totalCost: 0,
					totalCommission: 0,
					totalFormCompletions: 0,
					totalFormCompletionsRate: 0,
					totalCostFormCompletions: 0, totalDefaultImpressions: 0
				});

			this.setState({
				membersSummary: data.membersSummary,
				budget: data.budget,
				editColumns: JSON.parse(data.dashboardColumns),
				budgetType: data.budgetType,
				lineData: data.lineData,
				barData: data.barData,
				isLoading: false,
				totalImpressions,
				totalClicks,
				totalReferrals,
				totalCost,
				totalFormCompletions,
				totalCommission,
				totalDefaultImpressions
			});
		});
	}


	columnsModalToggler = () => {
		this.setState({isOpen: !this.state.isOpen});
	}


	onCheckHandler = (data) => {
		this.setState({isOpen: !this.state.isOpen});
		this.setState({editColumns: data});
		saveDashboardColumns({dashboardColumns: data})
	}

	render() {
		const {isLoading, editColumns, membersSummary, lineData, barData, totalImpressions, totalDefaultImpressions, totalClicks, totalReferrals, totalCost, totalFormCompletions,} = this.state;
		return (
			<PageWrap>
				<PageTitle>
					<div className="row justify-content-between" style={{paddingBottom: "30px"}}>
						<span className="h1 d-style">Dashboard</span>
						<div className="calendar-container">
							<DateRangePicker
								showDefaultInputIcon
								small
								displayFormat="YYYY/MM/DD"
								numberOfMonths={1}
								startDateId={START_DATE}
								minimumNights={0}
								endDateId={END_DATE}
								isOutsideRange={this.isOutsideRange}
								maxDate={this.state.maxDate}
								startDate={this.state.dateFrom} // momentPropTypes.momentObj or null,
								endDate={this.state.dateTo} // momentPropTypes.momentObj or null,
								onDatesChange={this.handleDateChange} // PropTypes.func.isRequired,
								focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
								onFocusChange={focusedInput => this.setState({focusedInput})} // PropTypes.func.isRequired,
							/>
						</div>
					</div>
				</PageTitle>
				{isLoading ?
					<div className="d-flex justify-content-center align-items-center">
						<div class="spinner">
							<span class="ball-1"></span>
							<span class="ball-2"></span>
							<span class="ball-3"></span>
							<span class="ball-4"></span>
							<span class="ball-5"></span>
							<span class="ball-6"></span>
							<span class="ball-7"></span>
							<span class="ball-8"></span>
						</div>
					</div>
					:
					<PageContent>
						<ColumnsModal
							columns={this.state.editColumns}
							getData={(data) => {
								this.onCheckHandler(data)
							}}
							isOpen={this.state.isOpen}
							isClose={this.columnsModalToggler}/>
						<Row>
							<Col lg={6}>
								<h2 className="h-style">Clicks and Referrals</h2>
								<div className="p-10 m-0 border">
									<Bar
										data={barData}
										options={barChartOptions}
									/>
								</div>
							</Col>
							<Col lg={6}>
								<h2 className="cr-style">Click Through Rate and Referral Rate</h2>
								<div className="p-10 m-0 border">
									<Line
										id="line-chart"
										options={lineOptions}
										data={lineData}
									/>
								</div>
							</Col>
						</Row>
						<div className="table-responsive">
							<div className="d-flex justify-content-end mb-8 mt-8">
								<Button className="btn-edit-style px-30" onClick={() => {
									this.columnsModalToggler()
								}}>Edit Columns</Button>
							</div>
							<div class="card table-responsive">
								<Table className="table table-hover table-sm">
									<thead class="card-header">
									{!editColumns.seller ?
										null
										:
										<th className="font-weight-bold">Seller</th>
									}
									{!editColumns.buyer ?
										null
										:
										<th className="font-weight-bold">Buyer</th>
									}
									{!editColumns.uniqueBidRequest ?
										null
										:
										<th className="font-weight-bold">Unique BidRequest</th>
									}
									{!editColumns.impressions ?
										null
										:
										<th className="font-weight-bold">Impressions</th>
									}
									{!editColumns.defaultImpressions ?
										null
										:
										<th className="font-weight-bold">Defaults</th>
									}
									{!editColumns.clicks ?
										null
										:
										<th className="font-weight-bold">Clicks</th>
									}
									{!editColumns.referrals ?
										null
										:
										<th className="font-weight-bold">Referrals</th>
									}
									{!editColumns.clickRate ?
										null
										:
										<th className="font-weight-bold">Click Rate</th>
									}
									{!editColumns.referralRate ?
										null
										:
										<th className="font-weight-bold">Referral Rate</th>
									}
									{!editColumns.costPerReferral ?
										null
										:
										<th className="font-weight-bold">Cost Per Referral</th>
									}
									{!editColumns.totalCost ?
										null
										:
										<th className="font-weight-bold">Total Cost</th>
									}
									{!editColumns.formCompletions ?
										null
										:
										<th className="font-weight-bold">Form Completions</th>
									}
									{!editColumns.costPerFormCompletions ?
										null
										:
										<th className="font-weight-bold">Cost Per Form Completions</th>
									}
									{!editColumns.commission ?
										null
										:
										<th className="font-weight-bold">Commission</th>
									}


									</thead>
									{membersSummary.map((member, index) => {
										return (
											<tbody>
											<tr>
												{!editColumns.seller ?
													null
													:
													<td>{member.sellerName}</td>
												}
												{!editColumns.buyer ?
													null
													:
													<td>{member.buyerName}</td>
												}
												{!editColumns.uniqueBidRequest ?
													null
													:
													<td>{member.requests}</td>
												}
												{!editColumns.impressions ?
													null
													:
													<td>{member.impressions}</td>
												}
												{!editColumns.defaultImpressions ?
													null
													:
													<td>{member.defaultImpressions || 0}</td>
												}
												{!editColumns.clicks ?
													null
													:
													<td>{member.clicks}</td>
												}
												{!editColumns.referrals ?
													null
													:
													<td>{member.referrals}</td>
												}
												{!editColumns.clickRate ?
													null
													:
													<td>{(+member.impressions ? ((member.clicks * 100) / member.impressions) : 0).toFixed(2)}%</td>
												}
												{!editColumns.referralRate ?
													null
													:
													<td>{(+member.impressions ? ((member.referrals * 100) / member.impressions) : 0).toFixed(2)}%</td>
												}
												{!editColumns.costPerReferral ?
													null
													:
													<td>${(+member.referrals ? ((+member.totalCost) / member.referrals) : 0).toFixed(2)}</td>
												}
												{!editColumns.totalCost ?
													null
													:
													<td>${(+member.totalCost).toFixed(2).toLocaleString()}</td>
												}
												{!editColumns.formCompletions ?
													null
													:
													<td>{member.formCompletions}</td>
												}
												{!editColumns.costPerFormCompletions ?
													null
													:
													<td>${(+member.formCompletions ? (+member.totalCost / member.formCompletions) : 0).toFixed(2)}</td>
												}
												{!editColumns.commission ?
													null
													:
													<td>{member.commission}</td>
												}

											</tr>
											</tbody>
										);
									})}
									<tr>
										{editColumns.seller ? <td><strong>Total</strong></td> : null}
										{!editColumns.buyer ?
											null
											:
											<td></td>
										}
										{!editColumns.uniqueBidRequest ?
											null
											:
											<td></td>
										}
										{!editColumns.impressions ?
											null
											:
											<td><strong>{totalImpressions}</strong></td>
										}
										{!editColumns.defaultImpressions ?
											null
											:
											<td><strong>{totalDefaultImpressions}</strong></td>
										}
										{!editColumns.clicks ?
											null
											:
											<td><strong>{totalClicks}</strong></td>
										}
										{!editColumns.referrals ?
											null
											:
											<td><strong>{totalReferrals}</strong></td>
										}
										{!editColumns.clickRate ?
											null
											:
											<td>
												<strong>{(+totalImpressions ? ((totalClicks * 100) / totalImpressions) : 0).toFixed(2)}%</strong>
											</td>
										}
										{!editColumns.referralRate ?
											null
											:
											<td>
												<strong>{(+totalImpressions ? ((totalReferrals * 100) / totalImpressions) : 0).toFixed(2)}%</strong>
											</td>
										}
										{!editColumns.costPerReferral ?
											null
											:
											<td><strong>${(+totalReferrals ? ((+totalCost) / totalReferrals) : 0).toFixed(2)}</strong></td>
										}
										{!editColumns.totalCost ?
											null
											:
											<td><strong>${(+totalCost).toFixed(2).toLocaleString()}</strong></td>
										}
										{!editColumns.formCompletions ?
											null
											:
											<td><strong>{+totalFormCompletions ? totalFormCompletions : 0}</strong></td>
										}
										{!editColumns.costPerFormCompletions ?
											null
											:
											<td>
												<strong>${totalFormCompletions > 0 ? ((+totalCost) / totalFormCompletions).toFixed(2) : '0.00'}</strong>
											</td>
										}
										{!editColumns.commission ?
											null
											:
											<td><strong>{''}</strong></td>
										}

									</tr>
								</Table>
							</div>
						</div>
					</PageContent>
				}
			</PageWrap>
		);
	}
}

export default Dashboard;
