import {API} from "aws-amplify";

export function getBudget() {
    return API.get("buyer", `/buyer/budget`, {});
}

export function updateDailyBudget(body) {
    return API.put("buyer", `/buyer/dailyBudget`, {body});
}

export function updateBudget(body) {
    return API.put("buyer", `/buyer/budget`, {body});
}


export function saveBudgetType(body) {
    return API.put("buyer", `/buyer/saveBudgetType`, {body});
}
