import React, {Component} from 'react';
import {Button, CustomInput, Modal, ModalBody, ModalFooter, Table} from 'reactstrap';

class EditColumnsModal extends Component {
    state = {
        editColumns: {}
    }

    componentDidMount() {
        const editColumns = this.props.columns;
        this.setState({ editColumns });
    }

    onCheckHandler = key => () => {
        this.setState(k => ({ editColumns: { ...k.editColumns, [key]: !k.editColumns[key] } }))
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen}>
                    <div className="modal-header">
                        <h5 className="modal-title h2">Edit Columns</h5>
                        <Button className="close" onClick=""></Button>
                    </div>
                    <ModalBody>
                        <div className="table-responsive">
                            <Table>
                                <thead>
                                    <th>
                                        <CustomInput
                                            defaultChecked={this.state.editColumns.seller}
                                            onChange={this.onCheckHandler('seller')}
                                            id="seller"
                                            type="checkbox" />
                                    </th>
                                    <th>seller</th>
                                </thead>
                                <thead>
                                    <th><CustomInput
                                        defaultChecked={this.state.editColumns.buyer}
                                        onChange={this.onCheckHandler('buyer')}
                                        id="buyer"
                                        type="checkbox" /></th>
                                    <th>Buyer</th>
                                </thead>
                                <thead>
                                    <th><CustomInput
                                        defaultChecked={this.state.editColumns.uniqueBidRequest}
                                        onChange={this.onCheckHandler('uniqueBidRequest')}
                                        id="uniqueBidRequest"
                                        type="checkbox" /></th>
                                    <th>uniqueBidRequest</th>
                                </thead>
                                <thead>
                                    <th><CustomInput
                                        defaultChecked={this.state.editColumns.impressions}
                                        onChange={this.onCheckHandler('impressions')}
                                        id="impressions"
                                        type="checkbox" /></th>
                                    <th>impressions</th>
                                </thead>
                                <thead>
                                <th><CustomInput
	                                defaultChecked={this.state.editColumns.defaultImpressions}
	                                onChange={this.onCheckHandler('defaultImpressions')}
	                                id="defaultImpressions"
	                                type="checkbox"/></th>
                                <th>defaultImpressions</th>
                                </thead>
	                            <thead>
	                            <th><CustomInput
		                            defaultChecked={this.state.editColumns.clicks}
		                            onChange={this.onCheckHandler('clicks')}
		                            id="clicks"
		                            type="checkbox" /></th>
	                            <th>Clicks</th>
	                            </thead>
                                <thead>
                                    <th><CustomInput
                                        defaultChecked={this.state.editColumns.referrals}
                                        onChange={this.onCheckHandler('referrals')}
                                        id="referrals"
                                        type="checkbox" /></th>
                                    <th>Referrals</th>
                                </thead>
                                <thead>
                                    <th><CustomInput
                                        defaultChecked={this.state.editColumns.clickRate}
                                        onChange={this.onCheckHandler('clickRate')}
                                        id="clickRate"
                                        type="checkbox" /></th>
                                    <th>ClickRate</th>
                                </thead>
                                <thead>
                                    <th><CustomInput
                                        defaultChecked={this.state.editColumns.referralRate}
                                        onChange={this.onCheckHandler('referralRate')}
                                        id="referralRate"
                                        type="checkbox" /></th>
                                    <th>ReferralRate</th>
                                </thead>
                                <thead>
                                    <th><CustomInput
                                        defaultChecked={this.state.editColumns.costPerReferral}
                                        onChange={this.onCheckHandler('costPerReferral')}
                                        id="costPerReferral"
                                        type="checkbox" /></th>
                                    <th>Cost Per Referral</th>
                                </thead>
                                <thead>
                                    <th><CustomInput
                                        defaultChecked={this.state.editColumns.totalCost}
                                        onChange={this.onCheckHandler('totalCost')}
                                        id="totalCost"
                                        type="checkbox" /></th>
                                    <th>Total Cost</th>
                                </thead>
                                <thead>
                                    <th><CustomInput
                                        defaultChecked={this.state.editColumns.formCompletions}
                                        onChange={this.onCheckHandler('formCompletions')}
                                        id="formCompletions"
                                        type="checkbox" /></th>
                                    <th>Form Completions</th>
                                </thead>
                                <thead>
                                    <th><CustomInput
                                        defaultChecked={this.state.editColumns.costPerFormCompletions}
                                        onChange={this.onCheckHandler('costPerFormCompletions')}
                                        id="costPerFormCompletions"
                                        type="checkbox" /></th>
                                    <th>Cost Per FormCompletions</th>
                                </thead>
                                <thead>
                                    <th><CustomInput
                                        defaultChecked={this.state.editColumns.commission}
                                        onChange={this.onCheckHandler('commission')}
                                        id="commission"
                                        type="checkbox" /></th>
                                    <th>Commission</th>
                                </thead>
                            </Table>
                        </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <Button color="primary" style={{color:"white"}}  onClick={() => this.props.getData(this.state.editColumns)}>Save</Button>
                        <Button className="btn-outline-primary" style={{background:"none",color:"#4FC1E9"}} onClick={this.props.isClose}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}

export default EditColumnsModal;
