import React, { Component } from 'react';
import { Button, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ClipLoader } from 'react-spinners';

const SignupSchema = Yup.object().shape({
	budget: Yup.number()
		.required('Required'),
});

class MemberBudgetValueModal extends Component {
	state = {
		budget: '',
		loading: true
	}

	componentWillReceiveProps(nextProps) {
		const { sellerBudget } = nextProps;
		this.setState({ ...sellerBudget });
	}

	render() {
		return (
			<div>
				<Formik
					ref={this.form}
					initialValues={this.state}
					enableReinitialize={true}
					validationSchema={SignupSchema}
					onSubmit={(values) => {
						this.props.updatedMemberBudget(values);
					}}>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleSubmit,
						handleBlur,
					}) => (
						<Modal isOpen={this.props.isOpen}>
							<div className="modal-header">
								<h5 className="modal-title h2">Edit Member Budget</h5>
								<Button className="close" onClick=""></Button>
							</div>
							<ModalBody>
								<FormGroup>
									<Label>Member Budget</Label>
									<Input name="budget"
										invalid={errors.budget && touched.budget}
										value={values.budget}
										onChange={handleChange}
										onBlur={handleBlur} />
									<FormFeedback>
										{errors.budget && touched.budget && errors.budget}
									</FormFeedback>
								</FormGroup>
							</ModalBody>
							<ModalFooter className="justify-content-center">
								<Button color="primary" style={{ color: "white" }} className="mr-2"
									onClick={this.props.isLoading ? null : handleSubmit}>
									{this.props.isLoading ? <ClipLoader
										sizeUnit={"px"}
										size={20}
										color={'#fff'}
										loading={true}
									/> : 'Save'}
								</Button>
								<Button className="btn-outline-primary" style={{ background: "none", color: "#4FC1E9" }}
									onClick={this.props.isClose}>Close</Button>
							</ModalFooter>
						</Modal>
					)}
				</Formik>
			</div>
		);
	}
}
export default MemberBudgetValueModal;