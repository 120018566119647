import './style.scss';
import React, { Component, Suspense, lazy } from 'react';
// import PageWrap from '../../../components/page-wrap';
// import PageTitle from '../../../components/page-title';
// import PageContent from '../../../components/page-content';
import { Table } from 'reactstrap';
import Switch from "react-switch";
import { changeStatus, getAds } from "../../../api/admin/ads";

const PageWrap = lazy(() => import('../../../components/page-wrap'));
const PageTitle = lazy(() => import('../../../components/page-title'));
const PageContent = lazy(() => import('../../../components/page-content'));

class Ads extends Component {
	state = {
		checked: false,
		loading: true,
		ads: []
	};

	componentDidMount() {
		getAds().then(data => {
			this.setState(data)
			this.setState({ loading: false })
		})
	}

	handleChange = (id, status) => {
		changeStatus({ id, status });
		this.setState(({ ads }) => {
			const ad = ads.find(p => p.id === id);
			ad.enabled = status;
			return { ads }
		});
	};

	render() {
		return (
			<Suspense fallback={<div>Ads</div>}>
				<PageWrap>
					<PageTitle>
						<h1 className="mb-2 pb-5 ads-style">Ads</h1>
					</PageTitle>
					<PageContent>
						{this.state.loading ?
							<div className="d-flex justify-content-center align-items-center" >
								<div class="spinner">
									<span class="ball-1"></span>
									<span class="ball-2"></span>
									<span class="ball-3"></span>
									<span class="ball-4"></span>
									<span class="ball-5"></span>
									<span class="ball-6"></span>
									<span class="ball-7"></span>
									<span class="ball-8"></span>
								</div>
							</div>
							:
							<div class=" table-responsive">
								<Table striped className="table table-sm">
									<thead>
										<tr>
											<th scope="col"><span className="font-weight-bold">Seller</span></th>
											<th scope="col"><span className="font-weight-bold">Buyer</span></th>
											<th scope="col"><span className="font-weight-bold">Logo</span></th>
											<th scope="col"><span className="font-weight-bold">Message</span></th>
											<th scope="col"><span className="font-weight-bold">Link</span></th>
											<th scope="col"><span className="font-weight-bold">Status</span></th>
										</tr>
									</thead>
									<tbody>
										{this.state.ads.map(a =>
											<tr>
												<td style={{ verticalAlign: "middle" }}>{a.sellerName}</td>
												<td style={{ verticalAlign: "middle" }}>{a.buyerName}</td>
												<td style={{ verticalAlign: "middle" }}>{a.logo && <img src={a.logo} alt="logo" height={50} />}</td>
												<td style={{ verticalAlign: "middle" }}>{a.message}</td>
												<td style={{ verticalAlign: "middle" }}><a href={a.link} target={a.link}>{a.link}</a></td>
												<td style={{ verticalAlign: "middle" }}>
													<Switch
														checked={a.enabled}
														onChange={() => this.handleChange(a.id, !a.enabled)}
														onColor="#D3F0FA"
														offColor="#9C9C9C"
														onHandleColor="#4FC1E9"
														handleDiameter={16}
														uncheckedIcon={false}
														checkedIcon={false}
														boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
														activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
														height={20}
														width={45}
														className="react-switch"
														id="material-switch"
													/>
												</td>
											</tr>)}
									</tbody>
								</Table>
							</div>
						}
					</PageContent>
				</PageWrap>
			</Suspense>
		);
	}
}

export default Ads;
