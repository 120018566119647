import React, { Component } from 'react';
import './style.scss'
import { Alert, Col, Pagination, PaginationItem, PaginationLink, Row, Table } from 'reactstrap'
import PageWrap from '../../../components/page-wrap';
import PageTitle from '../../../components/page-title';
import PageContent from '../../../components/page-content';
import { getReferral } from "../../../api/buyer/referral";
import { getSellers } from "../../../api/buyer/bids";
import moment from 'moment';
import Dropdown from '../../../components/bs-dropdown';
import Icon from '../../../components/icon';
import { DateRangePicker } from 'react-dates';
import { END_DATE, START_DATE } from "react-dates/constants";

export default class Referrals extends Component {
    state = {
        isLoading: false,
        referrals: [],
        sellers: [],
        seller: null,
        dateFrom: moment().add(-7, 'd'),
        dateTo: moment(),
        page: 1,
        pageSize: 0,
        total: 0,
    }

    componentDidMount() {
        this.setState({ isLoading: true });
        getSellers().then(({ sellers, ...bids }) => {
            this.setState({ sellers, seller: sellers[0] });
            this.getReferralsData(sellers[0].id, this.state.dateFrom, this.state.dateTo, 1);
        });
    }

    getReferralsData = (sellerId, dateFrom, dateTo, page) => {
        this.setState({ isLoading: true });
        getReferral(sellerId, encodeURIComponent(dateFrom.startOf('day').toISOString(true)), encodeURIComponent(dateTo.endOf('day').toISOString(true)), page)
            .then(response => {
                this.setState({ referrals: response.referrals, page: response.page, pageSize: response.pageSize, total: response.total, isLoading: false });
            })
            .catch(err => {
                this.setState({ isLoading: false });
            });
    }

    sellerChangeHandler = seller => () => {
        this.setState({ seller, isLoading: true, page: 1 });
        this.getReferralsData(seller.id, this.state.dateFrom, this.state.dateTo, 1);
    };

    handleDateChange = ({ startDate, endDate }) => {
        this.setState(s => {
            if (s.dateFrom === startDate) {
                return { dateFrom: startDate, dateTo: endDate }
            }
            return { dateFrom: startDate, dateTo: null, }
        });
        if (!startDate || !endDate || startDate !== this.state.dateFrom) return;
        this.setState({ isLoading: true });
        this.getReferralsData(this.state.seller.id, startDate, endDate, this.state.page);
    }

    isOutsideRange = () => {
        return false
    };

    paginationChangeHandler = (page, value) => {
        const { seller, dateFrom, dateTo } = this.state;
        this.getReferralsData(seller.id, dateFrom, dateTo, value);
    }

    renderPagination(pagination) {
        const pages = [];
        for (let i = 1; i <= pagination; i++) {
            pages.push(
                <PaginationItem active={this.state.page === i} key={i} onClick={() => this.paginationChangeHandler('page', i)}>
                    <PaginationLink tag="button">{i}</PaginationLink>
                </PaginationItem>
            );
        }
        return pages;
    }

    render() {
        const { referrals, isLoading, sellers, seller, page, pageSize, total } = this.state;
        return (
            <PageWrap>
                <PageTitle>
                    <div className="row justify-content-between" style={{ paddingBottom: "30px" }}>
                        <h1 className="bid-title-style">Referral</h1>
                        <div className="calendar-container">
                            <DateRangePicker
                                showDefaultInputIcon
                                small
                                displayFormat="YYYY/MM/DD"
                                numberOfMonths={1}
                                minimumNights={0}
                                startDateId={START_DATE}
                                endDateId={END_DATE}
                                isOutsideRange={this.isOutsideRange}
                                maxDate={this.state.maxDate}
                                startDate={this.state.dateFrom} // momentPropTypes.momentObj or null,
                                endDate={this.state.dateTo} // momentPropTypes.momentObj or null,
                                onDatesChange={this.handleDateChange} // PropTypes.func.isRequired,
                                focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                                onFocusChange={focusedInput => this.setState({ focusedInput })} // PropTypes.func.isRequired,
                            />
                        </div>
                    </div>
                </PageTitle>

                {isLoading ?
                    <div className="d-flex justify-content-center align-items-center">
                        <div class="spinner">
                            <span class="ball-1"></span>
                            <span class="ball-2"></span>
                            <span class="ball-3"></span>
                            <span class="ball-4"></span>
                            <span class="ball-5"></span>
                            <span class="ball-6"></span>
                            <span class="ball-7"></span>
                            <span class="ball-8"></span>
                        </div>
                    </div>
                    :
                    <PageContent>
                        <Row style={{ marginTop: "21px", marginBottom: "25px" }}>
                            <Col>
                                <div className="d-flex align-items-center">
                                    <Dropdown
                                        showTriangle
                                        className="dib">
                                        <Dropdown.Toggle tag="button"
                                            className="btn btn-outline-primary btn-long justify-content-end">
                                            <span className="text">{seller ? seller.name : 'Member'}</span>
                                            <span className="icon">
                                                <Icon name="chevron-down" />
                                            </span>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu tag="ul" className="nav text-left dropdown-style">
                                            {sellers.map(s => <li onClick={this.sellerChangeHandler(s)}>
                                                <span>{s.name}</span>
                                                <span className="rui-nav-circle"></span>
                                            </li>)}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </Col>
                        </Row>
                        <div className="card table-responsive">
                            <Table className="table table-hover table-sm">
                                <thead className="card-header">
                                    <tr>
                                        <th scope="col" className="font-weight-bold">#</th>
                                        <th scope="col" className="font-weight-bold">Date</th>
                                        <th scope="col" className="font-weight-bold">Loan Amount</th>
                                        <th scope="col" className="font-weight-bold">Personal Loan Purpose</th>
                                        <th scope="col" className="font-weight-bold">Business Loan Purpose</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {referrals.map((r, i) => {
                                        return (
                                            <tr>
                                                <td>{(page - 1) * 10 + i + 1}</td>
                                                <td>{moment(r.createdOn).format('YYYY-MM-DD hh:mm a')}</td>
                                                <td>{(+r.json.loanAmount).toLocaleString('en-AU', {
                                                    currency: 'AUD',
                                                    maximumFractionDigits: 2,
                                                    style: 'currency'
                                                })}</td>
                                                <td>{r.json.loanPurpose || '-'}</td>
                                                <td>{r.json.businessLoanPurpose || '-'}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                            <div className="d-flex justify-content-md-end pagination-container">
                                {referrals.length > 0 && pageSize &&
                                    <Pagination className="pull-right">
                                        <PaginationItem>
                                            <PaginationLink
                                                onClick={() => page > 1 && this.paginationChangeHandler('page', page - 1)}
                                                previous tag="button" />
                                        </PaginationItem>
                                        {this.renderPagination(Math.ceil(total / pageSize))}
                                        <PaginationItem>
                                            <PaginationLink
                                                onClick={() => pageSize > page && this.paginationChangeHandler('page', page + 1)}
                                                next tag="button" />
                                        </PaginationItem>
                                    </Pagination>}
                            </div>
                            {!isLoading &&
                                (!referrals.length) && (
                                    <div>
                                        <Alert color="danger" className="d-flex justify-content-center">
                                            {'No data found!'}
                                        </Alert>
                                    </div>
                                )}
                        </div>
                    </PageContent>}
            </PageWrap>
        )
    }
}
