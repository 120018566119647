/**
 * Styles
 */
import './style.scss';
/**
 * External Dependencies
 */
import React, { Component } from 'react';
import classnames from 'classnames/dedupe';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
/**
 * Internal Dependencies
 */
import '../../parts/yaybar';
import { initPluginYaybar } from '../../parts/initPluginYaybar';
import Icon from '../icon';
import RoutesList, { buyer, seller, admin } from '../../pages';
import { updateAuth as actionUpdateAuth } from '../../actions';

window.RootUI.initPluginYaybar = initPluginYaybar;

/**
 * Component
 */
class PageYaybar extends Component {
	constructor(props) {
		super(props);

		this.renderSubmenus = this.renderSubmenus.bind(this);
		this.logOut = this.logOut.bind(this);
	}

	componentDidMount() {
		window.RootUI.initPluginYaybar();
	}

	logOut() {
		const {
			updateAuth,
		} = this.props;

		updateAuth({
			token: '',
		});
	}

	renderSubmenus(nav, returnObject = false) {
		let thereIsActive = false;

		const result = Object.keys(nav).map((url) => {
			const data = nav[url];
			const isActive = window.location.pathname === `${url}`;
			let isOpened = false;

			if (isActive) {
				thereIsActive = true;
			}

			let sub = '';
			if (data.sub) {
				const subData = this.renderSubmenus(data.sub, true);

				sub = (
					<ul className="yay-submenu dropdown-triangle">
						{subData.content}
					</ul>
				);

				if (subData.thereIsActive) {
					isOpened = true;
					thereIsActive = true;
				}
			}

			return (
				<React.Fragment key={`${url}-${data.name}`}>
					{data.label ? (
						<li className="yay-label">{data.label}</li>
					) : ''}
					<li className={classnames({
						'yay-item-active': isActive,
						'yay-submenu-open': isOpened,
					})}>
						{data.name ? (
							<NavLink
								exact
								to={data.sub ? '#' : url}
								className={data.sub ? 'yay-sub-toggle' : ''}
							>
								{data.icon ? (
									<>
										<span className="yay-icon">
											{typeof data.icon === 'string' ?
												<Icon name={data.icon} vendor={data.vendor} /> : <data.icon />}
										</span>
										<span>{data.name}</span>
										<span className="rui-yaybar-circle" />
									</>
								) : (
										data.name
									)}
								{data.sub ? (
									<span className="yay-icon-collapse">
										<Icon name="chevron-right" strokeWidth="1" className="rui-icon-collapse" />
									</span>
								) : ''}
							</NavLink>
						) : ''}
						{sub}
					</li>
				</React.Fragment>
			);
		});

		if (returnObject) {
			return {
				content: result,
				thereIsActive,
			};
		}

		return result;
	}

	render() {
		const {
			settings,
			userType
		} = this.props;

		let menu = {};
		if (userType === 'Admin')
			menu = settings.navigation_sidebar.admin;
		else if (userType === 'Buyer')
			menu = settings.navigation_sidebar.buyer;
		else
			menu = settings.navigation_sidebar.seller;
		return (
			<>
				<div className={
					classnames(
						'yaybar rui-yaybar yay-hide-to-small yay-gestures',
						settings.sidebar_dark && !settings.night_mode ? 'rui-yaybar-dark' : '',
						settings.sidebar_static ? 'yay-static' : '',
						settings.sidebar_effect ? `yay-${settings.sidebar_effect}` : '',
					)
				}
				>
					<div className="yay-wrap-menu">
						<div className="yaybar-wrap">
							<ul>
								{this.renderSubmenus(menu)}

								{/*<li className="yay-label">Sign</li>*/}
								<li>
									<a onClick={this.logOut}>
										<span className="yay-icon">
											<Icon name="log-out" />
										</span>
										<span>Log Out</span>
										<span className="rui-yaybar-circle"></span>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="rui-yaybar-bg" />
			</>
		);
	}
}

export default connect(({ settings }) => (
	{
		settings,
	}
), { updateAuth: actionUpdateAuth })(PageYaybar);
