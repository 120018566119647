import './style.scss';
import React, { Component } from 'react';
import editbudget from '../../../assets/icon/edit-budget.svg'
import PageWrap from '../../../components/page-wrap';
import PageTitle from '../../../components/page-title';
import PageContent from '../../../components/page-content';
import { Button, ListGroup, ListGroupItem, Table } from 'reactstrap';
import { getBudget, saveBudgetType, updateBudget, updateDailyBudget } from "../../../api/buyer/budget";
import './Budget.css';
import BudgetValueModal from './EditBudgetValue';
import MemberBudgetValueModal from './EditMemberBudgetValue';
import Tabs from '../../../components/tabs';
import Switch from "react-switch";

class Budget extends Component {
	state = {
		loading: false,
		isLoading: false,
		sellerBudgets: [],
		updatedTotalDailyBudget: {},
		updatedMemberBudget: {},
		buttonText: 'Unlimited',
		activeTab1: 'unlimited',
	}

	unlimitedHandleChange = budgetType => () => {
		saveBudgetType({ budgetType });
		this.setState({ budgetType });
	}

	componentDidMount() {
		this.setState({ loading: true });
		getBudget().then(memberBudgets => {
			this.setState(memberBudgets);
			if (memberBudgets.budgetType === 'accountLevel') {
				this.setState({
					dailyBudget: true,
					dropdownOpen: false,
					memberBudget: false,
					activeTab1: 'account',
					buttonText: 'Account Level'
				});
			} else if (memberBudgets.budgetType === 'sellerLevel') {
				this.setState({
					memberBudget: true,
					dropdownOpen: false,
					dailyBudget: false,
					activeTab1: 'seller',
					buttonText: 'Seller Level'
				});
			}
			this.setState({ loading: false, dailyBudget: this.state.dailyBudget, memberBudget: this.state.memberBudget })
		})
	}

	onDisplayBudgetHandler = (num, name) => {
		this.setState({ dailyBudget: true, dropdownOpen: false, memberBudget: false, buttonText: 'Account Level' });
		this.setState({ [`activeTab${num}`]: name });
	}

	onDisplayMemberBudgetHandler = (num, name) => {
		this.setState({ memberBudget: true, dropdownOpen: false, dailyBudget: false, buttonText: 'Seller Level' });
		this.setState({ [`activeTab${num}`]: name });
	}

	disableBothBudgetsHandler = (num, name) => {
		this.setState({ dailyBudget: false, dropdownOpen: false, memberBudget: false, buttonText: 'Unlimited' });
		this.setState({ [`activeTab${num}`]: name });
	}

	budgetValueModalToggler = () => {
		this.setState({ isOpenModal: !this.state.isOpenModal });
	}

	memberBudgetValueModalToggler = (index) => {
		this.setState({ isOpen: !this.state.isOpen, index: index });
	}

	updatedDailyBudgetHandler = (totalDailyBudget) => {
		this.setState({ isLoading: true });
		updateDailyBudget({ id: this.state.dailyBudgetId, budget: totalDailyBudget.totalDailyBudget })
			.then(({ id }) => {
				this.setState({ isOpenModal: !this.state.isOpenModal });
				this.setState({ id, totalDailyBudget: totalDailyBudget.totalDailyBudget, isLoading: false });
			});
	}

	updatedMemberBudgetHandler = (sellerBudget) => {
		this.setState({ isLoading: true });
		updateBudget({ id: sellerBudget.id, budget: sellerBudget.budget })
			.then(({ id }) => {
				const sellerBudgets = this.state.sellerBudgets;
				sellerBudgets[this.state.index] = { ...sellerBudgets[this.state.index], ...sellerBudget, id };
				this.setState(sellerBudgets);
				this.setState({ isLoading: false });
				this.setState({ isOpen: !this.state.isOpen });
			});
	}

	toggle = () => {
		this.setState({ dropdownOpen: !this.state.dropdownOpen });
	}

	toggleTab(num, name) {
		this.setState({
			[`activeTab${num}`]: name,
		});
	}


	render() {
		return (
			<PageWrap>
				<PageTitle className="pb-20">
					<h1 className="budget-style">Budget</h1>
				</PageTitle>
				{this.state.loading ?
					<div className="d-flex justify-content-center align-items-center">
						<div class="spinner">
							<span class="ball-1"></span>
							<span class="ball-2"></span>
							<span class="ball-3"></span>
							<span class="ball-4"></span>
							<span class="ball-5"></span>
							<span class="ball-6"></span>
							<span class="ball-7"></span>
							<span class="ball-8"></span>
						</div>
					</div>
					:
					<PageContent>
						<BudgetValueModal
							isLoading={this.state.isLoading}
							updatedDailyBudget={(getDailyBudget) => this.updatedDailyBudgetHandler(getDailyBudget)}
							totalDailyBudget={this.state.totalDailyBudget}
							isOpen={this.state.isOpenModal}
							isClose={this.budgetValueModalToggler} />

						<MemberBudgetValueModal
							isLoading={this.state.isLoading}
							updatedMemberBudget={(getMemberBudget) => this.updatedMemberBudgetHandler(getMemberBudget)}
							sellerBudget={this.state.sellerBudgets[this.state.index]}
							isOpen={this.state.isOpen}
							isClose={this.memberBudgetValueModalToggler} />
						<div>
							<Tabs className="border-0">
								<Tabs.NavItem
									isActive={this.state.activeTab1 === 'unlimited'}
									onClick={() => this.disableBothBudgetsHandler(1, 'unlimited')}>
									Unlimited
								</Tabs.NavItem>
								<Tabs.NavItem
									isActive={this.state.activeTab1 === 'account'}
									onClick={() => this.onDisplayBudgetHandler(1, 'account')}>
									Account Level
								</Tabs.NavItem>
								<Tabs.NavItem
									isActive={this.state.activeTab1 === 'seller'}
									onClick={() => this.onDisplayMemberBudgetHandler(1, 'seller')}>
									Seller Level
								</Tabs.NavItem>
							</Tabs>
							<Tabs.Content activeTab={this.state.activeTab1}>
								<Tabs.Pane tabId="unlimited">
									Unlimited budgeting allows for participation in every auction that occurs
									throughout
									the day.
									<div className="row">
										<span className="p-20 d-Style"> Turn on Unlimited budgeting</span>
										<label className="p-25">
											<Switch
												checked={this.state.budgetType === 'unlimited'}
												onChange={this.unlimitedHandleChange('unlimited')}
												onColor="#FFF"
												offColor="#9C9C9C"
												onHandleColor="#4FC1E9"
												handleDiameter={16}
												uncheckedIcon={false}
												checkedIcon={false}
												boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
												activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
												height={20}
												width={45}
												className="react-switch"
												id="material-switch" />
										</label>
									</div>

								</Tabs.Pane>
								<Tabs.Pane tabId="account">
									Seller level budgeting targets budget spend for the entire account, with no
									preference for which lead provider the budget it spent on.
									<div className="row">
										<span className="p-20 d-Style"> Turn on Account budgeting</span>
										<label className="p-25">
											<Switch
												checked={this.state.budgetType === 'accountLevel'}
												onChange={this.unlimitedHandleChange('accountLevel')}
												onColor="#D3F0FA"
												offColor="#9C9C9C"
												onHandleColor="#4FC1E9"
												handleDiameter={16}
												uncheckedIcon={false}
												checkedIcon={false}
												boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
												activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
												height={20}
												width={45}
												className="react-switch"
												id="material-switch" />
										</label>

									</div>
									{this.state.dailyBudget &&
										<ListGroup flush className="mt-10 col-6">
											<ListGroupItem>
												<div className="row align-items-center">
													<span style={{ fontSize: "17pt" }}> <span
														className="mr-25">Total Daily Budget</span> ${(+this.state.totalDailyBudget).toLocaleString()}</span>
													<Button onClick={this.budgetValueModalToggler}
														style={{ marginLeft: '15px' }}
														className="btn btn-link">
														<img src={editbudget} />
													</Button>
												</div>
											</ListGroupItem>
										</ListGroup>
									}

								</Tabs.Pane>
								<Tabs.Pane tabId="seller">
									Seller level budgeting targets budget spend on each lead provider. The total
									budget
									spend figure is the maximum spend if all budgets are fully exhausted in the
									day.
									<div className="row">
										<span className="p-20 d-Style"> Turn on Seller budgeting</span>
										<label className="p-25">
											<Switch
												checked={this.state.budgetType === 'sellerLevel'}
												onChange={this.unlimitedHandleChange('sellerLevel')}
												onColor="#D3F0FA"
												offColor="#9C9C9C"
												onHandleColor="#4FC1E9"
												handleDiameter={16}
												uncheckedIcon={false}
												checkedIcon={false}
												boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
												activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
												height={20}
												width={45}
												className="react-switch"
												id="material-switch" />
										</label>
									</div>
									{this.state.memberBudget && <Table className="table table-sm" style={{ width: 'auto' }}>
										<thead>
										</thead>
										<tbody>
											{this.state.sellerBudgets.map((p, index) =>
												<tr>
													<td style={{ verticalAlign: "middle" }}>{p.name}</td>
													<td style={{ verticalAlign: "middle", }}>$ {p.budget.toLocaleString()} per day</td>
													<td style={{ verticalAlign: "middle" }}>
														<Button
															onClick={() => this.memberBudgetValueModalToggler(index)}
															className="btn btn-link">
															<img src={editbudget} />
														</Button>
													</td>
												</tr>
											)}
											<tr>
												<td style={{ verticalAlign: "middle" }}>Total</td>
												<td style={{ verticalAlign: "middle", }}>
													<div className="" style={{
														fontWeight: 600,
														color: '#4b515b',
													}}>$ {this.state.sellerBudgets.reduce((p, c) => p + +c.budget, 0).toLocaleString()} per day
												</div>
												</td>
												<td style={{ verticalAlign: "middle" }}></td>
											</tr>
										</tbody>
									</Table>}
								</Tabs.Pane>
							</Tabs.Content>
						</div>
					</PageContent>
				}
			</PageWrap>
		);
	}
}
export default Budget;