import {API} from "aws-amplify";

export function getSellerAds() {
    return API.get("seller", "/seller/ads", {});
}

export function changeAdStatus(body) {
    return API.post("seller", "/seller/ads", {body});
}

export function saveReservedPrice(body) {
    return API.post("seller", "/seller/saveReservedPrice", {body});
}

export function saveMaxOffers(body) {
	return API.post("seller", "/seller/saveMaxOffers", {body});
}
