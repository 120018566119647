import React, { Component } from 'react';
import { Button, Card, CardBody, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';

class EditMessageModal extends Component {
	state = {
		message: '',
		link: '',
		name: '',
		loanAmount: '',
		comparisonRate: '',
		loanTerm: '',
		logo: '',
		productName: '',
		buttonText: '',
	}

	componentWillReceiveProps(nextProps) {
		const { ad } = nextProps;
		this.setState({ ...ad });
	}

	render() {
		const { productName, message, loanAmount, comparisonRate, loanTerm, buttonText, link } = this.state
		return (
			<div>
				<Modal isOpen={this.props.isOpen} className="modal-dialog-style">
					<div className="modal-header">
						<Button className="close" onClick={this.props.isClose}>
							x
						</Button>
					</div>
					<ModalBody>
						<Label>
							Preview
								</Label>
						<Card className="affiliate-ad-preview">
							<CardBody className="c-style">
								<div class="row row-size">
									<div class="col col-lg-6 col-md-12 col-sm-12 responsive-column">
										<div className="row-style" style={{ textAlign: "left" }}>
											{this.state.logo && <img alt="logo" style={{ maxWidth: "100%" }}
												src={this.state.logo} />}
										</div>
										<div className="row main-title">
											<div style={{ textAlign: "left" }}>
												<p className="title-style">{productName}</p>
												<p className="title-description">{message}</p>
											</div>
										</div>
									</div>
									<div class="col col-lg-6 col-md-12 col-sm-12 responsive-column-2">
										<div className="responsive-size ">
											<div className="row size-style">
												<div className="col col-lg-6 col-sm-12">
													<h2 className="text-style">Loan Amount : </h2>
												</div>
												<div className="col col-lg-6 col-sm-12">
													<h2 className="text-style-size">{loanAmount}</h2>
												</div>
											</div>

											<div className="row size-style">
												<div className="col col-lg-6 col-sm-12">
													<h2 className="text-style">Comparison Rate :</h2>
												</div>
												<div className="col col-lg-6 col-sm-12">
													<h2 className="text-style-size">{comparisonRate}</h2>
												</div>
											</div>
											<div className="row size-style">
												<div className="col col-lg-6 col-sm-12">
													<h2 className="text-style">Loan Term :</h2>
												</div>
												<div className="col col-lg-6 col-sm-12">
													<h2 className="text-style-size">{loanTerm}</h2>
												</div>
											</div>

											<div className="row size-style btn-style-1">
												<div className="col col-lg-12 btn-styles ">
													<button className="btn btn-primary"
														style={{ marginTop: "20px" }}>{buttonText}</button>

												</div>
											</div>
										</div>
									</div>
								</div>
							</CardBody>
						</Card>
					</ModalBody>
					<ModalFooter className="justify-content-center">
						<Button className="btn-outline-primary" onClick={this.props.isClose}>Close</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

export default EditMessageModal;
