import './style.scss';
import React, {Component} from 'react'
import PageWrap from '../../../components/page-wrap';
import PageTitle from '../../../components/page-title';
import PageContent from '../../../components/page-content';
import {Button, Col, Row} from 'reactstrap';
// import DatePicker from '../../../components/date-time-picker';
import Column from './EditColumn';
import {Bar, Line} from 'react-chartjs-2';
import {getSellerDashboard} from '../../../api/seller/dashboard'
import {DateRangePicker} from 'react-dates';
import {END_DATE, START_DATE} from "react-dates/constants";
import moment from "moment";
import {saveDashboardColumns} from "../../../api/buyer/dashboard";

const barChartOptions1 = {
	tooltips: {
		mode: 'nearest',
		intersect: false,
		backgroundColor: '#393f49',
		titleMarginBottom: 8,
		titleFontSize: 14,
		titleFontColor: '#f8f9fa',
		titleFontFamily: "'Open Sans', sans-serif",
		bodyFontSize: 11,
		bodyFontColor: '#d7d9e0',
		bodyFontFamily: "'Open Sans', sans-serif",
		footerMarginTop: 10,
		footerFontSize: 11,
		footerFontColor: '#f8f9fa',
		footerFontFamily: "'Open Sans', sans-serif",
		xPadding: 10,
		yPadding: 10,
		caretPadding: 5,
		cornerRadius: 4,
	},
	legend: false,
	scales: {
		xAxes: [{
			stacked: true,
			gridLines: {
				lineWidth: 0,
				color: 'transparent',
				zeroLineWidth: 0,
				zeroLineColor: 'transparent',
			},
			ticks: {
				fontSize: 12,
				fontColor: '#bcbec0',
				fontFamily: "'Open Sans', sans-serif",
			},
		}],
		yAxes: [{
			stacked: true,
			gridLines: {
				lineWidth: 0,
				color: 'transparent',
				zeroLineWidth: 0,
				zeroLineColor: 'transparent',
			},
			ticks: {
				callback: function (label, index, labels) {
					return `$${label.toLocaleString()}`;
				}, beginAtZero: true,
				precision: 0,
				fontSize: 12,
				fontColor: '#bcbec0',
				fontFamily: "'Open Sans', sans-serif",
			},
		}],
	},
};
const lineOptions = {
	tooltips: {
		mode: 'nearest',
		intersect: false,
		backgroundColor: '#393f49',
		titleMarginBottom: 8,
		titleFontSize: 14,
		titleFontColor: '#f8f9fa',
		titleFontFamily: "'Open Sans', sans-serif",
		bodyFontSize: 11,
		bodyFontColor: '#d7d9e0',
		bodyFontFamily: "'Open Sans', sans-serif",
		footerMarginTop: 10,
		footerFontSize: 11,
		footerFontColor: '#f8f9fa',
		footerFontFamily: "'Open Sans', sans-serif",
		xPadding: 10,
		yPadding: 10,
		caretPadding: 5,
		cornerRadius: 4,
	},
	legend: {
		display: true,
		labels: {
			fontSize: 9,
			boxWidth: 20,
		}
	},
	scales: {
		xAxes: [{
			gridLines: {
				lineWidth: 0,
				color: 'transparent',
				zeroLineWidth: 0,
				zeroLineColor: 'transparent',
			},
			ticks: {
				fontSize: 12,
				fontColor: '#bcbec0',
				fontFamily: "'Open Sans', sans-serif",
			},
		}],
		yAxes: [{
			position: 'left',
			id: 'revenuePerReferral',
			type: 'linear',
			gridLines: {
				lineWidth: 0,
				color: 'transparent',
				zeroLineWidth: 0,
				zeroLineColor: 'transparent',
			},
			ticks: {
				beginAtZero: true,
				callback: function (label, index, labels) {
					return `$${label.toFixed(2)}`;
				},
				fontSize: 12,
				fontColor: '#bcbec0',
				fontFamily: "'Open Sans', sans-serif",
			},
		}, {
			position: 'right',
			id: 'auctionToReferralRate',
			type: 'linear',
			gridLines: {
				lineWidth: 0,
				color: 'transparent',
				zeroLineWidth: 0,
				zeroLineColor: 'transparent',
			},
			ticks: {
				suggestedMin: 4,
				beginAtZero: true,
				callback: function (label, index, labels) {
					return `${label.toFixed(2)}%`;
				},
				fontSize: 12,
				fontColor: '#bcbec0',
				fontFamily: "'Open Sans', sans-serif",
			},
		}],
	},
}

class Dashboard extends Component {
	state = {
		loading: false,
		editColumns: {
			member: true,
			impression: true,
			click: true,
			referral: true,
			clickRate: true,
			referralRate: true,
			uniqueBidRequest: true,
			revenuePerReferral: true,
			revenuePerAuction: true,
			auctionToRevenueRate: true,
			totalRevenue: true,
		},
		dateFrom: moment().add(-7, 'd'),
		dateTo: moment(),
		dashboardData: {
			membersSummary: []
		}
	}
	getData = (dateFrom, dateTo) => {
		this.setState({loading: true});
		getSellerDashboard(encodeURIComponent(moment(dateFrom).startOf('day').toISOString(true)),
			encodeURIComponent(moment(dateTo).endOf('day').toISOString(true)))
			.then(dashboardData => {
				let {totalImpressions, totalClicks, totalReferrals, totalRequests, totalCost} = dashboardData.membersSummary
					.reduce((p, c) => ({
						totalImpressions: p.totalImpressions + +c.impressions,
						totalClicks: p.totalClicks + +c.clicks,
						totalReferrals: p.totalReferrals + +c.referrals,
						totalRequests: +c.requests,
						totalCost: p.totalCost + +c.totalCost,
						totalFormCompletions: p.totalFormCompletions + +c.formCompletions
					}), {
						totalImpressions: 0,
						totalClicks: 0,
						totalReferrals: 0,
						totalCost: 0,
						totalRequests: 0,
						totalFormCompletions: 0,
						totalFormCompletionsRate: 0,
						totalCostFormCompletions: 0
					});

				this.setState({
					memberSummary: dashboardData.membersSummary,
					editColumns: JSON.parse(dashboardData.dashboardColumns),
					budget: dashboardData.budget,
					budgetType: dashboardData.budgetType,
					lineData: dashboardData.lineData,
					barData: dashboardData.barData,
					isLoading: false,
					totalImpressions, totalClicks, totalReferrals, totalCost, totalRequests
				});
				this.setState({dashboardData});
				this.setState({loading: false})
			})
	}

	componentDidMount() {
		this.getData(this.state.dateFrom, this.state.dateTo);
	}

	handleDateChange = ({startDate, endDate}) => {
		this.setState(s => {
			if (s.dateFrom === startDate) {
				return {dateFrom: startDate, dateTo: endDate}
			}
			return {dateFrom: startDate, dateTo: null,}
		});
		if (!startDate || !endDate || startDate !== this.state.dateFrom) return;
		this.setState({isLoading: true});
		this.getData(startDate, endDate);
	}

	isOutsideRange = day => {
		// if (this.state.dateFrom && this.state.dateTo) return false;
		// if (day.isBefore(this.state.dateFrom)) return false;
		// const lastDate = moment(this.state.dateFrom).add(7, 'd');
		// return !day.isSameOrBefore(lastDate);
		return false;
	}


	dateFromHandleChange = (dateFrom) => {
		this.getData(dateFrom, this.state.dateTo);

		this.setState({dateFrom})
	}

	dateToHandleChange = (dateTo) => {
		this.getData(this.state.dateFrom, dateTo);

		this.setState({dateTo})
	}

	editColumn = () => {
		this.setState({
			isOpen: !this.state.isOpen
		})
	}

	onUncheckedHandler = data => {
		this.setState({isOpen: !this.state.isOpen});
		this.setState({editColumns: data});
		saveDashboardColumns({dashboardColumns: data})
	}

	render() {
		const {editColumns, dashboardData, totalImpressions, totalClicks, totalReferrals, totalRequests, totalCost} = this.state;
		const member = dashboardData.membersSummary;
		return (
			<PageWrap>
				<PageTitle>
					<h1 className="h-style">Dashboard</h1>
				</PageTitle>
				{this.state.loading ?
					<div className="d-flex justify-content-center align-items-center">
						<div class="spinner">
							<span class="ball-1"></span>
							<span class="ball-2"></span>
							<span class="ball-3"></span>
							<span class="ball-4"></span>
							<span class="ball-5"></span>
							<span class="ball-6"></span>
							<span class="ball-7"></span>
							<span class="ball-8"></span>
						</div>
					</div>
					:
					<PageContent>
						<div>
							<div className="row justify-content-end" style={{paddingBottom: "25px"}}>
								<DateRangePicker
									showDefaultInputIcon
									small
									displayFormat="YYYY/MM/DD"
									numberOfMonths={1}
									minimumNights={0}
									startDateId={START_DATE}
									endDateId={END_DATE}
									isOutsideRange={this.isOutsideRange}
									maxDate={this.state.maxDate}
									startDate={this.state.dateFrom} // momentPropTypes.momentObj or null,
									endDate={this.state.dateTo} // momentPropTypes.momentObj or null,
									onDatesChange={this.handleDateChange} // PropTypes.func.isRequired,
									focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
									onFocusChange={focusedInput => this.setState({focusedInput})} // PropTypes.func.isRequired,
								/>
							</div>
							<Row>
								<Col lg={6}>
									<h2 className="day-style"><strong>Revenue by Day</strong></h2>
									<div className="p-10 m-5 border">
										<Bar
											data={dashboardData.barChartData}
											options={barChartOptions1}
										/>
									</div>
								</Col>
								<Col lg={6}>
									<h2 className="day-style"><strong>Revenue Per Auction vs Referral Per Auction</strong></h2>
									<div className="p-10 m-5 border">
										<Line
											id="line-chart"
											options={lineOptions}
											data={dashboardData.lineData}
										/>
									</div>
								</Col>
							</Row>
							<Column
								columns={this.state.editColumns}
								isOpen={this.state.isOpen}
								isClose={this.editColumn}
								getData={(data) => this.onUncheckedHandler(data)}/>
							<div className="d-flex justify-content-end" style={{marginTop: "44px", paddingBottom: "30px"}}>
								<Button className="edit-btn-style1 px-30" onClick={this.editColumn}>Edit Columns</Button>
							</div>
							<div className="card table-responsive">
								<table className="table table-hover">
									<thead className="card-header">
									<tr>
										{!editColumns.member ?
											null
											:
											<th className="font-weight-bold">Member</th>
										}
										{!editColumns.uniqueBidRequest ?
											null
											:
											<th className="font-weight-bold">Auctions</th>
										}
										{!editColumns.impression ?
											null
											:
											<th className="font-weight-bold">Impression</th>
										}
										{!editColumns.click ?
											null
											:
											<th className="font-weight-bold">Clicks</th>
										}
										{!editColumns.referral ?
											null
											:
											<th className="font-weight-bold">Referrals</th>
										}
										{!editColumns.clickRate ?
											null
											:
											<th className="font-weight-bold">Click Rate</th>
										}
										{!editColumns.referralRate ?
											null
											:
											<th className="font-weight-bold">Referral Rate</th>
										}
										{!editColumns.revenuePerReferral ?
											null
											:
											<th className="font-weight-bold">Revenue Per Referral</th>
										}
										{!editColumns.auctionToRevenueRate ?
											null
											:
											<th className="font-weight-bold">Referral per Auction</th>
										}
										{!editColumns.revenuePerAuction ?
											null
											:
											<th className="font-weight-bold">Revenue Per Auction</th>
										}
										{!editColumns.totalRevenue ?
											null
											:
											<th className="font-weight-bold">Total Revenue</th>
										}
									</tr>
									</thead>
									<tbody>
									{member.map((p, index) => {
										return (
											<tr>
												{!editColumns.member ?
													null
													:
													<td>{p.name}</td>
												}
												{!editColumns.uniqueBidRequest ?
													null
													:
													<td>{p.requests}</td>
												}
												{!editColumns.impression ?
													null
													:
													<td>{p.impressions}</td>
												}
												{!editColumns.click ?
													null
													:
													<td>{p.clicks}</td>
												}
												{!editColumns.referral ?
													null
													:
													<td>{p.referrals}</td>
												}
												{!editColumns.clickRate ?
													null
													:
													<td>{(+p.impressions ? ((p.clicks * 100) / p.impressions) : 0).toFixed(2)}%</td>
												}
												{!editColumns.referralRate ?
													null
													:
													<td>{(+p.impressions ? ((p.referrals * 100) / p.impressions) : 0).toFixed(2)}%</td>
												}
												{!editColumns.revenuePerReferral ?
													null
													:
													<td>$ {+p.referrals > 0 ? ((+p.totalCost) / +p.referrals).toFixed(2).toLocaleString() : 0}</td>
												}
												{!editColumns.auctionToRevenueRate ?
													null
													:
													<td>{(+p.requests ? ((+p.referrals * 100) / p.requests) : 0).toFixed(2)}%</td>
												}
												{!editColumns.revenuePerAuction ?
													null
													:
													<td>$ {+p.requests > 0 ? ((+p.totalCost) / +p.requests).toFixed(2).toLocaleString() : 0}</td>
												}
												{!editColumns.totalRevenue ?
													null
													:
													<td>$ {(+p.totalCost).toFixed(2).toLocaleString()}</td>
												}
											</tr>
										);
									})}
									<tr>
										<td><strong>Total</strong></td>
										{!editColumns.uniqueBidRequest ?
											null
											:
											<td>{totalRequests}</td>
										}
										{!editColumns.impression ?
											null
											:
											<td><strong>{totalImpressions}</strong></td>
										}
										{!editColumns.click ?
											null
											:
											<td><strong>{totalClicks}</strong></td>
										}
										{!editColumns.referral ?
											null
											:
											<td><strong>{totalReferrals}</strong></td>
										}
										{!editColumns.clickRate ?
											null
											:
											<td>
												<strong>{(totalImpressions ? ((totalClicks * 100) / totalImpressions) : 0).toFixed(2)}%</strong>
											</td>
										}
										{!editColumns.referralRate ?
											null
											:
											<td>
												<strong>{(+totalImpressions ? ((totalReferrals * 100) / totalImpressions) : 0).toFixed(2)}%</strong>
											</td>
										}
										{!editColumns.revenuePerReferral ?
											null
											:
											<td>
												<strong>$ {+totalReferrals > 0 ? ((+totalCost) / +totalReferrals).toFixed(2).toLocaleString() : 0}</strong>
											</td>
										}
										{!editColumns.auctionToRevenueRate ?
											null
											:
											<td>
												<strong>{(totalRequests ? ((+totalReferrals * 100) / totalRequests) : 0).toFixed(2)}%</strong>
											</td>
										}
										{!editColumns.revenuePerAuction ?
											null
											:
											<td>
												<strong>$ {+totalRequests > 0 ? ((+totalCost) / +totalRequests).toFixed(2).toLocaleString() : 0}</strong>
											</td>
										}
										{!editColumns.totalRevenue ?
											null
											:
											<td><strong>$ {(+totalCost).toFixed(2).toLocaleString()}</strong></td>
										}
									</tr>
									</tbody>
								</table>
							</div>
						</div>
					</PageContent>
				}
			</PageWrap>
		)
	}
}

export default Dashboard
