import React, { Component, Suspense, lazy } from 'react';
import { Route, Switch } from 'react-router-dom';
import RoutesList, { admin, buyer, seller } from './pages';
// import NotFoundPage from './pages/404';
const NotFoundPage = lazy(() => import('./pages/404'));

class Routes extends Component {
    render() {
        const {
            location,
            userType
        } = this.props;
        let route = {};
        if (userType === 'Admin')
            route = admin;
        else if (userType === 'Buyer')
            route = buyer;
        else if (userType === 'Seller')
            route = seller;
        return (
            <Suspense fallback={<div>Loading...</div>}>
                <Switch location={location}>
                    {Object.keys(RoutesList)
                        .concat(Object.keys(route)).map((path) => {
                            let RouteInner = RoutesList[path] || route[path];
                            return (
                                <Route
                                    key={path}
                                    path={path}
                                    exact
                                    component={RouteInner} />
                            );
                        })}
                    <Route
                        render={() => (
                            <NotFoundPage />
                        )} />
                </Switch>
            </Suspense>
        );
    }
}

export default Routes;
