import {API} from "aws-amplify";

export function topup(body) {
	return API.post("buyer", "/buyer/topup", {body});
}

export function checkPoliPayStatus(body) {
	return API.post("buyer", "/buyer/checkPoliPayStatus", {body});
}

export function accountSummary() {
	return API.get("buyer", "/buyer/accountSummary", {});
}

export function getBuyerTransactions() {
	return API.get("buyer", "/buyer/allTransactions", {});
}
