import './style.scss';
import classnames from 'classnames/dedupe';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { Auth } from "aws-amplify";
import Icon from '../../components/icon';
import { isValidEmail } from '../../utils';
import { updateAuth as actionUpdateAuth } from '../../actions';
import img from "../../assets/icon/Final with green background.svg"
import img2 from "../../assets/icon/Final-01 Filled logo with text.svg"
import { setLoginTime } from "../../api/users";

class AuthSignInPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			emailError: '',
			password: '',
			passwordError: '',
			loading: false,
		};

		this.checkEmail = this.checkEmail.bind(this);
		this.checkPassword = this.checkPassword.bind(this);
		this.maybeLogin = this.maybeLogin.bind(this);
	}

	checkEmail() {
		const { email } = this.state;
		const isValid = email && isValidEmail(email);
		this.setState({ emailError: isValid ? '' : 'Invalid email format' });
		return isValid;
	}

	checkPassword() {
		const { password } = this.state;
		const isValid = password && password.length >= 6;
		this.setState({ passwordError: isValid ? '' : 'Password must be at least 6 characters long' });
		return isValid;
	}

	maybeLogin() {
		const { updateAuth } = this.props;
		if (this.state.loading) return;
		let isValid = true;
		isValid = this.checkEmail() && isValid;
		isValid = this.checkPassword() && isValid;
		if (!isValid) return;
		this.setState({
			loading: true,
		}, async () => {
			try {
				const u = await Auth.signIn(this.state.email, this.state.password);
				if (u.challengeName === "NEW_PASSWORD_REQUIRED") {
					await Auth.completeNewPassword(u, this.state.password);
				}
				updateAuth({ token: true });
				setLoginTime();
			} catch (e) {
				this.setState({ loading: false, passwordError: e.message });
				alert(e.message);
			}
		});
	}

	render() {
		const { email, emailError, password, passwordError, } = this.state;
		return (
			<div className="rui-main">
				<div className="row">
					<div className="col col-lg-6 col-xl-8 justify-content-center align-items-center d-flex">
						<img src={img} alt="" className="d-none d-sm-none d-md-none d-lg-block w-100" />
					</div>
					<div className="col-12 col-md-12 col-lg-6 col-xl-4">
						<div className="rui-sign align-items-center justify-content-center">
							<div className="bg-image">
								<div className="bg-grey-1" />
							</div>
							<div className="form rui-sign-form rui-sign-form-cloud">
								<div className="row vertical-gap sm-gap justify-content-center">
									<div className="col-12" style={{ textAlign: "center" }}>
										<img src={img2} alt="" />
									</div>
									<div className="col-12">
										<input
											type="email"
											className={classnames('form-control', { 'is-invalid': emailError })}
											aria-describedby="emailHelp"
											placeholder="Email"
											value={email}
											onChange={(e) => {
												this.setState({
													email: e.target.value,
												}, emailError ? this.checkEmail : () => {
												});
											}}
											onBlur={this.checkEmail}
											disabled={this.state.loading}
										/>
										{emailError ? (
											<div className="invalid-feedback">{emailError}</div>
										) : ''}
									</div>
									<div className="col-12">
										<input
											type="password"
											className={classnames('form-control', { 'is-invalid': passwordError })}
											placeholder="Password"
											value={password}
											onChange={(e) => {
												this.setState({
													password: e.target.value,
												}, passwordError ? this.checkPassword : () => {
												});
											}}
											onBlur={this.checkPassword}
											disabled={this.state.loading}
										/>
										{passwordError ? (
											<div className="invalid-feedback">{passwordError}</div>
										) : ''}
									</div>
									<div className="col-sm-6">
										<div className="custom-control custom-checkbox d-flex justify-content-start">
											<input
												type="checkbox"
												className="custom-control-input"
												id="rememberMe"
												disabled={this.state.loading}
											/>
											<label className="custom-control-label fs-13" htmlFor="rememberMe">Remember
												me</label>
										</div>
									</div>
									<div className="col-sm-6">
										<div className="d-flex justify-content-end">
											<Link to="/reset-Password" className="fs-13">Forget password?</Link>
										</div>
									</div>
									<div className="col-12">
										<button
											className="btn btn-primary btn-block text-center" style={{ color: "white" }}
											onClick={this.maybeLogin}
											disabled={this.state.loading}>
											Sign In
											{this.state.loading ? (
												<Spinner />
											) : ''}
										</button>
									</div>
									<div className="col-12">
										<div className="rui-sign-or mt-2 mb-5">or</div>
									</div>
									<div className="col-12">
										<ul className="rui-social-links d-flex justify-content-center">
											<li>
												<button
													className="rui-social-facebook"
													onClick={this.maybeLogin}
													disabled={this.state.loading}>
													<Icon vendor="fa" name={['fab', 'facebook-f']} />
													Facebook
												</button>
											</li>
											<li>
												<button
													className="rui-social-google"
													onClick={this.maybeLogin}
													disabled={this.state.loading}>
													<Icon vendor="fa" name={['fab', 'google']} />
													Google
												</button>
											</li>
										</ul>
									</div>
								</div>
							</div>
							<div className="mt-20 text-dark">
								Don&apos;t you have an account? <Link to="/sign-up" className="text-2">Sign Up</Link>
							</div>
							<div className="mt-10 text-dark">
								<Link to="/privacy" className="text-2 font-weight-bold mr-10">Privacy</Link>
								<Link to="/terms" className="text-2 font-weight-bold">Terms</Link>
							</div>
						</div>
					</div>
				</div>

			</div>
		);
	}
}

export default connect(({ auth }) => (
	{
		auth,
	}
), { updateAuth: actionUpdateAuth })(AuthSignInPage);
