import {API} from "aws-amplify";

export function accountSummary() {
	return API.get("admin", "/admin/accountSummary", {});
}

export function xeroConnect() {
	return API.get("admin", "/admin/connect", {});
}

export function xeroCallback(body) {
	return API.post("admin", "/admin/callback", {body});
}

export function selectXeroCompany(body) {
	return API.post("admin", "/admin/selectXeroCompany", {body});
}
