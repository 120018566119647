import {API} from "aws-amplify";

export function getAds() {
	return API.get("buyer", "/buyer/ads", {});
}

export function getAdsBySeller(id) {
	return API.get("buyer", `/buyer/adsBySeller/${id}`, {});
}

export function updateAds(body) {
	return API.put("buyer", `/buyer/ads`, {
		body
	})
}

export function getLogoUrl(body) {
	return API.post("buyer", `/buyer/getUploadUrl`, {
		body
	})
}
