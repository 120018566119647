import React, { Component } from 'react';
import { Button, Card, CardBody, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import { getAdsBySeller } from "../../../api/buyer/ads";
import Icon from "../../../components/icon";
import { ClipLoader } from "react-spinners";

class AttachAd extends Component {
	state = {
		ads: [],
		adId: null,
		bid: null,
		isLoading: false,
		isAdsLoading: false,
	}

	componentWillReceiveProps({ isOpen, seller }) {
		if (seller && isOpen) {
			this.setState({ isAdsLoading: true, });
			getAdsBySeller(seller.id).then(({ ads }) => this.setState({ ads, isAdsLoading: false, }));
		}
		this.setState({ bid: isOpen, isLoading: false, adId: isOpen ? isOpen.adId : null });
	}

	onchangeHandler = adId => {
		this.setState({ adId });
	}

	saveClickHandler = () => {
		this.setState({ isLoading: true });
		this.props.attachBid(this.state.adId);
	}

	render() {
		return (
			<div>
				<Modal isOpen={this.props.isOpen} className="modal-xl">
					<div className="modal-header">
						<h5 className="modal-title h2">Ads</h5>
						<Button className="close" onClick=""></Button>
					</div>
					<ModalBody>
						<div className="table-responsive">
							{this.state.isAdsLoading ?
								<div className="d-flex justify-content-center align-items-center p-25">
									<div className="spinner position-relative" style={{ right: 'auto' }}>
										<span className="ball-1"></span>
										<span className="ball-2"></span>
										<span className="ball-3"></span>
										<span className="ball-4"></span>
										<span className="ball-5"></span>
										<span className="ball-6"></span>
										<span className="ball-7"></span>
										<span className="ball-8"></span>
									</div>
								</div> :
								this.state.ads.map(a => (
									<Row className="align-items-center">
										<Col xs={1}>
											<div className="form-check">
												<input
													className="radio_item"
													type="radio"
													name="exampleRadios"
													id={"exampleRadios" + a.id}
													value={a.id}
													onChange={() => this.onchangeHandler(a.id)} />
												<label className="label_item" htmlFor={"exampleRadios" + a.id}>
													{this.state.adId === a.id ? (
														<Icon className="ad-radio" size={30} color="#4FC1E9"
															name="check-circle" />
													) : (
															<Icon className="ad-radio" size={30}
																name="circle" />
														)}{" "}
												</label>
											</div>
										</Col>
										<Col>
											<Card className="affiliate-ad-preview mt-2">
												<CardBody className="c-style">
													<div class="row row-size">
														<div class="col col-lg-6 col-md-12 col-sm-12 responsive-column">
															<div className="row-style" style={{ textAlign: "left" }}>
																{a.logo && <img alt="logo" style={{ maxWidth: "100%" }}
																	src={a.logo} />}
															</div>
															<div className="row main-title">
																<div style={{ textAlign: "left" }}>
																	<p className="title-style">{a.productName}</p>
																	<p className="title-description">{a.message}</p>
																</div>
															</div>
														</div>
														<div class="col col-lg-6 col-md-12 col-sm-12 responsive-column-2">
															<div className="responsive-size ">
																<div className="row size-style">
																	<div className="col col-lg-6 col-sm-12">
																		<h2 className="text-style">Loan Amount : </h2>
																	</div>
																	<div className="col col-lg-6 col-sm-12">
																		<h2 className="text-style-size">{a.loanAmount}</h2>
																	</div>
																</div>
																<div className="row size-style">
																	<div className="col col-lg-6 col-sm-12">
																		<h2 className="text-style">Comparison Rate :</h2>
																	</div>
																	<div className="col col-lg-6 col-sm-12">
																		<h2 className="text-style-size">{a.comparisonRate}</h2>
																	</div>
																</div>
																<div className="row size-style">
																	<div className="col col-lg-6 col-sm-12">
																		<h2 className="text-style">Loan Term :</h2>
																	</div>
																	<div className="col col-lg-6 col-sm-12">
																		<h2 className="text-style-size">{a.loanTerm}</h2>
																	</div>
																</div>
																<div className="row size-style btn-style-1">
																	<div className="col col-lg-12 btn-styles ">
																		<button className="btn btn-primary" style={{ marginTop: "20px" }}>{a.buttonText}</button>

																	</div>
																</div>
															</div>
														</div>
													</div>
												</CardBody>
											</Card>
										</Col>
									</Row>
								))}
						</div>
					</ModalBody>
					<ModalFooter className="justify-content-center">
						<Button color="primary" style={{ color: "white" }}
							disabled={this.state.adId && this.state.bid && this.state.adId === this.state.bid.adId}
							onClick={this.saveClickHandler}>{
								this.state.isLoading ? <ClipLoader
									sizeUnit={"px"}
									size={20}
									color={'#fff'}
									loading={true}
								/> : 'Save'}</Button>
						<Button className="btn-outline-primary" style={{ background: "none", color: "#4FC1E9" }}
							onClick={this.props.isClose}>Close</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

export default AttachAd;