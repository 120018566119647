import React, {Component} from 'react';
import {Button, CustomInput, Modal, ModalBody, ModalFooter, Table} from 'reactstrap';

class EditColumnsModal extends Component {
    state = {
        editColumns: {}
    }

    componentDidMount() {
        const editColumns = this.props.columns;
        this.setState({editColumns});
    }

    onCheckHandler = key => () => {
        this.setState(k => ({editColumns: {...k.editColumns, [key]: !k.editColumns[key]}}))
    }

    render() {
        return (
            <div>
                <Modal isOpen={this.props.isOpen}>
                    <div className="modal-header">
                        <h5 className="modal-title h2">Edit Columns</h5>
                        <Button className="close" onClick=""></Button>
                    </div>
                    <ModalBody>
                        <div className="table-responsive">
                            <Table>
                                <thead>
                                <th>
                                    <CustomInput
                                        defaultChecked={this.state.editColumns.member}
                                        onChange={this.onCheckHandler('member')}
                                        id="member"
                                        type="checkbox"/>
                                </th>
                                <th>Member</th>
                                </thead>
                                <thead>
                                <th><CustomInput
                                    defaultChecked={this.state.editColumns.impression}
                                    onChange={this.onCheckHandler('impression')}
                                    id="impression"
                                    type="checkbox"/></th>
                                <th>Impression</th>
                                </thead>
                                <thead>
                                <th><CustomInput
                                    defaultChecked={this.state.editColumns.click}
                                    onChange={this.onCheckHandler('click')}
                                    id="click"
                                    type="checkbox"/></th>
                                <th>Clicks</th>
                                </thead>
                                <thead>
                                <th><CustomInput
                                    defaultChecked={this.state.editColumns.referral}
                                    onChange={this.onCheckHandler('referral')}
                                    id="referral"
                                    type="checkbox"/></th>
                                <th>Referrals</th>
                                </thead>
                                <thead>
                                <th><CustomInput
                                    defaultChecked={this.state.editColumns.clickRate}
                                    onChange={this.onCheckHandler('clickRate')}
                                    id="clickRate"
                                    type="checkbox"/></th>
                                <th>Click Rate</th>
                                </thead>
                                <thead>
                                <th><CustomInput
                                    defaultChecked={this.state.editColumns.referralRate}
                                    onChange={this.onCheckHandler('referralRate')}
                                    id="referralRate"
                                    type="checkbox"/></th>
                                <th>Referral Rate</th>
                                </thead>
                                <thead>
                                <th><CustomInput
                                    defaultChecked={this.state.editColumns.formCompletions}
                                    onChange={this.onCheckHandler('formCompletions')}
                                    id="formCompletions"
                                    type="checkbox"/></th>
                                <th>Form Completions</th>
                                </thead>
                                <thead>
                                <th><CustomInput
                                    defaultChecked={this.state.editColumns.completionsRate}
                                    onChange={this.onCheckHandler('completionsRate')}
                                    id="completionsRate"
                                    type="checkbox"/></th>
                                <th>Form Completions Rate</th>
                                </thead>
                                <thead>
                                <th><CustomInput
                                    defaultChecked={this.state.editColumns.costReferral}
                                    onChange={this.onCheckHandler('costReferral')}
                                    id="costReferral"
                                    type="checkbox"/></th>
                                <th>Cost per Referral</th>
                                </thead>
                                <thead>
                                <th><CustomInput
                                    defaultChecked={this.state.editColumns.totalCost}
                                    onChange={this.onCheckHandler('totalCost')}
                                    id="totalCost"
                                    type="checkbox"/></th>
                                <th>Total Cost</th>
                                </thead>
                                <thead>
                                <th><CustomInput
                                    defaultChecked={this.state.editColumns.costFormCompletions}
                                    onChange={this.onCheckHandler('costFormCompletions')}
                                    id="costFormCompletions"
                                    type="checkbox"/></th>
                                <th>Cost Per Form Completion</th>
                                </thead>
                                <thead>
                                <th><CustomInput
                                    defaultChecked={this.state.editColumns.status}
                                    onChange={this.onCheckHandler('status')}
                                    id="status"
                                    type="checkbox"/></th>
                                <th>Status</th>
                                </thead>
                            </Table>
                        </div>
                    </ModalBody>
                    <ModalFooter className="justify-content-center">
                        <Button color="primary" style={{color:"white"}}  onClick={() => this.props.getData(this.state.editColumns)}>Save</Button>
                        <Button className="btn-outline-primary" style={{background:"none",color:"#4FC1E9"}} onClick={this.props.isClose}>Close</Button>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
export default EditColumnsModal;