import React, { Component } from 'react'
import { Button, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import { Formik } from 'formik';
import edit2 from "../../../assets/icon/edit2.svg";
import Select from 'react-select';
import { getConversions, updateBid } from "../../../api/buyer/bids";

const options = [
	{ value: '', label: 'None' },
	{ value: 'referral', label: 'Referral' },
	{ value: 'conversion', label: 'Conversion' }
];


export default class Payper extends Component {
	state = {
		show: false,
		isOpen: false,
		variables: options
	};

	componentDidMount() {
		getConversions()
			.then(res => {
				this.setState({ variables: options.concat(res.map(a => ({ value: a.label, label: `${a.conversion}(${a.label})` }))) });
			})
	}

	handleChange = selectedOption => {
		this.setState({ selectedOption });
	};

	toggleHover = (show) => {
		if (this.props.isLocked) return null;
		else this.setState({ show });
	};

	togglePopUp = () => {
		if (this.props.isLocked) return null
		else this.setState(s => ({ isOpen: !s.isOpen }));
	}

	toggleHoverLeave = (show) => this.setState({ show });

	payperHandler = (bid) => {
		if (bid.id) {
			this.setState({ updateBidInProgress: true });
			updateBid({ id: bid.id, payType: bid.payType })
				.then(response => {
					this.props.updatePayType({ ...bid, payType: bid.payType });
					this.setState({ updateBidInProgress: false });
					this.togglePopUp();
				});
		} else {
			this.props.updatePayType({ ...bid, payType: bid.payType });
			this.togglePopUp();
		}
	}

	render() {
		const { isOpen, variables } = this.state;
		const { bid } = this.props;
		return (
			<div onMouseEnter={() => this.toggleHover(true)} onMouseLeave={() => this.toggleHoverLeave(false)}>
				<div className="d-flex align-items-center" id={"UncontrolledPopover-payType" + bid.id}>
					<div className="cursor-pointer pr-0">
						<p className="mb-0">{bid.payType}</p>
					</div>
					<Formik
						ref={this.form}
						initialValues={bid}
						enableReinitialize={true}
						onSubmit={(values) => {
							this.payperHandler(values);
						}}>
						{({
							values,
							handleSubmit,
							setFieldValue,
						}) => (
							<div className=" pl-0">
								<div>
									<Button
										hidden={!((this.state.show && !this.props.editVariables) || isOpen)}
										className="btn btn-link pt-0 pb-0"><img
											src={edit2} className="pr-5" /> </Button>
									<Popover isOpen={isOpen} placement="left"
										target={"UncontrolledPopover-payType" + bid.id}
										toggle={this.togglePopUp}>
										<div>
											<PopoverHeader>Pay Type</PopoverHeader>
											<PopoverBody>
												<div className="pb-5">
													<Select
														value={variables.find(o => o.label === values.payType)}
														onChange={(value) => setFieldValue('payType', value.value)}
														options={variables} />
												</div>
												<div className="ml-auto d-flex justify-content-center pt-5">
													<Button color="primary" style={{ color: "white" }} className={"mr-2  btn-sm"}
														onClick={handleSubmit}>
														{this.state.updateBidInProgress ? <ClipLoader
															sizeUnit={"px"}
															size={20}
															color={"#fff"}
															loading={true}
														/> : 'Save'}
													</Button>
													<Button className="btn-outline-primary  btn-sm" style={{ background: "none", color: "#4FC1E9" }}
														onClick={() => {
															this.toggleHover(false);
															this.togglePopUp();
														}}>Close</Button>
												</div>
											</PopoverBody>
										</div>
									</Popover>
								</div>
							</div>
						)}
					</Formik>
				</div>
			</div>
		)
	}
}
