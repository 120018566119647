import React, {Component} from 'react';
import {Popover, PopoverBody, Table} from 'reactstrap';
import './style.scss';
import Icon from "../../../components/icon";
import {camelToTitle} from "../../../utils";

export default class PopoverControl extends Component {
	state = {
		isOpen: false,
	};

	togglePopUp = (isOpen) => this.setState(s => ({isOpen: !s.isOpen}));

	render() {
		const {bid,} = this.props;
		return (
			<React.Fragment>
				<Popover placement="left"
				         toggle={this.togglePopUp}
				         isOpen={this.state.isOpen}
				         popperClassName="max-width-auto"
				         target={"UncontrolledPopover" + bid.bidId}>
					<div className="popup-close">
						<Icon
							onClick={() => this.togglePopUp(false)}
							name="x" size={14}
							className="p-2"/>
					</div>
					<PopoverBody>
						{bid.bidSummary ? <Table className="table table-hover table-sm">
							<tbody>
							{bid.bidSummary.map(p =>
								<tr>
									<td>{camelToTitle(p.name)}</td>
									<td>{p.varType === 'category' ? p.categoryValue : p.intFrom}</td>
								</tr>
							)}
							</tbody>
						</Table> : <div>Default Bid</div>}
					</PopoverBody>
				</Popover>
				<td id={"UncontrolledPopover" + bid.bidId} type="button">{bid.bidId}<Icon
					name={["fas", "info"]} size={14}
					vendor="fa"
					className="p-2"/></td>
			</React.Fragment>
		)
	}
}
