import React, { Component } from 'react';
import { Button, FormGroup, Input, Label, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import edit2 from '../../../assets/icon/edit2.svg'
import './style.scss';
import Select from 'react-select';
import { camelToTitle } from "../../../utils";
import { updateVariable } from "../../../api/buyer/bids";
import { ClipLoader } from "react-spinners";

export default class PopoverControl extends Component {
	state = {
		show: false,
		isOpen: false,
		selectedOption: null,
		form: '',
		to: '',
		updateVariableInProgress: false,
		isChecked: false
	};

	formValueChangeHandler = key => e => {
		this.setState({ [key]: e.target.value })
	}

	handleChange = selectedOption => {
		this.setState({ selectedOption });
	};

	componentDidMount = () => {
		const { bid, variable, isLocked } = this.props;
		const { value, options, varType } = bid[variable.name] || { value: [0, 10000] };
		if (varType === 'category') {
			const opts = (options || '').split('||').map(p => ({ value: p, label: p }));
			const selectedOption = opts.filter(p => value.split(',').includes(p.value));
			this.setState({ selectedOption, options: opts, isLocked });
		} else
			this.setState({ from: value[0], to: value[1] });
	};

	toggleHover = (show) => {
		if (this.props.isLocked) return null
		else this.setState({ show });
	}
	togglePopUp = () => {
		if (this.props.isLocked) return null
		else this.setState(s => ({ isOpen: !s.isOpen }));
	}

	toggleHoverLeave = (show) => this.setState({ show });

	isValid = () => {
		const { bid, variable } = this.props;
		if (bid[variable.name] && bid[variable.name].varType === 'category')
			return Array.isArray(this.state.selectedOption) && !!this.state.selectedOption.length;
		else
			return this.state.from !== '' && this.state.to !== '';
	};

	checkBoxHandleChange = () => {
		this.setState({ isChecked: !this.state.isChecked });
	}

	valueAddHandler = () => {
		const { bid, variable } = this.props;
		let value;
		if (bid[variable.name].varType === 'category' && Array.isArray(this.state.selectedOption)) {
			value = { ...bid[variable.name], value: this.state.selectedOption.map(p => p.value).join(',') };
		} else {
			if (this.state.isChecked)
				value = { ...bid[variable.name], value: [null, null], };
			else
				value = { ...bid[variable.name], value: [this.state.from, this.state.to], };
		}
		if (bid.id) {
			this.setState({ updateVariableInProcess: true });
			updateVariable(value.id, { value: value.value, varType: value.varType })
				.then(() => {
					this.setState({ updateVariableInProcess: false });
					this.props.onChange(value);
					this.toggleHover(false);
					this.togglePopUp()
				});
		} else {
			this.props.onChange(value);
			this.toggleHover(false);
			this.togglePopUp()
		}
	};

	render() {
		const { selectedOption, isOpen, options } = this.state;
		const { bid, variable } = this.props;
		const { value, varType } = bid[variable.name] || {};
		return (
			<div className="" onMouseEnter={() => this.toggleHover(true)} onMouseLeave={() => this.toggleHoverLeave(false)}>
				<div className="d-flex align-items-center" id={"UncontrolledPopover" + variable.id + bid.id}>
					<div className="cursor-pointer pr-0">
						{value && <p className="mb-0">
							{varType === 'category' ? value : value.map(v => v !== null ? v.toLocaleString() : '').join('-')}
						</p>}
					</div>
					<div className=" pl-0">
						<Button hidden={!((this.state.show && !this.props.editVariables) || isOpen)}
							className="btn btn-link pt-0 pb-0" ><img src={edit2} className="pr-5" />
						</Button>
						<Popover isOpen={isOpen} placement="right" target={"UncontrolledPopover" + variable.id + bid.id}
							toggle={this.togglePopUp}>
							<div>
								<PopoverHeader>{camelToTitle(variable.name)}</PopoverHeader>
								<PopoverBody>
									{varType === 'category' ?
										<Select
											value={selectedOption}
											onChange={this.handleChange}
											options={options}
											isMulti />
										:
										<div className="row">
											<div className="col-5 pr-0">
												<FormGroup>
													<Label for="exampleEmail" className="popup-title-style">From</Label>
													<Input type="email" name="email" id="exampleEmail" placeholder="0"
														readOnly={this.state.isChecked}
														value={this.state.from}
														onChange={this.formValueChangeHandler('from')} />
												</FormGroup>
											</div>
											<div className="col-2" style={{ marginTop: '35px' }}>
												<h2>-</h2>
											</div>
											<div className="col-5 pl-0">
												<FormGroup>
													<Label for="exampleEmail" className="popup-title-style-1">To</Label>
													<Input type="email" name="email" id="exampleEmail" placeholder="10,000"
														readOnly={this.state.isChecked}
														value={this.state.to}
														onChange={this.formValueChangeHandler('to')} />
												</FormGroup>
											</div>
											<div className="col-12">
												<FormGroup check>
													<Label check className="check-title">
														<Input type="checkbox" value={this.state.isChecked} onChange={this.checkBoxHandleChange} />
														Value is missing
													</Label>
												</FormGroup>
											</div>
										</div>
									}
									<div className="ml-auto d-flex justify-content-center">
										<Button className="pop-btn-style btn-sm" style={{ color: "white" }}
											disabled={!this.isValid()}
											onClick={this.valueAddHandler}>{this.state.updateVariableInProcess ?
												<ClipLoader
													sizeUnit={"px"}
													size={20}
													color={"#fff"}
													loading={true}
												/> : 'Save'}</Button>
										<Button className="btn-outline-primary btn-sm pop-btn-style-1  btn-sm "
											style={{ background: "none", color: "#4FC1E9" }}
											onClick={() => {
												this.toggleHover(false);
												this.togglePopUp()
											}}>Cancel</Button>
									</div>
								</PopoverBody>
							</div>
						</Popover>
					</div>
				</div>
			</div>
		)
	}
}