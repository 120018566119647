import './style.scss';
import React, { Component } from 'react';
import edit from '../../../assets/icon/edit.svg'
import PageWrap from '../../../components/page-wrap';
import PageTitle from '../../../components/page-title';
import PageContent from '../../../components/page-content';
import { getAds, updateAds } from "../../../api/buyer/ads";
import { Button, Table } from 'reactstrap';
import EditMessage from './EditAd.jsx';
import Icon from "../../../components/icon";

class Ads extends Component {
	state = {
		loading: true,
		index: '',
		ads: [],
		reservedBid: 0,
		updateAd: {}
	}

	constructor(props) {
		super(props);
	}

	componentDidMount() {
		getAds()
			.then(ads => {
				this.setState(ads);
				this.setState({ loading: false })
			})
	}

	adModalToggler = (index) => {
		this.setState({ isOpen: !this.state.isOpen, index: index });
	}

	updateAdChangeHandler = (getAd) => {
		this.setState({ isLoading: true });
		this.setState({ isOpen: !this.state.isOpen });
		updateAds({ id: getAd.id, message: getAd.message, buttonText: getAd.buttonText, link: getAd.link })
			.then(ad => {
				const ads = this.state.ads;
				ads[this.state.index] = { ...ads[this.state.index], ...getAd }
				this.setState(ads);
				this.setState({ isLoading: false });
			});
	}

	render() {
		return (
			<PageWrap>
				<PageTitle>
					<h1 className="mb-2 pb-5 ads-style">Ads</h1>
				</PageTitle>
				<PageContent>
					<EditMessage
						ad={this.state.ads[this.state.index]}
						isOpen={this.state.isOpen}
						isClose={this.adModalToggler} />
					{this.state.loading ?
						<div className="d-flex justify-content-center align-items-center" >
							<div class="spinner">
								<span class="ball-1"></span>
								<span class="ball-2"></span>
								<span class="ball-3"></span>
								<span class="ball-4"></span>
								<span class="ball-5"></span>
								<span class="ball-6"></span>
								<span class="ball-7"></span>
								<span class="ball-8"></span>
							</div>
						</div>
						:
						<React.Fragment>
							<div class=" table-responsive">
								<Table striped className="table table-sm">
									<thead>
										<tr>
											<th scope="col"><span className="font-weight-bold">Logo</span></th>
											<th scope="col"><span className="font-weight-bold">Member</span></th>
											<th scope="col"><span className="font-weight-bold">Message</span></th>
											<th scope="col"><span className="font-weight-bold">Link</span></th>
											<th scope="col"></th>
										</tr>
									</thead>
									<tbody>
										{this.state.ads.map((p, index) => {
											return (
												<tr>
													<td style={{ verticalAlign: "middle" }}>{p.logo &&
														<img src={p.logo} alt="logo" height={50} />}</td>
													<td style={{ verticalAlign: "middle" }}>{p.name}</td>
													<td style={{ verticalAlign: "middle" }}>{p.message}</td>
													<td style={{ verticalAlign: "middle" }}><a href={p.link} target={p.link}>{p.link}</a></td>
													<td style={{ verticalAlign: "middle" }}>
														<Button onClick={() => this.adModalToggler(index)}
															className="btn btn-outline-brand mr-20" style={{ background: "none" }}>
															<Icon name="eye" />
														</Button>
														<a
															href="mailto:info@cashmi.com.au?subject=Edit Ad&body=Dear MatchLy, I would like to modify the ad displaying in my account. Please contact me to discuss."
															className="btn btn-outline-primary" style={{ background: "none" }}>
															<img src={edit} className="p-2" />
														</a>
													</td>
												</tr>
											)
										})}
									</tbody>
								</Table>
							</div>
						</React.Fragment>
					}
				</PageContent>
			</PageWrap>
		);
	}
}
export default Ads;