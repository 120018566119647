import './style.scss';
import React, {Component} from 'react';
import {Bar, Line} from 'react-chartjs-2';
import {Button, Col, Row, Table, UncontrolledCollapse} from 'reactstrap';
import {DateRangePicker} from 'react-dates';
import {END_DATE, START_DATE} from "react-dates/constants";
import PageWrap from '../../../components/page-wrap';
import PageTitle from '../../../components/page-title';
import PageContent from '../../../components/page-content';
import {getBidwiseSummary, getDashboardData, saveDashboardColumns} from "../../../api/buyer/dashboard";
import select from 'classnames';
import ColumnsModal from './EditColumns.jsx';
import moment from "moment";
import Icon from "../../../components/icon";
import PopoverControl from "./PopoverControl";

const barChartOptions = {
	tooltips: {
		mode: 'nearest',
		intersect: false,
		backgroundColor: '#393f49',
		titleMarginBottom: 8,
		titleFontSize: 14,
		titleFontColor: '#f8f9fa',
		titleFontFamily: "'Open Sans', sans-serif",
		bodyFontSize: 11,
		bodyFontColor: '#d7d9e0',
		bodyFontFamily: "'Open Sans', sans-serif",
		footerMarginTop: 10,
		footerFontSize: 11,
		footerFontColor: '#f8f9fa',
		footerFontFamily: "'Open Sans', sans-serif",
		xPadding: 10,
		yPadding: 10,
		caretPadding: 5,
		cornerRadius: 4,
	},
	legend: {
		display: true,
		labels: {
			fontSize: 9,
			boxWidth: 20,
		}
	},
	scales: {
		xAxes: [{
			gridLines: {
				lineWidth: 0,
				color: 'transparent',
				zeroLineWidth: 0,
				zeroLineColor: 'transparent',
			},
			ticks: {
				fontSize: 12,
				fontColor: '#bcbec0',
				fontFamily: "'Open Sans', sans-serif",
			},
		}],
		yAxes: [{
			position: 'right',
			id: 'referral',
			type: 'linear',
			gridLines: {
				lineWidth: 0,
				color: 'transparent',
				zeroLineWidth: 0,
				zeroLineColor: 'transparent',
			},
			ticks: {
				beginAtZero: true,
				precision: 0,
				fontSize: 12,
				fontColor: '#bcbec0',
				fontFamily: "'Open Sans', sans-serif",
			},
		}],
	},
};
const lineOptions = {
	tooltips: {
		mode: 'nearest',
		intersect: false,
		backgroundColor: '#393f49',
		titleMarginBottom: 8,
		titleFontSize: 14,
		titleFontColor: '#f8f9fa',
		titleFontFamily: "'Open Sans', sans-serif",
		bodyFontSize: 11,
		bodyFontColor: '#d7d9e0',
		bodyFontFamily: "'Open Sans', sans-serif",
		footerMarginTop: 10,
		footerFontSize: 11,
		footerFontColor: '#f8f9fa',
		footerFontFamily: "'Open Sans', sans-serif",
		xPadding: 10,
		yPadding: 10,
		caretPadding: 5,
		cornerRadius: 4,
	},
	legend: {
		display: true,
		labels: {
			fontSize: 9,
			boxWidth: 20,
		}
	},
	scales: {
		xAxes: [{
			gridLines: {
				lineWidth: 0,
				color: 'transparent',
				zeroLineWidth: 0,
				zeroLineColor: 'transparent',
			},
			ticks: {
				fontSize: 12,
				fontColor: '#bcbec0',
				fontFamily: "'Open Sans', sans-serif",
			},
		}],
		yAxes: [{
			position: 'right',
			id: 'referralRate',
			type: 'linear',
			gridLines: {
				lineWidth: 0,
				color: 'transparent',
				zeroLineWidth: 0,
				zeroLineColor: 'transparent',
			},
			ticks: {
				stepSize: 20,
				beginAtZero: true,
				callback: function (label, index, labels) {
					return `${label}%`;
				},
				fontSize: 12,
				fontColor: '#bcbec0',
				fontFamily: "'Open Sans', sans-serif",
			},
		}],
	},
}

export default class Dashboard extends Component {
	state = {
		memberSummary: [],
		bidwiseSummary: {},
		bidwiseLoading: {},
		clickThroughData: [],
		referralRateData: [],
		clicksChartData: [],
		referralsChartData: [],
		dateFrom: moment().add(-7, 'd'),
		dateTo: moment(),
		isLoading: false,
		balance: 0,
		popoverOpen: false,
		editColumns: {
			member: true,
			impression: true,
			click: true,
			referral: true,
			clickRate: true,
			referralRate: true,
			costReferral: true,
			totalCost: true,
			status: true,
		}
	};

	toggle = () => {
		this.setState({popoverOpen: !this.state.popoverOpen})
	}

	componentDidMount() {
		this.getData(this.state.dateFrom, this.state.dateTo);
	}

	handleDateChange = ({startDate, endDate}) => {
		this.setState(s => {
			if (s.dateFrom === startDate) {
				return {dateFrom: startDate, dateTo: endDate}
			}
			return {dateFrom: startDate, dateTo: null,}
		});
		if (!startDate || !endDate || startDate !== this.state.dateFrom) return;
		this.setState({isLoading: true});
		this.getData(startDate, endDate);
	}

	isOutsideRange = () => {
		return false
	};

	getData = (dateFrom, dateTo) => {
		this.setState({isLoading: true});
		getDashboardData(
			encodeURIComponent(dateFrom.startOf('day').toISOString(true)),
			encodeURIComponent(dateTo.endOf('day').toISOString(true))).then(data => {
			let {totalImpressions, totalClicks, totalReferrals, totalCost, totalFormCompletions} = data.membersSummary
				.reduce((p, c) => ({
					totalImpressions: p.totalImpressions + +c.impressions,
					totalClicks: p.totalClicks + +c.clicks,
					totalReferrals: p.totalReferrals + +c.referrals,
					totalCost: p.totalCost + +c.totalCost,
					totalFormCompletions: p.totalFormCompletions + +c.formCompletions
				}), {
					totalImpressions: 0,
					totalClicks: 0,
					totalReferrals: 0,
					totalCost: 0,
					totalFormCompletions: 0,
				});
			this.setState({
				balance: data.balance,
				editColumns: JSON.parse(data.dashboardColumns),
				memberSummary: data.membersSummary,
				budget: data.budget,
				budgetType: data.budgetType,
				lineData: data.lineData,
				barData: data.barData,
				isLoading: false,
				totalImpressions,
				totalClicks,
				totalReferrals,
				totalCost,
				totalFormCompletions,
			});
		});
	}

	columnsModalToggler = () => {
		this.setState({isOpen: !this.state.isOpen});
	}

	onCheckHandler = (data) => {
		this.setState({isOpen: !this.state.isOpen});
		this.setState({editColumns: data});
		saveDashboardColumns({dashboardColumns: data})
	}

	accountScreenHandler = () => {
		this.props.history.push('/account', {state: 'makePayment'});
	}

	bidwiseSummary = (sellerId) => {
		const {dateTo, dateFrom} = this.state;
		this.setState(s => ({
			bidwiseLoading: {
				...s.bidwiseLoading,
				[sellerId]: true
			}
		}));
		getBidwiseSummary(encodeURIComponent(dateFrom.startOf('day').toISOString(true)),
			encodeURIComponent(dateTo.endOf('day').toISOString(true)), sellerId)
			.then(({membersSummary}) => this.setState(s => ({
				bidwiseLoading: {
					...s.bidwiseLoading,
					[sellerId]: false
				},
				bidwiseSummary: {
					...s.bidwiseSummary,
					[sellerId]: membersSummary
				}
			})))
	}

	render() {
		const {isLoading, bidwiseLoading, editColumns, balance, bidwiseSummary, memberSummary, lineData, barData, totalImpressions, totalClicks, totalReferrals, totalCost, totalFormCompletions} = this.state;
		const cols = Object.values(editColumns).filter(Boolean).length;
		const referralBarData = barData?.datasets.filter(a => a.label === 'Referrals');
		const referralLineData = lineData?.datasets.filter(a => a.label === 'Referral Rate');
		return (
			<PageWrap>
				<PageTitle>
					<div className="row justify-content-between" style={{paddingBottom: "30px"}}>
						<span className="h1 d-style">Dashboard</span>
						<div className="calendar-container">
							<DateRangePicker
								showDefaultInputIcon
								small
								displayFormat="YYYY/MM/DD"
								numberOfMonths={1}
								minimumNights={0}
								startDateId={START_DATE}
								endDateId={END_DATE}
								isOutsideRange={this.isOutsideRange}
								maxDate={this.state.maxDate}
								startDate={this.state.dateFrom} // momentPropTypes.momentObj or null,
								endDate={this.state.dateTo} // momentPropTypes.momentObj or null,
								onDatesChange={this.handleDateChange} // PropTypes.func.isRequired,
								focusedInput={this.state.focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
								onFocusChange={focusedInput => this.setState({focusedInput})} // PropTypes.func.isRequired,
							/>
						</div>
					</div>
				</PageTitle>
				{isLoading ?
					<div className="d-flex justify-content-center align-items-center">
						<div className="spinner">
							<span className="ball-1"></span>
							<span className="ball-2"></span>
							<span className="ball-3"></span>
							<span className="ball-4"></span>
							<span className="ball-5"></span>
							<span className="ball-6"></span>
							<span className="ball-7"></span>
							<span className="ball-8"></span>
						</div>
					</div>
					:
					<PageContent>
						{+balance <= 0 ?
							<div className="d-flex makePayment align-items-center">
								<Icon className="text-white" name="alert-circle"/>
								<span className="h3 pl-10 m-0 text-white">You don't have enough credits and might be missing your important leads.</span>
								<button className="ml-10 btn btn-color text-white align-self-center"
								        onClick={this.accountScreenHandler}>Top Up Now
								</button>
							</div>
							: null}
						<ColumnsModal
							columns={this.state.editColumns}
							getData={(data) => {
								this.onCheckHandler(data)
							}}
							isOpen={this.state.isOpen}
							isClose={this.columnsModalToggler}/>
						<Row>
							<Col lg={6}>
								<h2 className="h-style">Referrals</h2>
								<div className="p-10 m-0 border">
									<Bar
										data={{datasets: referralBarData, labels: barData?.labels}}
										options={barChartOptions}/>
								</div>
							</Col>
							<Col lg={6}>
								<h2 className="cr-style">Referral Rate</h2>
								<div className="p-10 m-0 border">
									<Line
										id="line-chart"
										options={lineOptions}
										data={{datasets: referralLineData, labels: barData?.labels}}/>
								</div>
							</Col>
						</Row>
						<div className="table-responsive">
							<div className="d-flex justify-content-end mb-8 mt-8">
								<Button className="btn-edit-style px-30" onClick={() => {
									this.columnsModalToggler()
								}}>Edit Columns</Button>
							</div>
							<div class="card table-responsive">
								<Table className="table table-hover table-sm">
									<thead class="card-header">
									{!editColumns.member ?
										null
										:
										<th className="font-weight-bold">Member</th>
									}
									{!editColumns.impression ?
										null
										:
										<th className="font-weight-bold">Impression</th>
									}
									{!editColumns.click ?
										null
										:
										<th className="font-weight-bold">Clicks</th>
									}
									{!editColumns.referral ?
										null
										:
										<th className="font-weight-bold">Referrals</th>
									}
									{!editColumns.clickRate ?
										null
										:
										<th className="font-weight-bold">Click Rate</th>
									}
									{!editColumns.referralRate ?
										null
										:
										<th className="font-weight-bold">Referral Rate</th>
									}
									{!editColumns.formCompletions ?
										null
										:
										<th className="font-weight-bold">Form Completions</th>
									}
									{!editColumns.completionsRate ?
										null
										:
										<th className="font-weight-bold">Form Completions Rate</th>
									}
									{!editColumns.costReferral ?
										null
										:
										<th className="font-weight-bold">Cost per Referral</th>
									}
									{!editColumns.totalCost ?
										null
										:
										<th className="font-weight-bold">Total Cost</th>
									}
									{!editColumns.costFormCompletions ?
										null
										:
										<th className="font-weight-bold">Cost Per Form Completion</th>
									}
									{!editColumns.status ?
										null
										:
										<th className="font-weight-bold">Status</th>
									}
									</thead>
									<tbody>
									{memberSummary.map((member, index) => {
										return (
											<React.Fragment>
												<tr id={'bidwise-' + index}
												    onClick={() => !bidwiseSummary[member.sellerId] && this.bidwiseSummary(member.sellerId)}>
													{!editColumns.member ?
														null
														:
														<td>{member.name}</td>
													}
													{!editColumns.impression ?
														null
														:
														<td>{member.impressions}</td>
													}
													{!editColumns.click ?
														null
														:
														<td>{member.clicks}</td>
													}
													{!editColumns.referral ?
														null
														:
														<td>{member.referrals}</td>
													}
													{!editColumns.clickRate ?
														null
														:
														<td>{(+member.impressions > 0 ? ((+member.clicks * 100) / +member.impressions) : 0).toFixed(2)}%</td>
													}
													{!editColumns.referralRate ?
														null
														:
														<td>{(+member.impressions > 0 ? ((+member.referrals * 100) / +member.impressions) : 0).toFixed(2)}%</td>
													}
													{!editColumns.formCompletions ?
														null
														:
														<td>{member.formCompletions}</td>
													}
													{!editColumns.completionsRate ?
														null
														:
														<td>{(+member.impressions > 0 ? ((+member.formCompletions * 100) / +member.impressions) : 0).toFixed(2)}%</td>
													}
													{!editColumns.costReferral ?
														null
														:
														<td>${(+member.referrals > 0 ? ((+member.totalCost) / +member.referrals) : 0).toFixed(2)}</td>
													}
													{!editColumns.totalCost ?
														null
														:
														<td>${(+member.totalCost).toLocaleString()}</td>
													}
													{!editColumns.costFormCompletions ?
														null
														:
														<td>${(+member.formCompletions > 0 ? (+member.totalCost / +member.formCompletions) : 0).toFixed(2)}</td>
													}
													{
														!editColumns.status ?
															null
															:
															<td className={select({
																'text-success': member.budgetStatus === 'Running',
																'text-warning': member.budgetStatus === 'Budget Limited',
																'text-danger': member.budgetStatus === 'Exhausted' || +balance <= 0
															})}>{balance <= 0 ? 'Out of Credit' : member.budgetStatus}</td>
													}
												</tr>
												<tr className="no-hover">
													<td colSpan={cols} className="p-0">
														<UncontrolledCollapse toggler={'bidwise-' + index}>
															<div className="p-10">
																{bidwiseLoading[member.sellerId] ?
																	<div className="d-flex justify-content-center align-items-center">
																		<div className="spinner m-20 inner-spinner">
																			<span className="ball-1"></span>
																			<span className="ball-2"></span>
																			<span className="ball-3"></span>
																			<span className="ball-4"></span>
																			<span className="ball-5"></span>
																			<span className="ball-6"></span>
																			<span className="ball-7"></span>
																			<span className="ball-8"></span>
																		</div>
																	</div> :
																	<>
																		<Table className="table table-hover table-sm table-elevation">
																			<thead class="card-header">
																			<th className="font-weight-bold">Bid Id</th>
																			<th className="font-weight-bold">Bid</th>
																			{!editColumns.impression ?
																				null
																				:
																				<th className="font-weight-bold">Impression</th>
																			}
																			{!editColumns.click ?
																				null
																				:
																				<th className="font-weight-bold">Clicks</th>
																			}
																			{!editColumns.referral ?
																				null
																				:
																				<th className="font-weight-bold">Referrals</th>
																			}
																			{!editColumns.clickRate ?
																				null
																				:
																				<th className="font-weight-bold">Click Rate</th>
																			}
																			{!editColumns.referralRate ?
																				null
																				:
																				<th className="font-weight-bold">Referral Rate</th>
																			}
																			{!editColumns.formCompletions ?
																				null
																				:
																				<th className="font-weight-bold">Form Completions</th>
																			}
																			{!editColumns.completionsRate ?
																				null
																				:
																				<th className="font-weight-bold">Form Completions Rate</th>
																			}
																			{!editColumns.costReferral ?
																				null
																				:
																				<th className="font-weight-bold">Cost per Referral</th>
																			}
																			{!editColumns.totalCost ?
																				null
																				:
																				<th className="font-weight-bold">Total Cost</th>
																			}
																			{!editColumns.costFormCompletions ?
																				null
																				:
																				<th className="font-weight-bold">Cost Per Form Completion</th>
																			}
																			</thead>
																			<tbody>
																			{bidwiseSummary[member.sellerId] && bidwiseSummary[member.sellerId].map((bid, index) => {
																				return (
																					<React.Fragment>
																						<tr>
																							<PopoverControl bid={bid}/>
																							<td>${(+bid.bid).toFixed(2)}</td>
																							{!editColumns.impression ?
																								null
																								:
																								<td>{bid.impressions}</td>
																							}
																							{!editColumns.click ?
																								null
																								:
																								<td>{bid.clicks}</td>
																							}
																							{!editColumns.referral ?
																								null
																								:
																								<td>{bid.referrals}</td>
																							}
																							{!editColumns.clickRate ?
																								null
																								:
																								<td>{(((+bid.clicks * 100) / +bid.impressions) || 0).toFixed(2)}%</td>
																							}
																							{!editColumns.referralRate ?
																								null
																								:
																								<td>{(((+bid.referrals * 100) / +bid.impressions) || 0).toFixed(2)}%</td>
																							}
																							{!editColumns.formCompletions ?
																								null
																								:
																								<td>{bid.formCompletions}</td>
																							}
																							{!editColumns.completionsRate ?
																								null
																								:
																								<td>{(((+bid.formCompletions * 100) / +bid.impressions) || 0).toFixed(2)}%</td>
																							}
																							{!editColumns.costReferral ?
																								null
																								:
																								<td>${(((+bid.totalCost) / +bid.referrals) || 0).toFixed(2)}</td>
																							}
																							{!editColumns.totalCost ?
																								null
																								:
																								<td>${(+bid.totalCost).toLocaleString()}</td>
																							}
																							{!editColumns.costFormCompletions ?
																								null
																								:
																								<td>${((+bid.totalCost / +bid.formCompletions) || 0).toFixed(2)}</td>
																							}
																						</tr>
																					</React.Fragment>
																				);
																			})}
																			</tbody>
																		</Table>
																	</>
																}
															</div>
														</UncontrolledCollapse>
													</td>
												</tr>
											</React.Fragment>
										);
									})}
									<tr>
										<td><strong>Total</strong></td>
										{!editColumns.impression ? null
											:
											<td><strong>{totalImpressions}</strong></td>
										}
										{!editColumns.click ?
											null
											:
											<td><strong>{totalClicks}</strong></td>
										}
										{!editColumns.referral ?
											null
											:
											<td><strong>{totalReferrals}</strong></td>
										}
										{!editColumns.clickRate ?
											null
											:
											<td>
												<strong>{(totalImpressions > 0 ? ((totalClicks * 100) / totalImpressions) : 0).toFixed(2)}%</strong>
											</td>
										}
										{!editColumns.clickRate ?
											null
											:
											<td>
												<strong>{(totalImpressions > 0 ? ((totalReferrals * 100) / totalImpressions) : 0).toFixed(2)}%</strong>
											</td>
										}
										{!editColumns.formCompletions ?
											null
											:
											<td>{totalFormCompletions ? totalFormCompletions : 0}</td>
										}
										{!editColumns.completionsRate ?
											null
											:
											<td>{totalFormCompletions > 0 ? ((totalFormCompletions * 100) / totalImpressions).toFixed(2) : '0.00'}%</td>
										}
										{!editColumns.costReferral ?
											null
											:
											<td><strong>${(totalReferrals > 0 ? ((+totalCost) / totalReferrals) : 0).toFixed(2)}</strong></td>
										}
										{!editColumns.totalCost ?
											null
											:
											<td><strong>${(+totalCost).toLocaleString()}</strong></td>
										}
										{!editColumns.costFormCompletions ?
											null
											:
											<td>${totalFormCompletions > 0 ? ((+totalCost) / totalFormCompletions).toFixed(2) : '0.00'}</td>
										}
										<td></td>
									</tr>
									</tbody>
								</Table>
							</div>
						</div>
					</PageContent>
				}
			</PageWrap>
		);
	}
}
