import {API} from "aws-amplify";

export function addUser(body) {
	return API.post("user", "/users/users", {body});
}

export function getLoginTime() {
	return API.get("user", "/users/getLoginTime", {});
}

export function setLoginTime() {
	return API.post("user", "/users/setLoginTime", {});
}

export function getXeroApi() {
	return API.get("users", "/admin/connect", {});
}


export function forgetPassword(body) {
	return API.post('user', '/users/forgot-password', {body})
}

export function confirmPassword(body) {
	return API.post('user', '/users/confirm-password', {body})
}
