import {API} from "aws-amplify";

export function getBids(id) {
    return API.get("buyer", `/buyer/bids/${id}`, {});
}

export function getSellers() {
    return API.get("buyer", `/buyer/sellers`, {});
}

export function getConversions(id) {
    return API.get("buyer", `/buyer/conversions`, {});
}

export function updateBid(body) {
    return API.put("buyer", `/buyer/bids`, { body });
}

export function attachAd(body) {
    return API.put("buyer", `/buyer/attachAd`, { body });
}
export function createBid(body) {
    return API.post("buyer", `/buyer/bids`, { body });
}

export function deleteBid(id) {
    return API.del("buyer", `/buyer/bids/${id}`, {});
}

export function updateVariable(id, body) {
    return API.put("buyer", `/buyer/variables/${id}`, { body });
}

export function updateSelectedVariables(id, body) {
    return API.put("buyer", `/buyer/saveSelectedVariables/${id}`, { body });
}
