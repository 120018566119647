import './style.scss';
import React, { Component, Suspense, lazy } from 'react';
import { Button, Col, Row, Table } from 'reactstrap'
import Icon from '../../../components/icon';
// import PageWrap from '../../../components/page-wrap';
// import PageTitle from '../../../components/page-title';
// import PageContent from '../../../components/page-content';
// import Dropdown from '../../../components/bs-dropdown';
import { camelToTitle } from "../../../utils";
import Select from "react-select";
import classNames from 'classnames';
import { getBids, lockMemberContract } from "../../../api/admin/bids";
import Switch from "react-switch";

const PageWrap = lazy(() => import('../../../components/page-wrap'));
const PageTitle = lazy(() => import('../../../components/page-title'));
const PageContent = lazy(() => import('../../../components/page-content'));
const Dropdown = lazy(() => import('../../../components/bs-dropdown'));

export default class Bids extends Component {
	state = {
		sellerBudgets: [],
		bids: [],
		users: [],
		seller: null,
		isLoading: true,
		modalLoading: false,
		modal: false,
		selectedOption: null,
		variables: [],
		updateBid: {},
		deleteBidInProgress: false,
		editVariables: false,
		saveSelectedVariablesInProcess: false
	}

	componentDidMount() {
		getBids().then((bids) => {
			this.setState({ ...bids, isLoading: false });
		});
	}

	sellerChangeHandler = seller => () => {
		getBids(this.state.buyer.id, seller.id).then((bids) => {
			this.setState({ ...bids, isLoading: false });
		});
		this.setState({ seller });
	}

	buyerChangeHandler = buyer => () => {
		getBids(buyer.id, this.state.seller.id).then(({ bids, variables }) => {
			this.setState({ bids, variables, isLoading: false });
		});
		this.setState({ buyer });
	}

	lockContractChangeHandler = () => {
		lockMemberContract({ buyerId: this.state.buyer.id, sellerId: this.state.seller.id, isLocked: !this.state.isLocked });
		this.setState({ isLocked: !this.state.isLocked });
	};

	render() {
		const { editVariables, variables, buyer, users, seller } = this.state;
		const sellers = users.filter(p => p.type === 'Seller');
		const buyers = users.filter(p => p.type === 'Buyer');
		const sellerVariables = variables.filter(v => v.selected && v.type === 'Seller');
		const buyerVariables = variables.filter(v => v.selected && v.type === 'Buyer');
		return (
			<Suspense>
				<PageWrap>
					<PageTitle>
						<h1 className="bid-title-style">Bidding</h1>
					</PageTitle>
					{this.state.isLoading ?
						<div className="d-flex justify-content-center align-items-center">
							<div class="spinner">
								<span class="ball-1"></span>
								<span class="ball-2"></span>
								<span class="ball-3"></span>
								<span class="ball-4"></span>
								<span class="ball-5"></span>
								<span class="ball-6"></span>
								<span class="ball-7"></span>
								<span class="ball-8"></span>
							</div>
						</div>
						:
						<PageContent>
							<Row style={{ marginTop: "21px" }}>
								<Col>
									<div className="d-flex align-items-center">
										<Dropdown
											showTriangle
											className="dib">
											<Dropdown.Toggle tag="button"
												className="btn btn-outline-primary btn-long justify-content-end">
												<span className="text">{seller ? seller.name : 'Seller'}</span>
												<span className="icon">
													<Icon name="chevron-down" />
												</span>
											</Dropdown.Toggle>
											<Dropdown.Menu tag="ul" className="nav text-left dropdown-style">
												{sellers.map(s => <li onClick={this.sellerChangeHandler(s)}>
													<span>{s.name}</span>
													<span className="rui-nav-circle"></span>
												</li>)}
											</Dropdown.Menu>
										</Dropdown>

										<Dropdown
											showTriangle
											className="dib">
											<Dropdown.Toggle tag="button"
												className="btn btn-outline-primary btn-long justify-content-end ml-5">
												<span className="text">{buyer ? buyer.name : 'Buyer'}</span>
												<span className="icon">
													<Icon name="chevron-down" />
												</span>
											</Dropdown.Toggle>
											<Dropdown.Menu tag="ul" className="nav text-left dropdown-style">
												{buyers.map(s => <li onClick={this.buyerChangeHandler(s)}>
													<span>{s.name}</span>
													<span className="rui-nav-circle"></span>
												</li>)}
											</Dropdown.Menu>
										</Dropdown>
										<span className="p-20 d-Style">Lock Member Contract</span>
										<label className="p-25">
											<Switch
												checked={this.state.isLocked}
												onChange={this.lockContractChangeHandler}
												onColor="#FFF"
												offColor="#9C9C9C"
												onHandleColor="#4FC1E9"
												handleDiameter={16}
												uncheckedIcon={false}
												checkedIcon={false}
												boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
												activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
												height={20}
												width={45}
												className="react-switch"
												id="material-switch" />
										</label>
										{!this.state.seller &&
											<span className="h1 ml-15 mb-0">Please select seller</span>}
									</div>
								</Col>
							</Row>
							<div>
								<Row>
									<div className="d-flex justify-content-start w-100 mb-10">
										<Col>
											<Row>
												<div className="d-flex m-15 align-items-center">
													<span className="h1 mb-5 mt-5 var-style">Variables</span>
													{/* {!editVariables && <Button color="primary" className="ml-15"
                                                                           onClick={() => this.setState({editVariables: true})}>
                                                    <img src={edit} className="pr-5"/> Edit
                                                </Button>} */}
													{editVariables && <React.Fragment>
														<span className="h3 mb-0 ml-20 mr-10">Seller Variables</span>
														<Select
															className="variable-select"
															value={null}
															onChange={({ value }) => this.onClickHandler(value)()}
															options={variables.filter(v => !v.selected && v.type === 'Seller').map(v => ({
																value: v,
																label: v.name
															}))}
														/>
														<span className="h3 mb-0 ml-20 mr-10">Buyer Variables</span>
														<Select
															classNamePrefix="react-select"
															className="variable-select"
															value={null}
															onChange={({ value }) => this.onClickHandler(value)()}
															options={variables.filter(v => !v.selected && v.type === 'Buyer').map(v => ({
																value: v,
																label: v.name
															}))}
														/>
													</React.Fragment>}
												</div>
											</Row>
											<div className={classNames("d-flex border p-20", { 'bg-grey-1': editVariables })}>
												<div>
													<Row className=" ml-1" style={{ paddingTop: "8px", paddingBottom: "17px" }}>
														{sellerVariables.map((p) => {
															return (
																<Button
																	className="mb-10 p-8 fs-10 mr-5 ml-5 d-flex align-items-center btn-round"
																	color={"outline-primary"}
																	onClick={editVariables ? this.onClickHandler(p) : null}>
																	<span>{camelToTitle(p.name)}</span>
																	{editVariables &&
																		<span className="chip-ion text-green"><Icon size={10}
																			name="x" /></span>}
																</Button>
															)
														})}
													</Row>
													<Row className="ml-1">
														{buyerVariables.map((p) => {
															return (
																<Button
																	className="mb-10 p-8 fs-10 mr-5 ml-5 d-flex align-items-center text-center btn-round"
																	color={"blue"}
																	onClick={editVariables ? this.onClickHandler(p) : null}>
																	<span>{camelToTitle(p.name)}</span>
																	{editVariables &&
																		<span className="chip-ion text-blue"><Icon size={10}
																			name="x" /></span>}
																</Button>
															)
														})}
													</Row>
												</div>
											</div>
										</Col>
									</div>
								</Row>

								<div>
									<div>
										<div className=" card table-responsive">
											<Table className="table table-hover table-sm">
												<thead className="card-header">
													<tr>
														<th scope="col" className="font-weight-bold">#</th>
														{sellerVariables.map(v =>
															<th scope="col" className="font-weight-bold">
																{camelToTitle(v.name)}
															</th>
														)}
														{buyerVariables.map(v =>
															<th scope="col" className="font-weight-bold">
																{camelToTitle(v.name)}
															</th>
														)}
														<th scope="col" className="font-weight-bold">Bid</th>
													</tr>
												</thead>
												<tbody>
													{(this.state.bids).map((b, index) => {
														return (
															<tr>
																<th scope="row">
																	{index + 1}
																</th>
																{sellerVariables.map((v) =>
																	<td style={{ verticalAlign: "middle" }}>
																		<div className="d-flex align-items-center">
																			<div className="pr-0">
																				{b[v.name] && <p className="mb-0">
																					{b[v.name].varType === 'category' ? b[v.name].value : b[v.name].value.map(v => v.toLocaleString()).join('-')}
																				</p>}
																			</div>
																		</div>
																	</td>
																)}
																{buyerVariables.map(v =>
																	<td style={{ verticalAlign: "middle" }}>
																		<div className="d-flex align-items-center">
																			<div className="pr-0">
																				{b[v.name] && <p className="mb-0">
																					{b[v.name].varType === 'category' ? b[v.name].value : b[v.name].value.map(v => v.toLocaleString()).join('-')}
																				</p>}
																			</div>
																		</div>
																	</td>
																)}
																<td className="w-10" style={{ verticalAlign: "middle" }}>
																	${b.bid}
																</td>
															</tr>

														);
													})}
												</tbody>
											</Table>
										</div>
									</div>

								</div>
							</div>
						</PageContent>
					}
				</PageWrap>
			</Suspense>
		);
	}
}