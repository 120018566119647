/**
 * Styles.
 */
import './style.scss';
/**
 * External Dependencies
 */
import React, {Component} from 'react';
/**
 * Internal Dependencies
 */
import PageWrap from '../../../components/page-wrap';
import PageTitle from '../../../components/page-title';
import PageContent from '../../../components/page-content';
import './Budget.css';
import {Button, ListGroup, ListGroupItem} from "reactstrap";
import {selectXeroCompany, xeroConnect} from "../../../api/admin/account";

/**
 * Component
 */
class Xero extends Component {
	state = {
		consentUrl: '',
		tenants: [],
		loading: true,
	}


	componentDidMount() {
		xeroConnect().then(res => {
			this.setState({...res, loading: false});
		});
	}

	selectXeroCompany = (tenantId) => {
		this.setState({loading: true});
		selectXeroCompany({tenantId}).then(() => {
			xeroConnect().then(res => {
				this.setState({...res, loading: false});
			});
		});
	};

	render() {
		return (
			<PageWrap>
				<PageTitle className="pb-20">
					<h1 className="budget-style">Xero</h1>
				</PageTitle>
				{this.state.loading ?
					<div className="d-flex justify-content-center align-items-center">
						<div class="spinner">
							<span class="ball-1"></span>
							<span class="ball-2"></span>
							<span class="ball-3"></span>
							<span class="ball-4"></span>
							<span class="ball-5"></span>
							<span class="ball-6"></span>
							<span class="ball-7"></span>
							<span class="ball-8"></span>
						</div>
					</div>
					:
					<PageContent>
						<div>
							<div className="pt-20">
								<Button color="secondary" onClick={() => window.open(this.state.consentUrl)}>
									Re-Authenticate
								</Button>
							</div>
							<ListGroup flush className="mt-10 col-10 mt-5">
								<div className="col font-weight-bold p-20 ">Organisations</div>
								<div>{this.state.tenants.map(p => (
									<ListGroupItem className="pb-5 cursor-pointer" key={p.id} active={p.tenantId === this.state.tenantId}
									               onClick={() => this.selectXeroCompany(p.tenantId)}>
										<div className="row">
											<div className="col ">{p.id}</div>
											<div className="col" style={{textAlign: "end"}}>$ {p.tenantName}</div>
										</div>
									</ListGroupItem>
								))}
								</div>
							</ListGroup>
						</div>
					</PageContent>
				}
			</PageWrap>
		);
	}
}

export default Xero;
