import React, {Component} from 'react';
import {Button, CustomInput, Modal, ModalBody, ModalFooter, Table} from 'reactstrap';

class EditColumn extends Component {
	state = {
		editColumns: {}
	}

	componentDidMount() {
		const editColumns = this.props.columns;
		this.setState({editColumns});
	}

	onCheckHandler = key => () => {
		this.setState(k => ({editColumns: {...k.editColumns, [key]: !k.editColumns[key]}}))
	}

	render() {
		return (
			<div>
				<Modal isOpen={this.props.isOpen}>
					<div className="modal-header">
						<h5 className="modal-title h2">Edit Columns</h5>
						<Button className="close" onClick=""></Button>
					</div>
					<ModalBody>
						<div className="table-responsive">
							<Table>
								<thead>
								<th><CustomInput
									defaultChecked={this.state.editColumns.member}
									onChange={this.onCheckHandler('member')}
									id="member"
									type="checkbox"/></th>
								<th>Member</th>
								</thead>
								<thead>
								<th><CustomInput
									defaultChecked={this.state.editColumns.uniqueBidRequest}
									onChange={this.onCheckHandler('uniqueBidRequest')}
									id="uniqueBidRequest"
									type="checkbox"/></th>
								<th>Auctions</th>
								</thead>
								<thead>
								<th><CustomInput
									defaultChecked={this.state.editColumns.impression}
									onChange={this.onCheckHandler('impression')}
									id="impression"
									type="checkbox"/></th>
								<th>Impression</th>
								</thead>
								<thead>
								<th><CustomInput
									defaultChecked={this.state.editColumns.click}
									onChange={this.onCheckHandler('click')}
									id="click"
									type="checkbox"/></th>
								<th>Clicks</th>
								</thead>
								<thead>
								<th><CustomInput
									defaultChecked={this.state.editColumns.referral}
									onChange={this.onCheckHandler('referral')}
									id="referral"
									type="checkbox"/></th>
								<th>Referrals</th>
								</thead>
								<thead>
								<th><CustomInput
									defaultChecked={this.state.editColumns.clickRate}
									onChange={this.onCheckHandler('clickRate')}
									id="clickRate"
									type="checkbox"/></th>
								<th>Click Rate</th>
								</thead>
								<thead>
								<th><CustomInput
									defaultChecked={this.state.editColumns.referralRate}
									onChange={this.onCheckHandler('referralRate')}
									id="referralRate"
									type="checkbox"/></th>
								<th>Referral Rate</th>
								</thead>
								<thead>
								<th><CustomInput
									defaultChecked={this.state.editColumns.revenuePerReferral}
									onChange={this.onCheckHandler('revenuePerReferral')}
									id="revenuePerReferral"
									type="checkbox"/></th>
								<th>Revenue Per Referral</th>
								</thead>
								<thead>
								<th><CustomInput
									defaultChecked={this.state.editColumns.revenuePerAuction}
									onChange={this.onCheckHandler('revenuePerAuction')}
									id="revenuePerAuction"
									type="checkbox"/></th>
								<th>Revenue Per Auction</th>
								</thead>
								<thead>
								<th><CustomInput
									defaultChecked={this.state.editColumns.auctionToRevenueRate}
									onChange={this.onCheckHandler('auctionToRevenueRate')}
									id="auctionToRevenueRate"
									type="checkbox"/></th>
								<th>Auction To Revenue Rate</th>
								</thead>

								<thead>
								<th><CustomInput
									defaultChecked={this.state.editColumns.totalRevenue}
									onChange={this.onCheckHandler('totalRevenue')}
									id="totalRevenue"
									type="checkbox"/></th>
								<th>Total Revenue</th>
								</thead>
							</Table>
						</div>
					</ModalBody>
					<ModalFooter className="justify-content-center">
						<Button color="primary" style={{color: "white"}}
						        onClick={() => this.props.getData(this.state.editColumns)}>Save</Button>
						<Button className="btn-outline-primary" style={{background: "none", color: "#4FC1E9"}}
						        onClick={this.props.isClose}>Close</Button>
					</ModalFooter>
				</Modal>
			</div>
		);
	}
}

export default EditColumn;
