import {API} from "aws-amplify";

export function getDashboardData(fromDate, toDate) {
	return API.get("buyer", `/buyer/dashboard?fromDate=${fromDate}&toDate=${toDate}`, {});
}

export function saveDashboardColumns(body) {
	return API.put("buyer", `/buyer/saveDashboardColumns`, {body});
}

export function getBidwiseSummary(fromDate, toDate, sellerId) {
	return API.get("buyer", `/buyer/getBidWiseSummary?fromDate=${fromDate}&toDate=${toDate}&sellerId=${sellerId}`, {});
}
