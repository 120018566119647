import React, { Component } from 'react';
import { Button, Card, CardBody, CardText, CardTitle, FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import PageWrap from '../../../components/page-wrap';
import PageTitle from '../../../components/page-title';
import PageContent from '../../../components/page-content';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { ClipLoader } from "react-spinners";
import Crop from "../../../components/Crop/Crop";
import { getLogoUrl, updateAds } from "../../../api/buyer/ads";

const SignupSchema = Yup.object().shape({
    link: Yup.string().url()
        .required('Required'),
    buttonText: Yup.string()
        .required('Required'),
    message: Yup.string()
        .required('Required'),
});

class EditAd extends Component {
    logo = React.createRef();
    state = {
        ad: {
            logo: null,
            message: '',
            link: '',
            buttonText: '',
            name: ''
        }
    }

    componentDidMount() {
        if (!this.props.location.state) {
            this.props.history.push('/ads');
            return;
        }
        const { ad } = this.props.location.state;
        this.setState({ ad });
    }

    messageChangeHandler = (event) => {
        this.setState({ message: event.target.value })
    }

    linkChangeHandler = (event) => {
        this.setState({ link: event.target.value })
    }

    buttonTextChangeHandler = (event) => {
        this.setState({ buttonText: event.target.value })
    }

    onSelectFile = e => {
        this.setState({ logoImage: e.target.files[0] });
        if (e.target.files && e.target.files.length > 0) {
            const reader = new FileReader();
            reader.addEventListener("load", () =>
                this.setState({ logo: reader.result }),
            );
            reader.readAsDataURL(e.target.files[0]);
        }
    };

    saveAd = async ad => {
        const { logoImage } = this.state;
        this.setState({ isLoading: true });
        if (logoImage) {
            const response = await getLogoUrl({ name: 'adLogos/' + logoImage.name, type: logoImage.tyoe });
            const res = await fetch(response.uploadURL, {
                method: "PUT",
                body: new Blob([ad.logo], { type: logoImage.type })
            });
        }
        updateAds({ id: ad.id, message: ad.message, buttonText: ad.buttonText, link: ad.link, logo: logoImage.name })
            .then(ad => {
                this.props.history.goBack();
            });
    }

    render() {
        const { logo, logoImage, croppedLogoUrl, ad } = this.state;
        return (
            <PageWrap>
                <PageTitle>
                    <h1>Edit Ad Copy</h1>
                </PageTitle>
                <PageContent>
                    <Formik
                        ref={this.form}
                        initialValues={ad}
                        enableReinitialize={true}
                        validationSchema={SignupSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            this.saveAd(values);
                        }}>
                        {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleSubmit,
                            setFieldValue,
                            handleBlur,
                        }) => (
                            <div>
                                <Crop src={logo} name={logoImage && logoImage.name}
                                    onCrop={(imageUrl, blob) => {
                                        this.setState({ croppedLogoUrl: imageUrl, logo: null });
                                        setFieldValue('logo', blob);
                                    }} onCancel={() => this.setState({ logo: null })} />
                                <h1>{ad.name}</h1>
                                <FormGroup>
                                    <label className="form-control-label">Logo</label>
                                    <FormGroup>
                                        <input hidden ref={this.logo} type="file"
                                            onChange={this.onSelectFile} />
                                        <FormFeedback>
                                            {errors.logo && touched.logo && errors.logo}
                                        </FormFeedback>
                                        {(!croppedLogoUrl && !ad.logo) &&
                                            <Button className="btn btn-info"
                                                onClick={e => this.logo.current && this.logo.current.click()}>Browse</Button>}
                                    </FormGroup>
                                    <div>
                                        {(croppedLogoUrl || ad.logo) && (
                                            <img alt="Crop" style={{ maxWidth: "100%" }}
                                                onClick={e => this.icon.current && this.icon.current.click()}
                                                src={croppedLogoUrl || ad.logo} />
                                        )}
                                    </div>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Link</Label>
                                    <Input name="link"
                                        invalid={errors.link && touched.link}
                                        value={values.link}
                                        onChange={handleChange}
                                        onBlur={handleBlur} />
                                    <FormFeedback>
                                        {errors.link && touched.link && errors.link}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Button Text</Label>
                                    <Input name="buttonText"
                                        invalid={errors.buttonText && touched.buttonText}
                                        value={values.buttonText}
                                        onChange={handleChange}
                                        onBlur={handleBlur} />
                                    <FormFeedback>
                                        {errors.buttonText && touched.buttonText && errors.buttonText}
                                    </FormFeedback>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Message</Label>
                                    <Input type="textarea"
                                        rows={4}
                                        name="message"
                                        invalid={errors.message && touched.message}
                                        value={values.message}
                                        onChange={handleChange}
                                        onBlur={handleBlur} />
                                    <FormFeedback>
                                        {errors.message && touched.message && errors.message}
                                    </FormFeedback>
                                </FormGroup>
                                <Label>Preview</Label>
                                <Card>
                                    <CardBody className="c-style">
                                        <div
                                            className="d-flex justify-content-center flex-column align-items-center title">
                                            {(croppedLogoUrl || ad.logo) && (
                                                <img alt="Crop" style={{ maxWidth: "100%" }}
                                                    onClick={e => this.icon.current && this.icon.current.click()}
                                                    src={croppedLogoUrl || ad.logo} />
                                            )}
                                            <CardTitle>{ad.name}</CardTitle>
                                        </div>
                                        <CardText className="font2">{values.message}</CardText>
                                        <div className="d-flex justify-content-center">
                                            <Button className="btn-style-Apply border-0">{values.buttonText}</Button>
                                        </div>
                                    </CardBody>
                                </Card>
                                <div className="row mt-15">
                                    <div className="col">
                                        <Button color="primary" style={{ color: "white" }} className="mr-2" onClick={handleSubmit}>
                                            {this.props.isLoading ? <ClipLoader
                                                sizeUnit={"px"}
                                                size={20}
                                                color={'#fff'}
                                                loading={true}
                                            /> : 'Save'}
                                        </Button>
                                        <Button className="btn-outline-primary" style={{ background: "none", color: "#4FC1E9" }} onClick={this.props.isClose}>Close</Button>
                                    </div>
                                </div>
                            </div>
                        )}
                    </Formik>
                </PageContent>
            </PageWrap>
        );
    }
}

export default EditAd;
