/**
 * Styles.
 */
import "./style.scss";
/**
 * External Dependencies
 */
import React, { Component } from "react";
/**
 * Internal Dependencies
 */
import PageWrap from "../../../components/page-wrap";
import PageTitle from "../../../components/page-title";
import PageContent from "../../../components/page-content";
import { accountSummary } from "../../../api/seller/account";
import { Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import Icon from "../../../components/icon";

/**
 * Component
 */
class Account extends Component {
	state = {
		loading: true,
		lastTransactions: [],
		weekEarning: {
			amount: 0
		}
	};

	componentDidMount() {
		accountSummary()
			.then(data => this.setState({ ...data, loading: false }))
			.catch(console.log);
	}

	render() {
		const { lastTransactions, weekEarning } = this.state;
		return (
			<PageWrap>
				<PageTitle>
					<h1 className="account-style">Account</h1>
				</PageTitle>
				<PageContent>
					{this.state.loading ? (
						<div className="d-flex justify-content-center align-items-center" >
							<div class="spinner">
								<span class="ball-1"></span>
								<span class="ball-2"></span>
								<span class="ball-3"></span>
								<span class="ball-4"></span>
								<span class="ball-5"></span>
								<span class="ball-6"></span>
								<span class="ball-7"></span>
								<span class="ball-8"></span>
							</div>
						</div>
					) : (
							<div className="row">
								<div className="col-12 col-md-6 mt-20">
									<div className="card ">
										<div className="card-body pb-2">
											<h1 className="transaction-style">Transactions</h1>
											<div className="row mb-5">
												<div className="col-md-4">
													<span style={{ fontSize: "12pt" }}>Date Range</span>
												</div>
												<div className="col-md-4">
													<h6 className="date-style">Type</h6>
												</div>
												<div
													className="col-md-4"
													style={{ textAlign: "left" }}
												>
													<span style={{ fontSize: "12pt" }}>Total</span>
												</div>
											</div>
											{lastTransactions.map(t => (
												<div className="row mb-5 align-items-center">
													<div className="col-4">
														<h6 className="tot-date-style">
															{moment(t.createdOn).format("MMM DD,YYYY")}
														</h6>
													</div>
													<div className="col-4">
														<h6 className="tot-date-style">{t.type}</h6>
													</div>
													<div className="col-3" style={{ textAlign: "left" }}>
														<span style={{ fontSize: "12pt" }}>
															A${(+t.amount).toFixed(2).toLocaleString()}
															{/* A$123456789 */}
														</span>
													</div>
													<div>
														<a
															href={`https://affiliate-admin.s3-ap-southeast-2.amazonaws.com/invoices/${t.referenceId}.pdf`}
															className="btn-link"
															style={{ fontSize: "12pt" }}
														>
															<Icon name="download" />
														</a>
													</div>
												</div>
											))}
										</div>
										<hr className="m-0" />
										<div className="row mb-0">
											<div className="col" style={{ textAlign: "end" }}>
												<Link
													className="btn btn-link p-20 view-style"
													to={{ pathname: "/transactions" }}
												>
													VIEW TRANSACTIONS
											</Link>
												{/* <button className="btn btn-link p-20 view-style" >VIEW
											TRANSACTIONS
										</button> */}
											</div>
										</div>
									</div>
								</div>
								<div className="col-12 col-md-6 mt-20">
									<div className="card ">
										<div className="card-body">
											<h1 className="week-style">This weeks activity</h1>
											<div style={{ textAlign: "end", paddingTop: "57px" }}>
												<h1 className="m-0 font-weight-bold price-style">
													A${(+weekEarning.amount).toFixed(2).toLocaleString()}
												</h1>
												<h2 className="text-muted" style={{ fontSize: "17pt" }}>
													Inclusive of all taxes.
											</h2>
											</div>
										</div>
									</div>
								</div>
							</div>
						)}
				</PageContent>
			</PageWrap>
		);
	}
}

export default Account;
