import './style.scss';
import classnames from 'classnames/dedupe';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import { isValidEmail } from '../../utils';
import { addToast as actionAddToast, updateAuth as actionUpdateAuth } from '../../actions';
import img from "../../assets/icon/Final with green background.svg"
import { confirmPassword, forgetPassword } from '../../api/users';

class ForgotPasswordPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			emailError: '',
			password: '',
			confirmPassword: '',
			confirmPasswordError: '',
			passwordError: '',
			code: '',
			codeError: '',
			apiError: '',
			loading: false,
			sendEmail: false
		};

		this.checkEmail = this.checkEmail.bind(this);
		this.checkPassword = this.checkPassword.bind(this);
	}

	checkEmail = () => {
		const { email } = this.state;
		const isValid = email && isValidEmail(email);
		this.setState({ emailError: isValid ? '' : 'Invalid email format' });
		return isValid;
	}

	checkCode = () => {
		const { code } = this.state;
		const isValid = code && code.length > 3;
		this.setState({ codeError: isValid ? '' : 'Please enter valid code.' });
		return isValid;
	}

	checkPassword = () => {
		const isValid = this.state.password && /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(this.state.password);
		this.setState({
			passwordError: isValid ? '' : 'Password must be at least 8 characters, one uppercase letter, one lowercase letter, one number and one special character.',
		});
		return isValid;
	}

	checkConfirmPassword = () => {
		const isValid = this.state.confirmPassword === this.state.password;
		this.setState({
			confirmPasswordError: isValid ? '' : 'New password and Confirm password does not match!',
		});
		return isValid;
	}

	resetPassword = () => {
		const { addToast } = this.props;
		let isValid = true;
		isValid = this.checkEmail() && isValid;
		isValid = this.checkPassword() && isValid;
		isValid = this.checkConfirmPassword() && isValid;
		if (!isValid) return;
		this.setState({
			loading: true
		}, async () => {
			const { email, password, code } = this.state;
			confirmPassword({ email, code, password })
				.then(() => {
					this.props.history.push('/sign-in');
					this.setState({
						loading: false,
					});
					addToast({
						title: 'Reset Password Successfully',
						content: 'Your Password has been changed Successfully',
						time: new Date(),
						duration: 4000,
						color: 'primary',
					});
				}).catch(e => {
					this.setState({
						loading: false,
						codeError: e.response ? e.response.data.error : 'Something went wrong. Please try again.'
					});
					addToast({
						title: 'Error.',
						content: e.response ? e.response.data.error : 'Something went wrong. Please try again.',
						time: new Date(),
						duration: 4000,
						color: 'danger',
					});
				});
		});
	}

	sendEmail = () => {
		const { addToast } = this.props;
		if (this.state.loading) return;
		let isValid = true;
		isValid = this.checkEmail() && isValid;
		if (!isValid) return;
		this.setState({
			loading: true,
		}, async () => {
			const { email } = this.state;
			forgetPassword({ email })
				.then(response => {
					this.setState({ sendEmail: true, loading: false });
					addToast({
						title: 'Sent Email Successfully',
						content: 'Please check your email for your verification code.',
						time: new Date(),
						duration: 4000,
						color: 'primary',
					});
				}).catch(e => {
					this.setState({
						loading: false,
						emailError: e.response ? e.response.data.error : 'Something went wrong. Please try again.'
					});
					addToast({
						title: 'Error.',
						content: e.response ? e.response.data.error : 'Something went wrong. Please try again.',
						time: new Date(),
						duration: 4000,
						color: 'danger',
					});
				})
		});
	};

	render() {
		const { email, emailError, password, confirmPassword, confirmPasswordError, passwordError, code, codeError, } = this.state;
		return (
			<div className="rui-main">
				<div className="row">
					<div className="col col-lg-6 col-xl-8 justify-content-center align-items-center d-flex">
						<img src={img} alt="" className="d-none d-sm-none d-md-none d-lg-block w-100" />
					</div>
					<div className="rui-sign align-items-center justify-content-center">
						<div className="bg-image">
							<div className="bg-grey-1" />
						</div>
						<div className="form rui-sign-form rui-sign-form-cloud">
							<div className="row vertical-gap sm-gap justify-content-center">
								<div className="col-12">
									<h1 className="display-4 mb-10 text-center">Reset Password</h1>
								</div>
								<div className="col-12">
									<input
										type="email"
										readOnly={this.state.sendEmail ? true : false}
										className={classnames('form-control', { 'is-invalid': emailError })}
										aria-describedby="emailHelp"
										placeholder="Email"
										value={email}
										onChange={(e) => {
											this.setState({
												email: e.target.value,
											}, emailError ? this.checkEmail : () => {
											});
										}}
										onBlur={this.checkEmail}
										disabled={this.state.loading} />
									{emailError ? (
										<div className="invalid-feedback">{emailError}</div>
									) : ''}
								</div>
								{this.state.sendEmail ?
									<div className="col-12">
										<input
											type="text"
											className={classnames('form-control', { 'is-invalid': codeError })}
											placeholder="Email Verification Code"
											value={code}
											onChange={(e) => {
												this.setState({
													code: e.target.value,
												});
											}}
											disabled={this.state.loading} />
										{codeError ? (
											<div className="invalid-feedback">{codeError}</div>
										) : ''}
									</div>
									: null}

								{this.state.sendEmail ?
									<div className="col-12">
										<input
											type="password"
											className={classnames('form-control', { 'is-invalid': passwordError })}
											placeholder="New Password"
											value={password}
											onChange={(e) => {
												this.setState({
													password: e.target.value,
												}, passwordError ? this.checkPassword : () => {
												});
											}}
											disabled={this.state.loading} />
										{passwordError ? (
											<div className="invalid-feedback">{passwordError}</div>
										) : ''}
									</div>
									: null}

								{this.state.sendEmail ?
									<div className="col-12">
										<input
											type="password"
											className={classnames('form-control', { 'is-invalid': confirmPasswordError })}
											placeholder="Confirm New Password"
											value={confirmPassword}
											onChange={(e) => {
												this.setState({
													confirmPassword: e.target.value,
												}, confirmPasswordError ? this.checkConfirmPassword : () => {
												});
											}}
											disabled={this.state.loading}/>
										{confirmPasswordError ? (
											<div className="invalid-feedback">{confirmPasswordError}</div>
										) : ''}
									</div>
									: null}

								<div className="col-12 pt-0">
									<button
										className="btn btn-primary btn-block text-center" style={{ color: "white" }}
										onClick={this.state.sendEmail ? this.resetPassword : this.sendEmail}
										disabled={this.state.loading}>
										{this.state.sendEmail ? 'Reset Password' : 'Send'}
										{this.state.loading ? (
											<Spinner />
										) : ''}
									</button>
								</div>
							</div>
						</div>
						<div className="mt-20 text-dark">
							Remember Password? <Link to="/sign-in" className="text-2">Sign In</Link>
						</div>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(({ auth }) => (
	{
		auth,
	}
), { updateAuth: actionUpdateAuth, addToast: actionAddToast })(ForgotPasswordPage);
