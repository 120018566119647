import "./style.scss";
import React, { Component } from "react";
import PageWrap from "../../../components/page-wrap";
import PageTitle from "../../../components/page-title";
import PageContent from "../../../components/page-content";
import { Button, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from "reactstrap";
import Icon from "../../../components/icon";
import right from "../../../assets/icon/right.svg";
import { accountSummary, checkPoliPayStatus, topup } from "../../../api/buyer/account";
import { ClipLoader } from "react-spinners";
import moment from "moment";
import { Link } from "react-router-dom"
import success from '../../../assets/icon/noun_done_1543594.svg'
import failed from '../../../assets/icon/noun_Close_1620335.svg'

class Account extends Component {
	state = {
		amount: "",
		loading: true,
		modalOpen: false,
		paymentModalOpen: false,
		selectedOption: "transfer",
		transactionId: "",
		balanceInfo: {
			balance: 0,
			pendingAmount: 0
		},
		lastPayment: null,
		lastTransactions: [],
		weekExpenses: {
			amount: 0,
			commission: 0
		},
		successModal: false,
	};

	componentDidMount() {
		const urlParams = new URLSearchParams(window.location.search);
		const status = urlParams.get('status');
		const token = urlParams.get('token');
		if (token && status) {
			checkPoliPayStatus({ token })
				.then(res => this.setState({ ...res, successModal: true }));

		}
		accountSummary()
			.then(data => {
				this.setState({ ...data, loading: false });
				const openPopup = this.props.location.state || {};
				if (openPopup.state === 'makePayment') {
					this.togglePayment();
				}
			})
	}

	successToggleHandler = () => {
		this.setState({ successModal: !this.state.successModal })
	}

	failedToggleHandler = () => {
		this.setState({ failedModal: !this.state.failedModal })
	}

	toggle = () => {
		this.setState({ modalOpen: false });
	};

	togglePayment = () => {
		this.setState({ paymentModalOpen: !this.state.paymentModalOpen });
	};

	onchangeHandler = changeEvent => {
		this.setState({ selectedOption: changeEvent.target.value });
	};

	topUpHandler = () => {
		this.setState({ topUpInProgress: true });
		topup({ amount: this.state.amount, mode: this.state.selectedOption }).then(({ transactionId, NavigateURL }) => {
			if (this.state.selectedOption === 'polipay') {
				this.setState({ topUpInProgress: false, transactionId, paymentModalOpen: false });
				window.location = NavigateURL;
			} else {
				this.setState({ topUpInProgress: false, loading: true, transactionId, paymentModalOpen: false, modalOpen: true });
				accountSummary()
					.then(data => {
						this.setState({ ...data, loading: false })
					})
					.catch(err => {
						this.setState({ loading: false })
					});
			}
		});
	};

	lastPaymentHandler = () => {
		this.setState({ amount: this.state.lastPayment.amount });
	};

	render() {
		const { topUpInProgress, amount, lastPayment, error, lastTransactions, balanceInfo, weekExpenses, selectedOption, transactionId, terms, status } = this.state;
		return (
			<PageWrap>
				<PageTitle>
					<iframe
						id="ml-pixel-conversion"
						onLoad={window.mlPixel.init}
						height="1" width="1"
						frameBorder="0" />
					<h1 className="account-style">Account</h1>
				</PageTitle>
				<PageContent>
					<Modal isOpen={this.state.successModal} toggle={this.successToggleHandler}>
						<div className="modal-header">
							<h5 className="modal-title h2">Payment Status</h5>
							<Button className="close" color="" onClick={this.successToggleHandler}>
								<Icon name="x" />
							</Button>
						</div>
						<ModalBody>
							<div class="d-flex justify-content-center">
								<img src={status === 'Completed' ? success : failed} alt="" width="100px" height="100px" />
							</div>
							<div className="card " style={{ backgroundColor: "#e1e1e185" }}>
								<div className="card-body">
									<p className="font-weight-bold" style={{ color: "black" }}>
										Payment information
									</p>
									<p className="mb-0 text-muted">Date</p>
									<p style={{ color: "black" }}>
										{moment().format("MMM DD,YYYY")}
									</p>
									<p className="mb-0 text-muted">Transfer amount</p>
									<p style={{ color: "black" }}>A${amount}</p>
									<p className="mb-0 text-muted">Reference number</p>
									<p style={{ color: "black" }}>{transactionId}</p>
									<p className="mb-0 text-muted">Status</p>
									<p style={{ color: "black" }}>{status}</p>
									{status !== 'Completed' && <>
										<p className="mb-0 text-muted">Error</p>
										<p style={{ color: "black" }}>{error || status}</p>
									</>}
								</div>
							</div>
						</ModalBody>
					</Modal>
					<Modal
						isOpen={this.state.paymentModalOpen}
						toggle={this.toggle}
						className={this.props.className}
						fade>
						<div className="modal-header">
							<h5 className="modal-title h2">Make a payment</h5>
							<Button className="close" color="" onClick={this.togglePayment}>
								<Icon name="x" />
							</Button>
						</div>
						<ModalBody>
							<p className="text-muted">Select a payment method</p>
							<div class="form-check">
								<input
									class="radio_item"
									disabled
									type="radio"
									name="exampleRadios"
									id="exampleRadios1"
									value="online"
									onChange={this.onchangeHandler}
								/>
								<label class="label_item" for="exampleRadios1">
									{this.state.selectedOption === "online" ? (
										<img src={right} />
									) : (
											<img src="" style={{ marginLeft: "21px" }} />
										)}{" "}
									Paypal/credit card/debit card(Coming Soon)
								</label>
							</div>
							<hr />
							<div class="form-check">
								<input
									class="radio_item"
									type="radio"
									name="exampleRadios"
									id="exampleRadios2"
									value="transfer"
									checked={this.state.selectedOption === "transfer"}
									onChange={this.onchangeHandler}
								/>
								<label class="label_item" for="exampleRadios2">
									{this.state.selectedOption === "transfer" ? (
										<img src={right} />
									) : (
											<img src="" style={{ marginLeft: "21px" }} />
										)}{" "}
									Money transfer
								</label>
							</div>
							<hr />
							<div className="form-check">
								<input
									className="radio_item"
									type="radio"
									name="exampleRadios"
									id="exampleRadios3"
									value="payID"
									checked={this.state.selectedOption === "payID"}
									onChange={this.onchangeHandler}
								/>
								<label className="label_item" htmlFor="exampleRadios3">
									{this.state.selectedOption === "payID" ? (
										<img src={right} />
									) : (
											<img src="" style={{ marginLeft: "21px" }} />
										)}{" "}
									PayID Transfer
								</label>
							</div>
							<hr />
							<div className="form-check">
								<input
									className="radio_item"
									type="radio"
									name="exampleRadios"
									id="exampleRadios4"
									value="polipay"
									checked={this.state.selectedOption === "polipay"}
									onChange={this.onchangeHandler}
								/>
								<label className="label_item" htmlFor="exampleRadios4">
									{this.state.selectedOption === "polipay" ? (
										<img src={right} />
									) : (
											<img src="" style={{ marginLeft: "21px" }} />
										)}{" "}
									Polipay
								</label>
							</div>
							<hr />
							<p className="pt-5">Specify your payment amount</p>
							<div className="row">
								<div className="col-11 offset-1">
									{lastPayment === null ? (
										<p
											className="mb-5"
											style={{ color: "black" }}
											onClick={this.lastPaymentHandler}>
											You have no past payments
										</p>
									) : (
											<p
												className="mb-5"
												style={{ color: "black" }}
												onClick={this.lastPaymentHandler}>
												A${(+lastPayment.amount).toFixed(2).toLocaleString()}(last
											payment)
											</p>
										)}
									<p className="text-muted mb-5">other amount</p>
									<FormGroup>
										<div className="row">
											<div
												className="col-1 m-0 p-0 "
												style={{ textAlign: "end" }}>
												<Label
													for="emailInput1"
													className="pt-10"
													style={{ color: "black" }}>
													A$
												</Label>
											</div>
											<div className="col-11">
												<Input
													className="input-style"
													type="number"
													value={this.state.amount}
													onChange={e =>
														this.setState({ amount: e.target.value })
													}
												/>
											</div>
											<small id="emailHelp" class="form-text text-muted pl-15">
												E.g 10000.00
											</small>
										</div>
									</FormGroup>
								</div>
								<div className="col-sm-12">
									<div className="custom-control custom-checkbox d-flex justify-content-start">
										<input
											type="checkbox"
											className="custom-control-input"
											id="terms"
											checked={this.state.terms}
											onChange={() => this.setState(s => ({ terms: !s.terms }))} />
										<label className="custom-control-label fs-13" htmlFor="terms">I have read and agree to the
											<Link to={{ pathname: "/buyer-terms" }} target="_blank"> Buyer terms</Link>
										</label>
									</div>
								</div>
							</div>
						</ModalBody>
						<ModalFooter>
							<Button
								color="primary"
								onClick={this.topUpHandler}
								style={{ color: "white" }}
								disabled={!amount || topUpInProgress || !terms}>
								{topUpInProgress ? (
									<ClipLoader
										css={{ alignSelf: "center" }}
										sizeUnit={"px"}
										size={10}
										color={"#fff"}
										loading={true} />
								) : (
										<div>
											{this.state.selectedOption === 'polipay' ? "PoliPay" : "MAKE A PAYMENT"}
										</div>
									)}
							</Button>{" "}
						</ModalFooter>
					</Modal>
					<Modal
						isOpen={this.state.modalOpen}
						toggle={this.toggle}
						className={this.props.className}
						fade >
						<div className="modal-header">
							<h5 className="modal-title h2">Complete this transactions</h5>
							<Button className="close" color="" onClick={this.toggle}>
								<Icon name="x" />
							</Button>
						</div>
						<ModalBody>
							<div className="row">
								<div className="col-2">
									<h3 className="num-style">1</h3>
								</div>
								<div className="col-10">
									<div>
										<p className="m-0 font-weight-bold">Print this Page</p>
										<p className="text-muted">
											Print this Page or write down the bank transfer
											information below{" "}
										</p>
									</div>
								</div>
							</div>

							<div className="row">
								<div className="col-2">
									<h3 className="num-style">2</h3>
								</div>
								<div className="col-10">
									{selectedOption === 'transfer' ? <div>
										<p className="m-0 font-weight-bold">
											Initiate the transfer
										</p>
										<p className="text-muted">
											Visit your bank online or in person and use the
											information below to transfer the money. To avoid less or
											delay,
											<strong>
												do not use an ATM,and make sure to use the correct
												reference number.{" "}
											</strong>
											If the reference number doesn't fit in the Number or Notes
											fields, add it in the Name field instead of your name.{" "}
										</p>
									</div> : <div>
											<p className="m-0 font-weight-bold">
												Initiate the transfer
										</p>
											<p className="text-muted">
												Login to your online banking account an follow your bank instructions on how to make a payment to
												a PayID
											<br />
												<a
													href="http://payid.com.au"
													className="stretched-link"
													target="_blank"
													style={{ color: "#4FC1E9" }}>
													Learn more about PayID
											</a>
											</p>
										</div>}
								</div>
							</div>

							<div className="row">
								<div className="col-2">
									<h3 className="num-style">3</h3>
								</div>
								<div className="col-10">
									<div>
										<p className="m-0 font-weight-bold">
											Wait for confirmation
										</p>
										<p className="text-muted">
											You'll receive an email confirming your updated balance in
											3 to 10 days. if you do not see your balance updated
											within 10 days.{" "}<br />
											<a
												href="#"
												className="stretched-link"
												style={{ color: "#4FC1E9" }}>
												Contact Us
											</a>
										</p>
									</div>
								</div>
							</div>

							<div className="card " style={{ backgroundColor: "#e1e1e185" }}>
								<div className="card-body">
									<p className="font-weight-bold" style={{ color: "black" }}>
										Bank transfer information
									</p>
									<p className="mb-0 text-muted">Date</p>
									<p style={{ color: "black" }}>
										{moment().format("MMM DD,YYYY")}
									</p>
									<p className="mb-0 text-muted">Transfer amount</p>
									<p style={{ color: "black" }}>A${amount}</p>
									<p className="mb-0 text-muted">Reference number</p>
									<p style={{ color: "black" }}>{transactionId}</p>
									<p className="mb-0 text-muted">{selectedOption === 'transfer' ? 'Transfer to account' : 'PayID'}</p>
									<p style={{
										color: "black",
										marginBottom: 0,
									}}>{selectedOption === 'transfer' ? 'Matchly Pty Ltd' : 'MATCHLY'}</p>
									{selectedOption === 'transfer' &&
										<p style={{ color: "black", marginBottom: 0, }}>BSB: 082-798</p>}
									<p style={{ color: "black", marginBottom: 0, }}>ACN
										:{selectedOption === 'transfer' ? '710653647' : '14 639 337 796'}</p>
								</div>
							</div>
						</ModalBody>
						<ModalFooter>
							<Button color="secondary" onClick={this.toggle}>
								Close
							</Button>
						</ModalFooter>
					</Modal>
					{this.state.loading ? (
						<div className="d-flex justify-content-center align-items-center">
							<div class="spinner">
								<span class="ball-1"></span>
								<span class="ball-2"></span>
								<span class="ball-3"></span>
								<span class="ball-4"></span>
								<span class="ball-5"></span>
								<span class="ball-6"></span>
								<span class="ball-7"></span>
								<span class="ball-8"></span>
							</div>
						</div>
					) : (
							<React.Fragment>
								<div className="card ">
									<div className="card-body">
										<div className="row" style={{ height: "70px" }}>
											<div className="col">
												<h3 className="ml-5 transaction-style">Your Credits</h3>
											</div>
											<div className="col " style={{ textAlign: "end" }}>
												<h1
													className="mb-3"
													style={{
														fontSize: "30pt"
													}}>
													A${(+balanceInfo.balance).toFixed(2).toLocaleString()}
												</h1>
												<h1 className="text-muted" style={{ fontSize: "17pt" }}>
													Pending A$
												{(+balanceInfo.pendingAmount)
														.toFixed(2)
														.toLocaleString()}
												</h1>
											</div>
										</div>
										<div>
											<div className="row pt-60">
												<div className="col">
													{lastPayment === null ? (
														<p
															className="text-muted"
															style={{ fontSize: "12pt", paddingTop: "25px" }}>
															You have no past payments
														</p>
													) : (
															<p
																className="text-muted"
																style={{ fontSize: "12pt", paddingTop: "25px" }}>
																Your last payment was on{" "}
																{moment(lastPayment.createdOn).format(
																	"MMM DD,YYYY"
																)}{" "}
														for A$
																{(+lastPayment.amount).toFixed(2).toLocaleString()}
															</p>
														)}
												</div>
												<div className="col" style={{ textAlign: "end" }}>
													<button
														id="ml-pixel-click-conversion"
														className="btn btn-outline-primary payment-style"
														onClick={this.togglePayment}>
														MAKE A PAYMENT
												</button>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-12 col-md-6 mt-20">
										<div className="card ">
											<div className="card-body pb-2">
												<h1 className="transaction-style">Transactions</h1>
												<div className="row mb-5">
													<div className="col-4 col-md-4">
														<h6 className="date-style">Date Range</h6>
													</div>
													<div className="col-4 col-md-4">
														<h6 className="date-style">Type</h6>
													</div>
													<div className="col-4 col-md-4" style={{ textAlign: "left" }}>
														<span style={{ fontSize: "12pt" }}>Total</span>
													</div>
												</div>
												{lastTransactions.map(t => (
													<div className="row mb-5 align-items-center">
														<div className="col-4">
															<h6 className="tot-date-style">
																{moment(t.createdOn).format("MMM DD,YYYY")}
															</h6>
														</div>
														<div className="col-4">
															<h6 className="tot-date-style">{t.type}</h6>
														</div>
														<div className="col-3" style={{ textAlign: "left" }}>
															<span style={{ fontSize: "12pt" }}>
																A${(+t.amount).toFixed(2).toLocaleString()}
															</span>
														</div>
														<div>
															<a
																href={`https://affiliate-admin.s3-ap-southeast-2.amazonaws.com/invoices/${t.referenceId}.pdf`}
																className="btn-link"
																style={{ fontSize: "12pt" }}>
																<Icon name="download" />
															</a>
														</div>
													</div>
												))}
											</div>
											<hr className="m-0" />
											<div className="row mb-0">
												<div className="col" style={{ textAlign: "end" }}>
													<Link className="btn btn-link p-20 view-style" to={{ pathname: "/transactions" }}>VIEW
													TRANSACTIONS</Link>
												</div>
											</div>
										</div>
									</div>
									<div className="col-12 col-md-6 mt-20">
										<div className="card ">
											<div className="card-body">
												<h1 className=" activity-style">This weeks activity</h1>
												<div style={{ textAlign: "end", paddingTop: "57px" }}>
													<h1 className="m-0 font-weight-bold price-style">
														A${(+weekExpenses.amount).toFixed(2).toLocaleString()}
													</h1>
													<h2 className="text-muted" style={{ fontSize: "17pt" }}>
														Includes $
													{(+weekExpenses.commission)
															.toFixed(2)
															.toLocaleString()}{" "}
													commission and ${(+weekExpenses.tax)
															.toFixed(2)
															.toLocaleString()}{' '} tax.
												</h2>
												</div>
											</div>
										</div>
									</div>
								</div>
							</React.Fragment>
						)}
				</PageContent>
			</PageWrap>
		);
	}
}

export default Account;
