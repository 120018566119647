import {API} from "aws-amplify";

export function getBids(buyerId = '', sellerId = '') {
	return API.get("admin", `/admin/bids?buyerId=${buyerId}&sellerId=${sellerId}`, {});
}

export function lockMemberContract(body) {
	return API.put("admin", `/admin/lockMemberContract`, {body});
}

export function getSellers() {
	return API.get("admin", `/admin/sellers`, {});
}
