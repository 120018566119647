import React, { Component } from 'react';
import { Button, FormFeedback, FormGroup, Input, Popover, PopoverBody, PopoverHeader } from 'reactstrap';
import * as Yup from 'yup';
import { ClipLoader } from 'react-spinners';
import { Formik } from 'formik';
import edit2 from "../../../assets/icon/edit2.svg";
import { updateBid } from "../../../api/buyer/bids";

const SignupSchema = Yup.object().shape({
	bid: Yup.number()
		.required('Required'),
});

class UpdateBid extends Component {
	state = {
		show: false,
		isOpen: false,
		bid: { bid: 0 }
	}

	componentDidMount() {
		this.setState({ bid: this.props.bid });
	}

	componentWillReceiveProps(nextProps) {
		const { bid } = nextProps;
		this.setState({ bid });
	}

	toggleHover = (show) => {
		if (this.props.isLocked) return null
		else this.setState({ show });
	};

	toggleHoverLeave = (show) => this.setState({ show });

	updatedBidHandler = (bid) => {
		if (bid.id) {
			this.setState({ updateBidInProgress: true });
			updateBid({ id: bid.id, bid: bid.bid })
				.then(response => {
					this.props.updatedBid(bid);
					this.setState({ updateBidInProgress: false });
					this.togglePopUp();
				});
		} else {
			this.props.updatedBid(bid);
			this.togglePopUp();
		}
	};

	togglePopUp = (e) => {
		if (this.props.isLocked) return null
		else {
			e && e.stopImmediatePropagation();
			this.setState(s => ({ isOpen: !s.isOpen }));
		}
	}

	render() {
		const { isOpen } = this.state;
		const { bid } = this.props;
		return (
			<div onMouseEnter={() => this.toggleHover(true)} onMouseLeave={() => this.toggleHoverLeave(false)}>
				<div className="d-flex align-items-center" id={"UncontrolledPopover" + bid.id}>
					<div className="cursor-pointer pr-0">
						<p className="mb-0">
							$ {bid.bid.toLocaleString()}
						</p>
					</div>
					<Formik
						ref={this.form}
						initialValues={bid}
						enableReinitialize={true}
						validationSchema={SignupSchema}
						onSubmit={(values) => {
							this.updatedBidHandler(values);
						}}>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleSubmit,
							handleBlur,
						}) => (
							<div className=" pl-0">
								<div>
									<Button
										hidden={!((this.state.show && !this.props.editVariables) || isOpen)}
										className="btn btn-link pt-0 pb-0"><img
											src={edit2} className="pr-5" /> </Button>
									<Popover isOpen={isOpen} placement="left"
										target={"UncontrolledPopover" + bid.id}
										toggle={this.togglePopUp}>
										<div>
											<PopoverHeader>Bid</PopoverHeader>
											<PopoverBody>
												<FormGroup>
													<Input name="bid"
														invalid={errors.bid && touched.bid}
														value={values.bid}
														onChange={handleChange}
														onBlur={handleBlur} />
													<FormFeedback>
														{errors.bid && touched.bid && errors.bid}
													</FormFeedback>
												</FormGroup>
												<div className="ml-auto d-flex justify-content-center">
													<Button color="primary" style={{ color: "white" }} className={"mr-2  btn-sm"}
														onClick={handleSubmit}>
														{this.state.updateBidInProgress ? <ClipLoader
															sizeUnit={"px"}
															size={20}
															color={"#fff"}
															loading={true}
														/> : 'Save'}</Button>
													<Button className="btn-outline-primary  btn-sm" style={{ background: "none", color: "#4FC1E9" }}
														onClick={() => {
															this.toggleHover(false);
															this.togglePopUp();
														}}>Close</Button>
												</div>
											</PopoverBody>
										</div>
									</Popover>
								</div>
							</div>
						)}
					</Formik>
				</div>
			</div>
		);
	}
}
export default UpdateBid;