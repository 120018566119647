import './style.scss';
import classnames from 'classnames/dedupe';
import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link} from 'react-router-dom';
import {Spinner} from 'reactstrap';
import Icon from '../../components/icon';
import {isValidEmail} from '../../utils';
import {addToast as actionAddToast, updateAuth as actionUpdateAuth} from '../../actions';
import {addUser} from "../../api/users";

class AuthSignUpPage extends Component {
	constructor(props) {
		super(props);
		this.state = {
			email: '',
			emailError: '',
			name: '',
			nameError: '',
			company: '',
			companyError: '',
			phone: '',
			phoneError: '',
			type: '',
			terms: false,
			termsError: '',
			loading: false,
		};
		this.checkEmail = this.checkEmail.bind(this);
		this.checkName = this.checkName.bind(this);
		this.checkPassword = this.checkPassword.bind(this);
		this.checkTerms = this.checkTerms.bind(this);
		this.maybeLogin = this.maybeLogin.bind(this);
	}

	checkEmail() {
		const { email } = this.state;
		const isValid = email && isValidEmail(email);
		this.setState({ emailError: isValid ? '' : 'Invalid email format' });
		return isValid;
	}

	checkName() {
		const { name } = this.state;
		const isValid = name && name.length >= 3;
		this.setState({ nameError: isValid ? '' : 'Name must be at least 3 characters long' });
		return isValid;
	}

	checkPassword() {
		const { password } = this.state;
		const isValid = password && password.length >= 6;
		this.setState({ passwordError: isValid ? '' : 'Password must be at least 6 characters long' });
		return isValid;
	}

	checkTerms() {
		const { terms } = this.state;
		this.setState({ termsError: terms ? '' : 'Accepting our terms is required' });
		return terms;
	}

	checkCompany = () => {
		const { company } = this.state;
		this.setState({ companyError: company ? '' : 'Company Name must be at least 3 characters long' });
		return company;
	}

	checkPhone = () => {
		const { phone } = this.state;
		this.setState({ phoneError: phone ? '' : 'Please enter valid Phone Number' });
		return phone;
	}

	maybeLogin() {
		const { addToast } = this.props;
		if (this.state.loading) return;
		let isValid = true;
		isValid = this.checkEmail() && isValid;
		isValid = this.checkName() && isValid;
		isValid = this.checkTerms() && isValid;
		isValid = this.checkCompany() && isValid;
		isValid = this.checkPhone() && isValid;
		if (!isValid) return;

		this.setState({
			loading: true,
		}, () => {
			const { name, company, email, phone, type, } = this.state;
			addUser({ name, company, email, phone, type, }).then(() => {
				this.setState({
					loading: false,
				});
				addToast({
					title: 'Sign Up Successful',
					content: 'You have successfully signed up. We will notify you once your account is ready',
					time: new Date(),
					duration: 4000,
					color: 'primary',
				});
				this.props.history.push('sign-in');
			}).catch(e => {
				console.log(e);
				this.setState({
					loading: false,
				});
				addToast({
					title: 'Error.',
					content: e.response ? e.response.data.error : 'Something went wrong. Please try again.',
					time: new Date(),
					duration: 4000,
					color: 'danger',
				});
			})
		});
	}

	render() {
		const { email, emailError, name, nameError, company, companyError, phone, phoneError, terms, termsError } = this.state;
		return (
			<div className="rui-main">
				<div className="rui-sign align-items-center justify-content-center">
					<div className="bg-image">
						<div className="bg-grey-1" />
					</div>
					<div className="form rui-sign-form rui-sign-form-cloud">
						<div className="row vertical-gap sm-gap justify-content-center">
							<div className="col-12">
								<h1 className="display-4 mb-10 text-center">Sign Up</h1>
							</div>
							<div className="col-12">
								<input
									type="email"
									className={classnames('form-control', { 'is-invalid': emailError })}
									aria-describedby="emailHelp"
									placeholder="Email"
									value={email}
									onChange={(e) => {
										this.setState({
											email: e.target.value,
										}, emailError ? this.checkEmail : () => {
										});
									}}
									onBlur={this.checkEmail}
									disabled={this.state.loading}
								/>
								{emailError ? (
									<div className="invalid-feedback">{emailError}</div>
								) : ''}
							</div>
							<div className="col-12">
								<input
									type="text"
									className={classnames('form-control', { 'is-invalid': nameError })}
									placeholder="Name"
									value={name}
									onChange={(e) => {
										this.setState({
											name: e.target.value,
										}, nameError ? this.checkName : () => {
										});
									}}
									onBlur={this.checkName}
									disabled={this.state.loading}
								/>
								{nameError ? (
									<div className="invalid-feedback">{nameError}</div>
								) : ''}
							</div>
							<div className="col-12">
								<input
									type="text"
									className={classnames('form-control', { 'is-invalid': companyError })}
									placeholder="Company Name"
									value={company}
									onChange={(e) => {
										this.setState({
											company: e.target.value,
										}, companyError ? this.checkCompany : () => {
										});
									}}
									onBlur={this.checkCompany}
									disabled={this.state.loading}
								/>
								{companyError ? (
									<div className="invalid-feedback">{companyError}</div>
								) : ''}
							</div>
							<div className="col-12">
								<input
									type="text"
									className={classnames('form-control', { 'is-invalid': phoneError })}
									placeholder="Phone Number"
									value={phone}
									onChange={(e) => {
										this.setState({
											phone: e.target.value,
										}, phoneError ? this.checkPhone : () => {
										});
									}}
									onBlur={this.checkPhone}
									disabled={this.state.loading}
								/>
								{phoneError ? (
									<div className="invalid-feedback">{phoneError}</div>
								) : ''}
							</div>
							<div className="col-12">
								<div className="custom-control custom-checkbox d-flex justify-content-start">
									<input
										type="checkbox"
										className={classnames('form-control custom-control-input', { 'is-invalid': termsError })}
										id="termsCheck"
										checked={terms}
										onChange={(e) => {
											this.setState({
												terms: e.target.checked,
											}, this.checkTerms);
										}}
										disabled={this.state.loading}
									/>
									<label className="custom-control-label fs-13" htmlFor="termsCheck">I have read and
										agree to the <Link to="/terms">terms and conditions</Link></label>
								</div>
								{termsError ? (
									<div className="invalid-feedback db">{termsError}</div>
								) : ''}
							</div>
							<div className="col-12">
								<button
									className="btn btn-brand btn-block text-center"
									onClick={this.maybeLogin}
									disabled={this.state.loading}>
									Sign Up
									{this.state.loading ? (
										<Spinner />
									) : ''}
								</button>
							</div>
							<div className="col-12">
								<div className="rui-sign-or mt-2 mb-5">or</div>
							</div>
							<div className="col-12">
								<ul className="rui-social-links d-flex justify-content-center">
									<li>
										<button
											className="rui-social-facebook"
											onClick={this.maybeLogin}
											disabled={this.state.loading}>
											<Icon vendor="fa" name={['fab', 'facebook-f']} />
											Facebook
										</button>
									</li>
									<li>
										<button
											className="rui-social-google"
											onClick={this.maybeLogin}
											disabled={this.state.loading}>
											<Icon vendor="fa" name={['fab', 'google']} />
											Google
										</button>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<div className="mt-20 text-dark">
						Already have an account? <Link to="/sign-in" className="text-2">Sign In</Link>
					</div>
					<div className="mt-10 text-dark">
						<Link to="/privacy" className="text-2 font-weight-bold mr-10">Privacy</Link>
						<Link to="/terms" className="text-2 font-weight-bold">Terms</Link>
					</div>
				</div>
			</div>
		);
	}
}

export default connect(({ auth }) => (
	{
		auth,
	}
), { updateAuth: actionUpdateAuth, addToast: actionAddToast, })(AuthSignUpPage);
