/**
 * Internal Dependencies
 */
// Auth
import AuthSignIn from './AuthSignIn';
import AuthSignUp from './AuthSignUp';
// Start
import Dashboard from './Buyer/Dashboard';
import Bids from "./Buyer/Bids";
import Ads from "./Buyer/Ads";
import Account from "./Buyer/Account";
import Referral from "./Buyer/Referrals";
import Budget from "./Buyer/Budget";
import SellerDashboard from './Seller/Dashboard/Dashboard';
import SellerAds from './Seller/Ads/Ads';
import EditAds from "./Buyer/Ads/EditAds";
import SellerAccount from "./Seller/Account"
import ViewTransactions from "./Seller/Account/Transactions"
import Transactions from "./Buyer/Account/Transactions"
import AdminDashboard from "./Admin/Dashboard"
import AdminBids from "./Admin/Bids"
import AdminAds from "./Admin/Ads"
import AdminBudget from "./Admin/Budget"
import Privacy from "./Privacy";
import Terms from "./Terms";
import ForgotPassword from "./ForgotPassword/index.jsx";
import BuyerTerms from "./TopupTerms";
import Xero from "./Admin/Xero";
// Apps
// Content
// Components Base
// Components Advanced
// Forms
export const buyer = {
	'/budget': Budget,
	'/account': Account,
	'/referral': Referral,
	'/transactions': Transactions,
	'/bids': Bids,
	'/edit-ad': EditAds,
	'/ads': Ads,
	'/buyer-terms': BuyerTerms,
	'/': Dashboard,
};
export const seller = {
	'/account': SellerAccount,
	'/transactions': ViewTransactions,
	'/ads': SellerAds,
	'/': SellerDashboard,
}

export const admin = {
	'/budget': AdminBudget,
	'/ads': AdminAds,
	'/xero': Xero,
	'/bids': AdminBids,
	'/': AdminDashboard,
}
export default {
	'/sign-in': AuthSignIn,
	'/sign-up': AuthSignUp,
	'/privacy': Privacy,
	'/terms': Terms,
	'/reset-password': ForgotPassword
};
