import './style.scss';
import React, { Component, Suspense, lazy } from 'react';
// import PageWrap from '../../../components/page-wrap';
// import PageTitle from '../../../components/page-title';
// import PageContent from '../../../components/page-content';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { saveBudgetType } from "../../../api/buyer/budget";
import './Budget.css';
import Tabs from '../../../components/tabs';
import { getBudget } from "../../../api/admin/budget";
import select from 'classnames';

const PageWrap = lazy(() => import('../../../components/page-wrap'));
const PageTitle = lazy(() => import('../../../components/page-title'));
const PageContent = lazy(() => import('../../../components/page-content'));

export default class Budget extends Component {
	state = {
		loading: true,
		sellerBudgets: {},
		dailyBudgets: [],
		unlimited: [],
		buttonText: 'Unlimited',
		activeTab1: 'unlimited',
		activeTab2: 'unlimited',
		activeTab3: 'unlimited',
		activeTab4: 'unlimited',
	}

	unlimitedHandleChange = budgetType => () => {
		saveBudgetType({ budgetType });
		this.setState({ budgetType });
	}

	componentDidMount() {
		getBudget().then(memberBudgets => {
			this.setState(memberBudgets);
			this.setState({ loading: false })
		})
	}

	onDisplayBudgetHandler = (num, name) => {
		this.setState({ dailyBudget: true, dropdownOpen: false, memberBudget: false, buttonText: 'Account Level' });
		this.setState({ [`activeTab${num}`]: name, });
	}

	onDisplayMemberBudgetHandler = (num, name) => {
		this.setState({ memberBudget: true, dropdownOpen: false, dailyBudget: false, buttonText: 'Seller Level' });
		this.setState({ [`activeTab${num}`]: name });
	}

	disableBothBudgetsHandler = (num, name) => {
		this.setState({ dailyBudget: false, dropdownOpen: false, memberBudget: false, buttonText: 'Unlimited' });
		this.setState({ [`activeTab${num}`]: name });
	}

	budgetValueModalToggler = () => {
		this.setState({ isOpenModal: !this.state.isOpenModal });
	}

	memberBudgetValueModalToggler = (index) => {
		this.setState({ isOpen: !this.state.isOpen, index: index });
	}

	toggle = () => {
		this.setState({ dropdownOpen: !this.state.dropdownOpen })
	}

	toggleTab(num, name) {
		this.setState({ [`activeTab${num}`]: name });
	}

	render() {
		return (
			<Suspense>

				<PageWrap>
					<PageTitle className="pb-20">
						<h1 className="budget-style">Budget</h1>
					</PageTitle>
					{this.state.loading ?
						<div className="d-flex justify-content-center align-items-center" >
							<div class="spinner">
								<span class="ball-1"></span>
								<span class="ball-2"></span>
								<span class="ball-3"></span>
								<span class="ball-4"></span>
								<span class="ball-5"></span>
								<span class="ball-6"></span>
								<span class="ball-7"></span>
								<span class="ball-8"></span>
							</div>
						</div>
						:
						<PageContent>
							<div>
								<Tabs className="border-0">
									<Tabs.NavItem
										isActive={this.state.activeTab1 === 'unlimited'}
										onClick={() => this.disableBothBudgetsHandler(1, 'unlimited')}
									>
										Unlimited
							</Tabs.NavItem>
									<Tabs.NavItem
										isActive={this.state.activeTab1 === 'account'}
										onClick={() => this.onDisplayBudgetHandler(1, 'account')}
									>
										Account Level
							</Tabs.NavItem>
									<Tabs.NavItem
										isActive={this.state.activeTab1 === 'seller'}
										onClick={() => this.onDisplayMemberBudgetHandler(1, 'seller')}
									// onClick={ () => this.toggleTab( 1, 'seller' ) }
									>
										Seller Level
							</Tabs.NavItem>
								</Tabs>
								<Tabs.Content activeTab={this.state.activeTab1}>
									<Tabs.Pane tabId="unlimited">
										Unlimited budgeting allows for participation in every auction that occurs
										throughout
										the day.
								<div className="pt-20">
											{this.state.unlimited.map(p => {
												return (
													<div>
														<div className="row">
															<div className="col-2">
																<span>{p.name}</span>
															</div>
														</div>
														<hr align="left" width="50%" />
													</div>

												)
											})}
										</div>
									</Tabs.Pane>
									<Tabs.Pane tabId="account">
										<span>
											Seller level budgeting targets budget spend for the entire account, with no
											preference for which lead provider the budget it spent on.
								</span>
										<div className="pt-20">
											{this.state.dailyBudgets.map(p => {
												return (
													<div>
														<div className="row">
															<div className="col-2">
																<span>{p.name}</span>
															</div>
															<div className="col-2">
																<span>{p.budget}</span>
															</div>
															<div className="col-2">
																<span className={select({
																	'text-success': p.budgetStatus === 'Running',
																	'text-warning': p.budgetStatus === 'Budget Limited',
																	'text-danger': p.budgetStatus === 'Exhausted'
																})}>
																	{p.budgetStatus}
																</span>
															</div>
														</div>
														<hr align="left" width="50%" />
													</div>

												)
											})}
										</div>
										{/* {this.state.dailyBudget &&
									<ListGroup flush className="mt-10 col-6">
										<ListGroupItem>
											<div className="row align-items-center">
												<span style={{ fontSize: "17pt" }}>
													<span className="mr-25">Total Daily Budget</span> ${(+this.state.totalDailyBudget).toLocaleString()}</span>
												<Button onClick={this.budgetValueModalToggler}
													style={{ marginLeft: '15px' }}
													className="btn btn-link">
													<img src={editbudget} />
												</Button>
											</div>
										</ListGroupItem>
									</ListGroup>
								} */}

									</Tabs.Pane>
									<Tabs.Pane tabId="seller">
										Seller level budgeting targets budget spend on each lead provider. The total
										budget
										spend figure is the maximum spend if all budgets are fully exhausted in the
										day.

								{this.state.memberBudget && Object.keys(this.state.sellerBudgets).map((buyerId, index) =>
										<ListGroup flush className="mt-10 col-4">
											<div className="col font-weight-bold p-20 ">{this.state.sellerBudgets[buyerId][0].buyerName}</div>
											{this.state.sellerBudgets[buyerId].map(p =>
												<ListGroupItem className="pb-5">
													<div className="row">
														<div className="col ">{p.sellerName}</div>
														<div className="col"
															style={{ textAlign: "end" }}>$ {p.budget.toLocaleString()} per
												 day
												</div>
													</div>
												</ListGroupItem>
											)}
											<ListGroupItem>
												<div className="row">
													<div className="col font-weight-bold">Total</div>
													<div className="col font-weight-bold" style={{
														fontWeight: 600,
														color: '#4b515b',
														textAlign: "end",
														// paddingLeft:"115px"
													}}>$ {this.state.sellerBudgets[buyerId].reduce((p, c) => p + +c.budget, 0).toLocaleString()} per day
											</div>
												</div>
											</ListGroupItem>
										</ListGroup>
									)}
									</Tabs.Pane>
								</Tabs.Content>
							</div>


						</PageContent>
					}
				</PageWrap>
			</Suspense>
		);
	}
}