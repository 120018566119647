/**
 * App Settings
 */
import {BudgetIcon} from "./components/icons/budget";
import {AdIcon} from "./components/icons/ads";

const settings = {
    night_mode: false,
    spotlight_mode: false,
    show_section_lines: true,
    sidebar_dark: false,
    sidebar_static: false,
    sidebar_small: false,
    sidebar_effect: 'shrink',
    nav: true,
    nav_dark: false,
    nav_logo_path: require('./assets/images/logo.png'),
    nav_logo_white_path: require('./assets/images/logo.png'),
    nav_logo_width: '45px',
    nav_logo_url: '/',
    nav_align: 'left',
    nav_expand: 'lg',
    nav_sticky: true,
    nav_autohide: true,
    nav_container_fluid: true,
    home_url: '/',
    navigation: {},
    navigation_sidebar: {
        buyer: {
            '/': {
                label: '',
                name: 'Dashboard',
                icon: 'home',
            },
            '/bids': {
                label: '',
                name: 'Bids',
                icon: 'activity',
            },
            '/budget': {
                label: '',
                name: 'Budget',
                icon: BudgetIcon,
            },
            '/ads': {
                label: '',
                name: 'Ads',
                icon: AdIcon,
            },
            '/account': {
                label: '',
                name: 'Account',
                icon: 'user',
            },
            '/referral': {
                label: '',
                name: 'Referrals',
                icon: 'external-link',
            },
        },
        seller: {
            '/': {
                // label: 'Get Started',
                name: 'Dashboard',
                icon: 'home',
            },
            '/ads': {
                // label: 'Services',
                name: 'Ads',
                icon: AdIcon,
            },
            // '/invoices': {
            //     label: '',
            //     name: 'Invoices',
            //     icon: 'pocket',
            // },
            '/account': {
                // label: 'Account',
                name: 'Account',
                icon: 'user',
            },
        },
        admin: {
            '/': {
                // label: 'Get Started',
                name: 'Dashboard',
                icon: 'home',
            },
            '/bids': {
                label: '',
                name: 'Bids',
                icon: 'activity',
            },
            '/budget': {
                // label: '',
                name: 'Budget',
                icon: BudgetIcon,
            },
            '/ads': {
                // label: 'Services',
                name: 'Ads',
                icon: AdIcon,
            },
	        '/xero': {
		        // label: 'Services',
		        name: 'Xero',
		        icon: 'user',
	        },
        },
    },

	breadcrumbs_presets: {},
    img_country: {
        usa: require('./assets/images/flags/united-states-of-america.svg'),
        china: require('./assets/images/flags/china.svg'),
        germany: require('./assets/images/flags/germany.svg'),
        japan: require('./assets/images/flags/japan.svg'),
        spain: require('./assets/images/flags/spain.svg'),
        france: require('./assets/images/flags/france.svg'),
        canada: require('./assets/images/flags/canada.svg'),
        netherlands: require('./assets/images/flags/netherlands.svg'),
        italy: require('./assets/images/flags/italy.svg'),
        russia: require('./assets/images/flags/russia.svg'),
        czech_republic: require('./assets/images/flags/czech-republic.svg'),
    },
    img_file: {
        empty: require('./assets/images/icon-empty.svg'),
        zip: require('./assets/images/icon-zip.svg'),
        rar: require('./assets/images/icon-rar.svg'),
        html: require('./assets/images/icon-html.svg'),
        php: require('./assets/images/icon-php.svg'),
        css: require('./assets/images/icon-css.svg'),
        js: require('./assets/images/icon-js.svg'),
        doc: require('./assets/images/icon-doc.svg'),
        txt: require('./assets/images/icon-txt.svg'),
        pdf: require('./assets/images/icon-pdf.svg'),
        xls: require('./assets/images/icon-xls.svg'),
        png: require('./assets/images/icon-png.svg'),
        jpg: require('./assets/images/icon-jpg.svg'),
    },
    users: [
        {
            img: require('./assets/images/avatar-1.png'),
            img_profile: require('./assets/images/avatar-1-profile.png'),
            name: 'Jack Boyd',
        }, {
            img: require('./assets/images/avatar-2.png'),
            name: 'Helen Holt',
        }, {
            img: require('./assets/images/avatar-3.png'),
            name: 'Avice Harris',
        }, {
            img: require('./assets/images/avatar-4.png'),
            name: 'Anna Rice',
        }, {
            img: require('./assets/images/avatar-5.png'),
            name: 'Amber Smith',
        }, {
            img: require('./assets/images/avatar-6.png'),
            name: 'Mary Rose',
        },
    ],
    letters: [
        {
            img: require('./assets/images/letter-1.png'),
            img_min: require('./assets/images/letter-1-min.png'),
        }, {
            img: require('./assets/images/letter-2.png'),
            img_min: require('./assets/images/letter-2-min.png'),
        }, {
            img: require('./assets/images/letter-3.png'),
            img_min: require('./assets/images/letter-3-min.png'),
        }, {
            img: require('./assets/images/letter-4.png'),
            img_min: require('./assets/images/letter-4-min.png'),
        },
    ],
};

/* Parse GET variables to change initial settings */
const $_GET = {};
window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, (a, name, value) => {
    $_GET[name] = value;
});

Object.keys($_GET).forEach((name) => {
    const isTrue = $_GET[name] === '1';

    switch (name) {
        case 'setting-night-mode':
            settings.night_mode = isTrue;
            break;
        case 'setting-show-section-lines':
            settings.show_section_lines = isTrue;
            break;
        case 'setting-sidebar-small':
            settings.sidebar_small = isTrue;
            break;
        case 'setting-sidebar-dark':
            settings.sidebar_dark = isTrue;
            break;
        case 'setting-nav-dark':
            settings.nav_dark = isTrue;
            break;
        // no default
    }
});

export default settings;
