import './style.scss';


import React, {Component} from 'react'
import PageWrap from '../../../components/page-wrap';
import PageTitle from '../../../components/page-title';
import PageContent from '../../../components/page-content';
import {Button, CustomInput} from 'reactstrap';
import {changeAdStatus, getSellerAds, saveMaxOffers, saveReservedPrice} from "../../../api/seller/ads";
import edit from "../../../assets/icon/edit.svg";
import Icon from "../../../components/icon";
import EditAds from "./EditAds"
import Select from "react-select";

const offers = [
	{label: 'Unlimited', value: 10000},
	{label: '1', value: 1},
	{label: '2', value: 2},
	{label: '3', value: 3},
	{label: '4', value: 4},
	{label: '5', value: 5},
	{label: '6', value: 6},
	{label: '7', value: 7},
	{label: '8', value: 8},
	{label: '9', value: 9},
	{label: '10', value: 10},
];

class Ads extends Component {
	state = {
		loading: true,
		ads: [],
		reservedPrice: 0,
		maxOffers: 0,
		editReserve: false,
		editOffers: false,
		isOpen: false
	};


	componentDidMount() {
		getSellerAds()
			.then(ads => {
				this.setState(ads);
				this.setState({loading: false})
			})
	}

	adModalToggler = (index) => {
		this.setState({isOpen: !this.state.isOpen, index: index});
	}

	changeStatus = (enabled, id, buyerId) => {
		changeAdStatus({enabled, buyerId, id})
			.then(() => {
				const ads = this.state.ads;
				const adIndex = ads.findIndex(p => p.id === id);
				const ad = ads[adIndex];
				ad.enabled = enabled;
				this.setState({ads: Object.assign([], ads, {[adIndex]: ad})});
			});
	};

	savePrice = () => {
		if (this.state.editReserve && this.state.reservedPrice !== '') {
			const {reservedPrice} = this.state;
			saveReservedPrice({reservedPrice});
			this.setState({editReserve: false})
		} else {
			this.setState({editReserve: true})
		}
	};
	saveMaxOfferes = () => {
		if (this.state.editOffers && this.state.maxOffers) {
			const {maxOffers} = this.state;
			saveMaxOffers({maxOffers});
			this.setState({editOffers: false})
		} else {
			this.setState({editOffers: true})
		}
	};

	render() {
		const {editReserve, maxOffers, editOffers, reservedPrice} = this.state;
		return (
			<PageWrap>
				<PageTitle>
					<h1 className="ads-style">Ads</h1>
				</PageTitle>
				<PageContent>
					<EditAds
						ad={this.state.ads[this.state.index]}
						isOpen={this.state.isOpen}
						isClose={this.adModalToggler}
					/>
					{this.state.loading ?
						<div className="d-flex justify-content-center align-items-center">
							<div class="spinner">
								<span class="ball-1"></span>
								<span class="ball-2"></span>
								<span class="ball-3"></span>
								<span class="ball-4"></span>
								<span class="ball-5"></span>
								<span class="ball-6"></span>
								<span class="ball-7"></span>
								<span class="ball-8"></span>
							</div>
						</div>
						:
						<React.Fragment>
							<div className="card mb-10">
								<div className="card-body p-20">
									<div className="row align-items-center">
										<div className="col">
											<h3 className="ml-5 mb-0 transaction-style">Reserve Price</h3>
										</div>
										<div className="col">
											{!editReserve ? <h3 className="mb-0">
												{reservedPrice ? '$' + (+reservedPrice).toFixed(2).toLocaleString() : 'Not Set'}
											</h3> : <input type="number" value={reservedPrice}
											               onChange={e => this.setState({reservedPrice: e.target.value})}/>}
										</div>
										<div className="col" style={{textAlign: "end"}}>
											<Button onClick={() => this.savePrice()}
											        className="btn btn-outline-primary" style={{background: "none"}}>
												{!editReserve ? <img src={edit} className="p-2"/> :
													<Icon size={14} color="green" name="check"/>}
											</Button>
										</div>
									</div>
								</div>
							</div>
							<div className="card mb-10">
								<div className="card-body p-20">
									<div className="row align-items-center">
										<div className="col">
											<h3 className="ml-5 mb-0 transaction-style">Max Number of Offers Displayed</h3>
										</div>
										<div className="col">
											{!editOffers ? <h3 className="mb-0">
												{+maxOffers > 10 ? 'Unlimited' : maxOffers}
											</h3> : <Select
												className="variable-select"
												value={offers.find(p => p.value === maxOffers)}
												onChange={({value}) => this.setState({maxOffers: value})}
												options={offers}
											/>}
										</div>
										<div className="col" style={{textAlign: "end"}}>
											<Button onClick={() => this.saveMaxOfferes()}
											        className="btn btn-outline-primary" style={{background: "none"}}>
												{!editOffers ? <img src={edit} className="p-2"/> :
													<Icon size={14} color="green" name="check"/>}
											</Button>
										</div>
									</div>
								</div>
							</div>
							<div class="card">
								<table class="table table-hover">
									<thead class="card-header">
									<tr>
										<th scope="col" style={{width: '250px'}}>Lead Buyers</th>
										<th scope="col">Message</th>
										<th scope="col">Link</th>
										<th scope="col">Enabled</th>
										<th scope="col"></th>
									</tr>
									</thead>
									<tbody>
									{this.state.ads.map((p, index) => {
										return (
											<tr>
												<td>{p.name}</td>
												<td>{p.message}</td>
												<td><a href={p.link} target={p.link}>{p.link}</a></td>
												<td><CustomInput
													defaultChecked={!!p.enabled}
													onChange={() => this.changeStatus(!p.enabled, p.id, p.buyerId)}
													id={`formSwitch${p.id}`}
													name="turnOn"
													type="switch"
												/></td>
												<Button onClick={() => this.adModalToggler(index)}
												        className="btn btn-outline-brand mr-20 mt-10" style={{background: "none"}}>
													<Icon name="eye"/>
												</Button>
											</tr>
										)
									})}

									</tbody>
								</table>
							</div>
						</React.Fragment>
					}
				</PageContent>
			</PageWrap>
		)
	}
}

export default Ads
