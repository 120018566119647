import React, { Component } from 'react';
import { BrowserRouter, Route, withRouter } from 'react-router-dom';
import { connect, Provider } from 'react-redux';
import { applyMiddleware, createStore } from 'redux';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import './components/animated-route';
import reducers from './reducers';
import Routes from './Routes';
import PageYaybar from './components/page-yaybar';
import PageNavbar from './components/page-navbar';
import PageToasts from './components/page-toasts';
import { updateAuth as actionUpdateAuth } from './actions';
import { Auth } from "aws-amplify";

const createStoreWithMiddleware = applyMiddleware()(createStore);
const $html = window.jQuery('html');
const $body = window.jQuery('body');

class PageWrap extends Component {
	state = {};
	constructor(props) {
		super(props);
		this.maybeCheckAuth = this.maybeCheckAuth.bind(this);
		this.maybeUpdateGlobalSettings = this.maybeUpdateGlobalSettings.bind(this);
		this.maybeScrollPageToTop = this.maybeScrollPageToTop.bind(this);
	}

	async componentDidMount() {
		try {
			const user = await Auth.currentSession();
			this.setState({ userType: user.accessToken.payload['cognito:groups'][0] });
			console.log('test');
			this.props.updateAuth({ token: true });
		} catch { }
		this.maybeCheckAuth();
		this.maybeUpdateGlobalSettings();
	}

	componentWillReceiveProps(nextProps) {
		if (nextProps.auth.token && !this.props.auth.token) {
			Auth.currentSession()
				.then(user => this.setState({ userType: user.accessToken.payload['cognito:groups'][0] }));
		}
	}

	componentDidUpdate(prevProps) {
		this.maybeCheckAuth(prevProps);
		this.maybeUpdateGlobalSettings(prevProps);
		this.maybeScrollPageToTop(prevProps);
	}

	isSignPage(check) {
		if (!check)
			check = window.location.pathname.replace(/^#/g, '');
		return check === '/sign-in' || check === '/sign-up' || check === '/privacy' || check === '/terms' || check === '/reset-Password';
	}

	maybeCheckAuth(prevProps) {
		const { auth, updateAuth, history, } = this.props;
		let newRedirect = false;
		const referrerUrl = auth.referrer && !this.isSignPage(auth.referrer) ? auth.referrer : '/';
		if (this.isSignPage() && auth.token) newRedirect = referrerUrl;
		else if (!this.isSignPage() && !auth.token) newRedirect = '/sign-in';
		else if (prevProps && auth.token !== prevProps.auth.token) newRedirect = auth.token ? referrerUrl : '/sign-in';
		if (newRedirect) {
			updateAuth({
				referrer: window.location.pathname.replace(/^#/g, ''),
			});
			history.push(newRedirect);
		}
	}

	maybeUpdateGlobalSettings(prevProps) {
		const { settings } = this.props;
		if (prevProps && prevProps.settings.night_mode !== settings.night_mode) {
			if (settings.night_mode) {
				$html.addClass('rui-night-mode');
			} else {
				$html.removeClass('rui-night-mode');
			}
		}
		if (!prevProps && settings.night_mode) {
			$html.addClass('rui-night-mode');
		}
		if (prevProps && prevProps.settings.spotlight_mode !== settings.spotlight_mode) {
			if (settings.spotlight_mode) {
				$body.addClass('rui-spotlightmode');
			} else {
				$body.removeClass('rui-spotlightmode');
			}
		}
		if (!prevProps && settings.spotlight_mode) {
			$body.addClass('rui-spotlightmode');
		}

		// section lines.
		if (prevProps && prevProps.settings.show_section_lines !== settings.show_section_lines) {
			if (settings.show_section_lines) {
				$body.addClass('rui-section-lines');
			} else {
				$body.removeClass('rui-section-lines');
			}
		}
		if (!prevProps && settings.show_section_lines) {
			$body.addClass('rui-section-lines');
		}

		// sidebar small.
		if (prevProps && prevProps.settings.sidebar_small !== settings.sidebar_small) {
			if (settings.sidebar_small) {
				$body.addClass('yay-hide');
			} else {
				$body.removeClass('yay-hide');
			}
		}
		if (!prevProps && settings.sidebar_small) {
			$body.addClass('yay-hide');
		}
	}

	maybeScrollPageToTop(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			window.scrollTo({
				top: 0,
				behavior: 'smooth',
			});
		}
	}

	render() {
		const { auth, location } = this.props;
		return (
			<TransitionGroup>
				<PageToasts />
				<Route>
					{auth.token ? (
						<>
							<PageYaybar userType={this.state.userType} />
							{/*<Route component={PageYaybar}/>*/}
							<Route component={PageNavbar} />
						</>
					) : ''}
				</Route>
				<CSSTransition
					key={location.pathname}
					timeout={300}
					classNames="rui-router-transition"
					unmountOnExit>
					<Routes userType={this.state.userType} location={location} />
				</CSSTransition>
			</TransitionGroup>
		);
	}
}

const PageWrapWithState = connect(({ auth, settings }) => (
	{
		auth,
		settings,
	}
), { updateAuth: actionUpdateAuth })(withRouter(PageWrap));

/**
 * Component App
 */
class App extends Component {
	constructor(props) {
		super(props);
		this.store = createStoreWithMiddleware(reducers);
	}

	render() {
		return (
			<Provider store={this.store}>
				<BrowserRouter>
					<PageWrapWithState />
				</BrowserRouter>
			</Provider>
		);
	}
}

export default App;
