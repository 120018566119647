import React from 'react';
import ReactDOM from 'react-dom';
import './assets/font/inter.css'
import './index.css';
import './globals';
import './methods';
import 'react-dates/initialize';
import "react-image-crop/dist/ReactCrop.css";
import 'react-dates/lib/css/_datepicker.css';
import App from './App.jsx';
import * as serviceWorker from './serviceWorker';
import Amplify from 'aws-amplify';
import config from './config';
import TagManager from 'react-gtm-module'

const tagManagerArgs = {
	gtmId: 'GTM-58XFSCD'
};

TagManager.initialize(tagManagerArgs);

Amplify.configure({
	Auth: {
		mandatorySignIn: true,
		region: config.cognito.REGION,
		userPoolId: config.cognito.USER_POOL_ID,
		identityPoolId: config.cognito.IDENTITY_POOL_ID,
		userPoolWebClientId: config.cognito.APP_CLIENT_ID
	},
	Storage: {
		region: config.s3.REGION,
		bucket: config.s3.BUCKET,
		identityPoolId: config.cognito.IDENTITY_POOL_ID
	},
	API: {
		endpoints: [{
			name: "users",
			endpoint: config.apiGateway.URL,
			region: config.apiGateway.REGION
		}, {
			name: "buyer",
			endpoint: config.apiGateway.URL,
			region: config.apiGateway.REGION
		}, {
			name: "seller",
			endpoint: config.apiGateway.URL,
			region: config.apiGateway.REGION
		}, {
			name: "admin",
			endpoint: config.apiGateway.URL,
			region: config.apiGateway.REGION
		}, {
			name: "user",
			endpoint: config.apiGateway.URL,
			region: config.apiGateway.REGION
		},]
	}
});
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
