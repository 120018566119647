import React from 'react';

export const BudgetIcon = props => (
    <svg {...props} width="44px" height="45px" viewBox="0 0 44 45" version="1.1" fill="none" stroke="currentColor">
        <path
            d="M12,10.1785715 C5.78679656,10.1785715 0.75,15.215368 0.75,21.4285715 L0.75,41 C0.75,42.2426407 1.75735931,43.25 3,43.25 L41,43.25 C42.2426407,43.25 43.25,42.2426407 43.25,41 L43.25,21.4285715 C43.25,15.215368 38.2132034,10.1785715 32,10.1785715 L12,10.1785715 Z"
            id="Rectangle" strokeWidth="1.5"/>
        <path
            d="M23.9980469,30.2890625 C23.9980469,29.7356743 23.8173846,29.2766945 23.4560547,28.9121094 C23.0947248,28.5475242 22.4908896,28.2154963 21.6445313,27.9160156 C20.2708265,27.4472633 19.2405633,26.8824903 18.5537109,26.2216797 C17.8668585,25.5608691 17.5234375,24.660813 17.5234375,23.5214844 C17.5234375,22.4407498 17.8522103,21.5520868 18.5097656,20.8554688 C19.167321,20.1588507 20.0592392,19.7454434 21.1855469,19.6152344 L21.1855469,17.4863281 L22.7480469,17.4863281 L22.7480469,19.6347656 C23.8613337,19.8040373 24.7320932,20.2809206 25.3603516,21.0654297 C25.9886099,21.8499388 26.3027344,22.8899675 26.3027344,24.1855469 L23.9394531,24.1855469 C23.9394531,23.3717407 23.7571633,22.7272159 23.3925781,22.2519531 C23.027993,21.7766903 22.5266959,21.5390625 21.8886719,21.5390625 C21.2246061,21.5390625 20.7265642,21.7164696 20.3945313,22.0712891 C20.0624983,22.4261085 19.8964844,22.9029918 19.8964844,23.5019531 C19.8964844,24.0618518 20.0706363,24.5175764 20.4189453,24.8691406 C20.7672543,25.2207049 21.3938757,25.555988 22.2988281,25.875 C23.6725329,26.3697941 24.6946581,26.9427051 25.3652344,27.59375 C26.0358106,28.2447949 26.3710938,29.1367131 26.3710938,30.2695312 C26.3710938,31.395839 26.0293003,32.2942675 25.3457031,32.9648438 C24.662106,33.63542 23.7278705,34.0325515 22.5429688,34.15625 L22.5429688,36.0605469 L20.9902344,36.0605469 L20.9902344,34.1660156 C19.824864,34.0423171 18.8662147,33.6207718 18.1142578,32.9013672 C17.3623009,32.1819625 16.9993488,31.1224028 17.0253906,29.7226562 L17.0449219,29.6738281 L19.3496094,29.6738281 C19.3496094,30.5983119 19.5758441,31.2591126 20.0283203,31.65625 C20.4807965,32.0533874 21.0455695,32.2519531 21.7226563,32.2519531 C22.4453161,32.2519531 23.0052063,32.0761736 23.4023438,31.7246094 C23.7994812,31.3730451 23.9980469,30.8945343 23.9980469,30.2890625 Z"
            id="$"/>
        <path
            d="M15.7100844,9.977327 L11.9605711,1.40056599 C11.7393446,0.894525191 11.9702322,0.304958572 12.476273,0.083732086 C12.6025955,0.0285075104 12.7389726,9.7058818e-14 12.876839,9.63673585e-14 L30.2284689,9.63673585e-14 C30.7807536,9.50446601e-14 31.2284689,0.44771525 31.2284689,1 C31.2284689,1.12860582 31.2036618,1.25600434 31.1554071,1.37521399 L27.6733701,9.977327"
            id="Path-2" strokeWidth="1.5"/>
    </svg>
);
