import React, { Component } from 'react';
import { Button, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter } from 'reactstrap';
import { ClipLoader } from 'react-spinners';
import * as Yup from 'yup';
import { Formik } from 'formik';

const SignupSchema = Yup.object().shape({
	totalDailyBudget: Yup.number()
		.required('Required'),
});

class EditBudgetValueModal extends Component {
	state = {
		totalDailyBudget: '',
	}

	componentWillReceiveProps(nextProps) {
		const { totalDailyBudget } = nextProps;
		this.setState({ totalDailyBudget });
	}

	render() {
		return (
			<div>
				<Formik
					ref={this.form}
					initialValues={this.state}
					enableReinitialize={true}
					validationSchema={SignupSchema}
					onSubmit={(values) => {
						this.props.updatedDailyBudget(values);
					}}>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleSubmit,
						handleBlur,
					}) => (
						<Modal isOpen={this.props.isOpen}>
							<div className="modal-header">
								<h5 className="modal-title h2">Edit Daily Budget</h5>
								<Button className="close" onClick=""></Button>
							</div>
							<ModalBody>
								<FormGroup>
									<Label>Total Daily Budget</Label>
									<Input name="totalDailyBudget"
										invalid={errors.totalDailyBudget && touched.totalDailyBudget}
										value={values.totalDailyBudget}
										onChange={handleChange}
										onBlur={handleBlur} />
									<FormFeedback>
										{errors.totalDailyBudget && touched.totalDailyBudget && errors.totalDailyBudget}
									</FormFeedback>
								</FormGroup>
							</ModalBody>
							<ModalFooter className="justify-content-center">
								<Button color="primary" onClick={this.props.isLoading ? null : handleSubmit}>
									{this.props.isLoading ? <ClipLoader
										sizeUnit={"px"}
										size={20}
										color={'#fff'}
										loading={true}
									/> : 'Save'}
								</Button>
								<Button className="btn-outline-primary" style={{ background: "none", color: "#4FC1E9" }}
									onClick={this.props.isClose}>Close</Button>
							</ModalFooter>
						</Modal>
					)}
				</Formik>
			</div>
		);
	}
}
export default EditBudgetValueModal;