export default {
	s3: {
		REGION: "affiliate-admin",
		BUCKET: "ap-southeast-2"
	},
	apiGateway: {
		REGION: "ap-southeast-2",
		URL: process.env.REACT_APP_API,
	},
	cognito: {
		REGION: "ap-southeast-2",
		USER_POOL_ID: "ap-southeast-2_vk37Y82HC",
		APP_CLIENT_ID: "ln14u4g3gtdnv1499u5ikk2sh",
		IDENTITY_POOL_ID: "ap-southeast-2:ea179940-8bf6-4e14-b65a-d608e5f43a48"
	}
};
