/**
 * External Dependencies
 */
import React, {Component} from 'react';
/**
 * Internal Dependencies
 */
import PageWrap from '../../components/page-wrap';
import PageTitle from '../../components/page-title';
import PageContent from '../../components/page-content';
import './style.scss';

/**
 * Component
 */
class Terms extends Component {
	render() {
		return (
			<PageWrap>
				<PageTitle>
					<h1 className="title-style">Website Terms and Conditions</h1>
				</PageTitle>
				<PageContent>
					<p><span>Thank you for visiting our website. This website is owned and operated by MATCHLY PTY LTD ACN </span><span>639 337 796</span><span>. By accessing and/or using this website and related services, you agree to these Terms and Conditions, which include our Privacy Policy (</span><strong><em>Terms</em></strong><span>). You should review our Privacy Policy and these Terms carefully and immediately cease using our website if you do not agree to these Terms.&nbsp;</span>
					</p>
					<p>
						<span>In these Terms, 'us', 'we' and 'our' means MATCHLY PTY LTD ACN </span><span>639 337 796</span><span>.</span>
					</p>
					<ol>
						<li><strong>Registration</strong></li>
					</ol>
					<p><span>You </span><span>must be</span><span> a registered member to make orders and access the features of our website.&nbsp;</span>
					</p>
					<p><span>When you register and activate your account, you will provide us with personal information such as your name, company, email address, phone and authorized contact. You must ensure that this information is accurate and current. We will handle all personal information we collect in accordance with our </span><span>Privacy Policy</span><span>.</span>
					</p>
					<p><span>When you register and activate your account, we will provide you with a user name and password. You are responsible for keeping this user name and password secure and are responsible for all use and activity carried out under this user name.</span>
					</p>
					<p><span>&nbsp;To create an account, you must be:</span></p>
					<ul>
						<li><span>at least 18 years of age;</span></li>
						<li><span>possess the legal right and ability to enter into a legally binding agreement with us on behalf of the company you represent in your registration; and</span>
						</li>
						<li><span>agree and warrant to use the website in accordance with these Terms.</span></li>
					</ul>
					<ol>
						<li><strong>Accuracy, completeness and timeliness of information</strong></li>
					</ol>
					<p><span>The information on our website is not comprehensive and is intended to provide a summary of the subject matter covered. While we use all reasonable attempts to ensure the accuracy and completeness of the information on our website, to the extent permitted by law, including the Australian Consumer Law, we make no warranty regarding the information on this website. You should monitor any changes to the information contained on this website.</span>
					</p>
					<p><span>We are not liable to you or anyone else if interference with or damage to your computer systems occurs in connection with the use of this website or a linked website. You must take your own precautions to ensure that whatever you select for your use from our website is free of viruses or anything else (such as worms or Trojan horses) that may interfere with or damage the operations of your computer systems.</span>
					</p>
					<p><span>We may, from time to time and without notice, change or add to the website (including the Terms) or the information, products or services described in it. However, we do not undertake to keep the website updated. We are not liable to you or anyone else if errors occur in the information on the website or if that information is not up-to-date.</span>
					</p>
					<ol>
						<li><strong>Linked sites</strong></li>
					</ol>
					<p><span>Our website may contain links to websites operated by third parties. Those links are provided for convenience and may not remain current or be maintained. Unless expressly stated otherwise, we do not endorse and are not responsible for the content on those linked websites and have no control over or rights in those linked websites.&nbsp;</span>
					</p>
					<ol>
						<li><strong>Intellectual property rights</strong></li>
					</ol>
					<p><span>Unless otherwise indicated, we own or license from third parties all rights, title and interest (including copyright, designs, patents, trademarks and other intellectual property rights) in this website and in all of the material (including all text, graphics, logos, audio and software) made available on this website (</span><strong><em>Content</em></strong><span>).&nbsp;</span>
					</p>
					<p><span>Your use of this website and use of and access to any Content does not grant or transfer any rights, title or interest to you in relation to this website or the Content. However we do grant you a licence to access the website and view the Content on the terms and conditions set out in this Agreement and, where applicable, as expressly authorised by us and/or our third party licensors.</span>
					</p>
					<p><span>Any reproduction or redistribution of this website or the Content is prohibited and may result in civil and criminal penalties. In addition, you must not copy the Content to any other server, location or support for publication, reproduction or distribution is expressly prohibited.&nbsp;</span>
					</p>
					<p><span>All other use, copying or reproduction of this website, the Content or any part of it is prohibited, except to the extent permitted by law.&nbsp;&nbsp;</span>
					</p>
					<ol>
						<li><strong>Unacceptable activity</strong></li>
					</ol>
					<p><span>You must not do any act that we would deem to be inappropriate, is unlawful or is prohibited by any laws applicable to our website, including but not limited to:</span>
					</p>
					<ul>
						<li><span>any act that would constitute a breach of either the privacy (including uploading private or personal information without an individual's consent) or any other of the legal rights of individuals;</span>
						</li>
						<li><span>using this website to defame or libel us, our employees or other individuals;</span></li>
						<li><span>uploading files that contain viruses that may cause damage to our property or the property of other individuals;</span>
						</li>
						<li><span>posting or transmitting to this website any non-authorised material including, but not limited to, material that is, in our opinion, likely to cause annoyance, or which is defamatory, racist, obscene, threatening, pornographic or otherwise or which is detrimental to or in violation of our systems or a third party's systems or network security.&nbsp;</span>
						</li>
					</ul>
					<p><span>If we allow you to post any information to our website, we have the right to take down this information at our sole discretion and without notice.</span>
					</p>
					<ol>
						<li><strong>Warranties and disclaimers</strong></li>
					</ol>
					<p><span>To the maximum extent permitted by law, including the Australian Consumer Law, we make no warranties or representations about this website or the Content, including but not limited to warranties or representations that they will be complete, accurate or up-to-date, that access will be uninterrupted or error-free or free from viruses, or that this website will be secure.</span>
					</p>
					<p><span>We reserve the right to restrict, suspend or terminate without notice your access to this website, any Content, or any feature of this website at any time without notice and we will not be responsible for any loss, cost, damage or liability that may arise as a result.&nbsp;</span>
					</p>
					<ol>
						<li><strong>Liability</strong></li>
					</ol>
					<p><span>To the maximum extent permitted by law, including the Australian Consumer Law, in no event shall we be liable for any direct and indirect loss, damage or expense &ndash; irrespective of the manner in which it occurs &ndash; which may be suffered due to your use of our website and/or the information or materials contained on it, or as a result of the inaccessibility of this website and/or the fact that certain information or materials contained on it are incorrect, incomplete or not up-to-date.</span>
					</p>
					<ol>
						<li><strong>Jurisdiction and governing law</strong></li>
					</ol>
					<p><span>Your use of the website and these Terms are governed by the law of New South Wales, Australia and you submit to the non-exclusive jurisdiction of the courts exercising jurisdiction in New South Wales, Australia.</span>
					</p></PageContent>
			</PageWrap>
		);
	}
}

export default Terms;
