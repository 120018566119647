import './style.scss';
import React, { Component } from 'react';
import { Button, Col, FormFeedback, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, Popover, PopoverBody, PopoverHeader, Row, Table } from 'reactstrap'
import edit from '../../../assets/icon/edit.svg'
import Icon from '../../../components/icon';
import PageWrap from '../../../components/page-wrap';
import PageTitle from '../../../components/page-title';
import PageContent from '../../../components/page-content';
import Dropdown from '../../../components/bs-dropdown';
import { attachAd, createBid, deleteBid, getBids, getSellers, updateBid, updateSelectedVariables } from "../../../api/buyer/bids";
import { ClipLoader } from 'react-spinners';
import { camelToTitle } from "../../../utils";
import { getBudget } from "../../../api/buyer/budget";
import UpdateBidModal from './UpdateBid';
import PopoverControl from './PopoverControl';
import Select from "react-select";
import classNames from 'classnames';
import AttachAd from "./AttachBid";
import Payper from './Payper';
import { Builder, Query, Utils as QbUtils } from 'react-awesome-query-builder';
import "antd/dist/antd.css"
import AntdConfig from 'react-awesome-query-builder/lib/config/antd';
import 'react-awesome-query-builder/lib/css/styles.css';
import 'react-awesome-query-builder/lib/css/compact_styles.css'; //optional, for more compact styles
import QueryPopover from './QueryPopover';
import * as Yup from 'yup';
import { Formik } from 'formik';
import close from '../../../assets/icon/close-circle-line.svg'

const InitialConfig = AntdConfig;
const SignupSchema = Yup.object().shape({
	bidLabel: Yup.string()
		.required('Label name is required'),
});

class Bids extends Component {
	state = {
		sellerBudgets: [],
		bids: [],
		sellers: [],
		seller: null,
		isLoading: false,
		modalLoading: false,
		modal: false,
		selectedOption: null,
		variables: [],
		updateBid: {},
		deleteBidInProgress: false,
		editVariables: false,
		attachAd: false,
		saveSelectedVariablesInProcess: false,
		isLocked: false,
		popoverOpen: false,
		layout: '',
		config: [],
		varType: [],
		queryModal: false,
		queryLabel: '',
		tree: '',
		isOpen: ''
	}

	toggle = () => {
		this.setState({ popoverOpen: !this.state.popoverOpen })
	}

	toggleQueryCloseHandler = () => {
		this.setState({ queryModal: !this.state.queryModal})
	}

	toggleQueryHandler = (index) => {
		const bid = this.state.bids[index];
		console.log(this.state.bids, index)
		this.setState({
			queryModal: !this.state.queryModal,
			popoverOpen: false,
			index,
			bidLabel: bid ? bid.bidLabel : '',
			tree: QbUtils.checkTree(bid && bid.query ? QbUtils.loadFromJsonLogic(bid.query.logic, this.state.config) : QbUtils.loadTree({
				id: QbUtils.uuid(),
				type: "group"
			}), this.state.config),
		})
	}

	saveQueryHandler = ({ bidLabel }) => {
		if (!this.state.index) {
			const bid = {
				payType: 'referral',
				bid: 0, bidType: 'query',
				query: QbUtils.jsonLogicFormat(this.state.tree, this.state.config),
				bidLabel
			};
			this.setState(s => ({
				...s,
				queryModal: false,
				isOpen: true,
				popoverOpen: false,
				bids: s.bids.concat(bid)
			}));
		} else {
			const bid = this.state.bids[this.state.index];
			bid.query = QbUtils.jsonLogicFormat(this.state.tree, this.state.config);
			bid.bidLabel = bidLabel;
			this.setState(s => ({
				...s,
				updateBidInProgress: true,
				bids: Object.assign(this.state.bids, { [this.state.index]: bid })
			}));
			if (bid.id)
				this.updatedQueryHandler(bid);
			else
				this.setState({ updateBidInProgress: false, queryModal: false, popoverOpen: false, isOpen: true });
		}
	};

	addBidHandler = (bidType) => {
		const { variables, } = this.state;
		const bid = variables.reduce((p, { name, options, id, varType }) => ({
			...p,
			payType: 'referral',
			[name]: { value: varType === 'integer' ? [0, 100000] : options.split('||').join(','), options, varType }
		}), {});
		this.setState(s => ({ ...s, queryModal: false, popoverOpen: false, bids: s.bids.concat({ bid: 0, bidType, ...bid }) }));
	}

	componentDidMount() {
		this.setState({ isLoading: true });
		getBudget().then(({ totalDailyBudget, sellerBudgets }) => {
			this.setState({ totalDailyBudget, sellerBudgets })
		});
		getSellers().then(({ sellers, ...bids }) => {
			this.setState({ sellers, seller: sellers[0], isLoading: false });
			const fields = bids.variables.reduce((p, c) => {
				let fields = {};
				if (c.varType === 'category') {
					const options = c.options.split('||');
					fields = {
						label: camelToTitle(c.name),
						type: 'select',
						valueSources: ['value'],
						fieldSettings: {
							listValues: options.map(o => ({ value: o, title: o }))
						},
						preferWidgets: ['number'],
					}
				} else {
					fields = {
						label: camelToTitle(c.name),
						type: 'number',
						valueSources: ['value'],
						fieldSettings: {
							min: 0,
							max: 100000,
						},
						preferWidgets: ['slider', 'rangeslider'],
					}
				}
				return { ...p, [c.name]: fields };
			}, {});
			const config = {
				...InitialConfig,
				fields
			};
			this.setState({
				...bids,
				bids: bids.bids.map(p => ({
					...p,
					tree: p.query ? QbUtils.checkTree(QbUtils.loadFromJsonLogic(p.query.logic, config), config) : null,
				})),
				isLocked: !!bids.isLocked,
				config,
			});
		})
	}

	sellerChangeHandler = seller => () => {
		this.setState({ seller, isLoading: true });
		getBids(seller.id).then(bids => {
			// config...
			const fields = bids.variables.reduce((p, c) => {
				let fields = {};
				if (c.varType === 'category') {
					const options = c.options.split('||');
					fields = {
						label: camelToTitle(c.name),
						type: 'select',
						valueSources: ['value'],
						fieldSettings: {
							listValues: options.map(o => ({ value: o, title: o }))
						},
						preferWidgets: ['number'],
					}
				} else {
					fields = {
						label: camelToTitle(c.name),
						type: 'number',
						valueSources: ['value'],
						fieldSettings: {
							min: 0,
							max: 100000,
						},
						preferWidgets: ['slider', 'rangeslider'],
					}
				}
				return { ...p, [c.name]: fields };
			}, {});
			const config = { ...InitialConfig, fields };
			this.setState({
				...bids,
				bids: bids.bids.map(p => ({
					...p,
					tree: p.query ? QbUtils.checkTree(QbUtils.loadFromJsonLogic(p.query.logic, config), config) : null,
				})),
				isLocked: !!bids.isLocked,
				config,
			});
			this.setState({ isLoading: false });
		});
	};

	onClickHandler = value => () => {
		this.setState(s => {
			const index = s.variables.findIndex(v => v === value);
			const variable = s.variables[index];
			variable.selected = !variable.selected;
			return { variables: Object.assign(s.variables, { [index]: variable }) };
		})
	};

	budgetClickHandler = () => {
		this.props.history.push('/budget');
	};

	updatedBidHandler = (bid, index) => {
		const bids = this.state.bids;
		bids[index] = bid;
		this.setState({ bids });
	};

	updatedQueryHandler = (bid) => {
		this.setState({ updateBidInProgress: true });
		updateBid({ id: bid.id, bidType: 'query', query: bid.query, bidLabel: bid.bidLabel })
			.then(response => {
				this.setState({ updateBidInProgress: false, queryModal: false, popoverOpen: false, });
			});
	};

	variableChangeHandler = (variable, index) => value => {
		const bids = this.state.bids;
		const bid = bids[index];
		bid[variable] = value;
		this.setState({ bids });
	};

	saveBidHandler = (index) => {
		this.setState({ isSaveInProcess: true, index });
		const bids = this.state.bids;
		const bid = bids[index];
		let variables = [];
		if (bid.bidType === 'simple')
			variables = this.state.variables.filter(p => p.selected).map(({ id, name, varType }) => ({
				variableId: id,
				categoryValue: varType === 'category' ? bid[name].value : '',
				intFrom: varType === 'category' ? 0 : bid[name].value[0],
				intTo: varType === 'category' ? 0 : bid[name].value[1],
			}));
		createBid({
			variables,
			bid: bid.bid,
			bidType: bid.bidType,
			bidLabel: bid.bidLabel,
			query: bid.query,
			sellerId: this.state.seller.id
		})
			.then(({ id }) => {
				bid.id = id;
				this.setState({ bids });
				this.setState({ isSaveInProcess: false });
				this.sellerChangeHandler(this.state.seller)();
			});
	};

	deleteBid = (id, index) => {
		this.setState({ isDeleteInProcess: true, index });
		deleteBid(id)
			.then(() => this.setState(s => ({ isDeleteInProcess: false, bids: s.bids.filter(b => b.id !== id) })))
	};

	saveVariablesHandler = () => {
		this.setState({ saveSelectedVariablesInProcess: true });
		const ids = this.state.variables.filter(p => p.selected).map(p => p.id);
		updateSelectedVariables(this.state.seller.id, { ids })
			.then(() => {
				this.setState({ editVariables: false, saveSelectedVariablesInProcess: false });
				this.sellerChangeHandler(this.state.seller)();
				document.body.classList.remove('no-pointer');
			});
	};

	editVariablesHandler = () => {
		document.body.classList.add('no-pointer');
		this.setState({ editVariables: true, })
	};

	attachAdHandler = (adId) => {
		if (this.state.attachAd.id)
			attachAd({ adId, bidId: this.state.attachAd.id })
				.then(() => {
					const bidIndex = this.state.bids.findIndex(p => p.id === this.state.attachAd.id);
					const bid = this.state.bids[bidIndex];
					bid.adId = adId;
					this.setState(s => ({ attachAd: null, bids: Object.assign(s.bids, { [bidIndex]: bid }) }));
				});
		else {
			const bidIndex = this.state.bids.findIndex(b => b === this.state.attachAd);
			const bid = this.state.bids[bidIndex];
			if (bid) {
				bid.adId = adId;
				this.setState(s => ({ attachAd: null, bids: Object.assign(s.bids, { [bidIndex]: bid }) }));
			}
		}
	};

	renderBuilder = (props) => (
		<div className="query-builder-container" style={{ padding: '10px' }}>
			<div className="query-builder  ">
				<Builder {...props} />
			</div>
		</div>
	)

	onChange = (immutableTree, config) => {
		this.setState({ tree: immutableTree, config: config, });
	}


	render() {
		const { editVariables, saveSelectedVariablesInProcess, variables, sellers, seller, isLocked, updateBidInProgress } = this.state;
		const sellerVariables = variables.filter(v => v.selected && v.type === 'Seller');
		const buyerVariables = variables.filter(v => v.selected && v.type === 'Buyer');
		return (
			<PageWrap>
				<PageTitle>
					<h1 className="bid-title-style">Bidding</h1>
				</PageTitle>
				{this.state.isLoading ?
					<div className="d-flex justify-content-center align-items-center">
						<div class="spinner">
							<span class="ball-1"></span>
							<span class="ball-2"></span>
							<span class="ball-3"></span>
							<span class="ball-4"></span>
							<span class="ball-5"></span>
							<span class="ball-6"></span>
							<span class="ball-7"></span>
							<span class="ball-8"></span>
						</div>
					</div>
					:
					<PageContent>
						{isLocked && <div className="d-flex makePayment align-items-center">
							<Icon className="text-white" name="alert-circle" />
							<span className="h3 pl-10 m-0 text-white">Your bids are locked.</span>
						</div>}
						<AttachAd isOpen={this.state.attachAd}
							seller={seller}
							attachBid={this.attachAdHandler}
							isClose={() => this.setState({ attachAd: null })} />
						<Modal isOpen={this.state.queryModal}>
							<Formik
								initialValues={{ bidLabel: this.state.bidLabel }}
								validationSchema={SignupSchema}
								onSubmit={(values) => {
									this.saveQueryHandler(values);
								}}>
								{({
									values,
									errors,
									touched,
									handleChange,
									handleSubmit,
									handleBlur,
								}) => (
									<>
										<ModalBody>
											<FormGroup className="input-query">
												<Label className="popup-title-style-1">Enter Label Name</Label>
												<Input
													type="text"
													name="bidLabel"
													invalid={errors.bidLabel && touched.bidLabel}
													onChange={handleChange}
													onBlur={handleBlur}
													value={values.bidLabel}
													placeholder="Enter name" />
												<FormFeedback>
													{errors.bidLabel && touched.bidLabel && errors.bidLabel}
												</FormFeedback>
											</FormGroup>
											<Query
												{...this.state.config}
												value={this.state.tree}
												onChange={this.onChange}
												renderBuilder={this.renderBuilder}
											/>
											<ModalFooter className="p-0 pt-10 pb-0 ">
												<Button className="btn btn-blue modal-btn" onClick={handleSubmit} disabled={!QbUtils.mongodbFormat(this.state.tree, this.state.config)}  >{updateBidInProgress ?
													<ClipLoader
														sizeUnit={"px"}
														size={20}
														color={"#fff"}
														loading={true}
													/> : 'Save'}</Button>{' '}
												<Button color="secondary" onClick={this.toggleQueryCloseHandler}>Cancel</Button>
											</ModalFooter>
										</ModalBody>
									</>
								)}
							</Formik>
						</Modal>
						<Row style={{ marginTop: "21px", marginBottom: "25px" }}>
							<Col>
								<div className="d-flex align-items-center">
									<Dropdown
										showTriangle
										className="dib">
										<Dropdown.Toggle tag="button"
											className="btn btn-outline-primary btn-long justify-content-end">
											<span className="text">{seller ? seller.name : 'Member'}</span>
											<span className="icon">
												<Icon name="chevron-down" />
											</span>
										</Dropdown.Toggle>
										<Dropdown.Menu tag="ul" className="nav text-left dropdown-style">
											{sellers.map(s => <li onClick={this.sellerChangeHandler(s)}>
												<span>{s.name}</span>
												<span className="rui-nav-circle"></span>
											</li>)}
										</Dropdown.Menu>
									</Dropdown>
									{!this.state.seller &&
										<span className="h1 ml-15 mb-0">Please select seller</span>}
								</div>
							</Col>
						</Row>

						{this.state.seller &&
							<div>
								<Row>
									<div id="variables" className="d-flex justify-content-start w-100 mb-10">
										<Col>
											<Row>
												<div className="d-flex m-15 align-items-center">
													<span className="h1 mb-5 mt-5 var-style">Variables</span>
													{editVariables && <React.Fragment>
														<span className="h3 mb-0 ml-20 mr-10">Seller Variables</span>
														<Select
															className="variable-select"
															value={null}
															onChange={({ value }) => this.onClickHandler(value)()}
															options={variables.filter(v => !v.selected && v.type === 'Seller').map(v => ({
																value: v,
																label: v.name
															}))} />
														<span className="h3 mb-0 ml-20 mr-10">Buyer Variables</span>
														<Select
															classNamePrefix="react-select"
															className="variable-select"
															value={null}
															onChange={({ value }) => this.onClickHandler(value)()}
															options={variables.filter(v => !v.selected && v.type === 'Buyer').map(v => ({
																value: v,
																label: v.name
															}))} />
														<Button color="primary" className="ml-15"
															onClick={this.saveVariablesHandler}>
															{saveSelectedVariablesInProcess ? <ClipLoader
																sizeUnit={"px"}
																size={20}
																color={"#fff"}
																loading={true}
															/> : <Icon size={14} color="#fff" name="check" />}
														</Button>
													</React.Fragment>}
												</div>
											</Row>
											<div className={classNames("d-flex border p-20", { 'bg-grey-1': editVariables })}>
												<div>
													<Row className=" ml-1" style={{ paddingTop: "8px", paddingBottom: "17px" }}>
														{sellerVariables.map((p) => {
															return (
																<Button
																	className="mb-10 p-8 fs-10 mr-5 ml-5 d-flex align-items-center btn-round"
																	color={"outline-primary"}
																	onClick={editVariables ? this.onClickHandler(p) : null}>
																	<span>{camelToTitle(p.name)}</span>
																	{editVariables &&
																		<span className="chip-ion text-green"><Icon size={10}
																			name="x" /></span>}
																</Button>
															)
														})}
													</Row>
													<Row className="ml-1">
														{buyerVariables.map((p) => {
															return (
																<Button
																	className="mb-10 p-8 fs-10 mr-5 ml-5 d-flex align-items-center text-center btn-round"
																	color={"blue"}
																	onClick={editVariables ? this.onClickHandler(p) : null}>
																	<span>{camelToTitle(p.name)}</span>
																	{editVariables &&
																		<span className="chip-ion text-blue"><Icon size={10}
																			name="x" /></span>}
																</Button>
															)
														})}
													</Row>
												</div>
												<div className="ml-auto mr-10 mt-10">
													{!editVariables && <Button className="btn btn-outline-primary " style={{ background: "none" }}
														onClick={this.editVariablesHandler} disabled={isLocked}>
														<img src={edit} className="p-2" />
													</Button>}
												</div>
											</div>
										</Col>
									</div>
								</Row>
								<div>
									{seller &&
										<div>
											<div className=" card table-responsive">
												<Table className="table table-hover table-sm">
													<thead className="card-header">
														<tr>
															<th scope="col" className="font-weight-bold">#</th>
															{sellerVariables.map(v =>
																<th scope="col" className="font-weight-bold">
																	{camelToTitle(v.name)}
																</th>
															)}
															{buyerVariables.map(v =>
																<th scope="col" className="font-weight-bold">
																	{camelToTitle(v.name)}
																</th>
															)}
															<th scope="col" className="font-weight-bold">Pay Type</th>
															<th scope="col" className="font-weight-bold">Bid</th>
															<th scope="col" className="font-weight-bold"></th>
															<th scope="col" className="font-weight-bold"></th>
														</tr>
													</thead>
													<tbody>
														{(this.state.bids).map((b, index) => {
															return (
																<tr>
																	<td scope="row" style={{ verticalAlign: "middle" }}>
																		{b.isDefault ? 'Default Bid' : (index)}
																	</td>
																	{b.bidType === 'simple' &&
																		<>
																			{sellerVariables.map((v) =>
																				<td style={{ verticalAlign: "middle" }}>
																					<PopoverControl editVariables={editVariables} bid={b} variable={v}
																						isLocked={this.state.isLocked}
																						onChange={this.variableChangeHandler(v.name, index)} />
																				</td>
																			)}
																			{buyerVariables.map(v =>
																				<td style={{ verticalAlign: "middle" }}>
																					<PopoverControl editVariables={editVariables} bid={b} variable={v}
																						isLocked={this.state.isLocked}
																						onChange={this.variableChangeHandler(v.name, index)} />
																				</td>
																			)}
																		</>
																	}
																	{b.bidType === 'query' &&
																		<td colSpan={buyerVariables.length + sellerVariables.length}>
																			<QueryPopover isOpen={() => this.toggleQueryHandler(index)}
																				editVariables={editVariables} label={b.bidLabel}
																				isLocked={this.state.isLocked} />

																		</td>
																	}

																	<td style={{ verticalAlign: "middle" }}>
																		{b.id &&
																			<Payper editVariables={editVariables} sellerId={seller.id} bid={b}
																				updatePayType={bid => this.updatedBidHandler(bid, index)}
																				isLocked={this.state.isLocked} />}
																	</td>
																	<td className="w-10" style={{ verticalAlign: "middle" }}>
																		{b.id &&
																			<UpdateBidModal bid={b} editVariables={editVariables} isLocked={this.state.isLocked}
																				updatedBid={(updateBid) => this.updatedBidHandler(updateBid, index)} />}
																	</td>

																	<td className="" style={{ verticalAlign: "middle" }}>
																		{!b.isDefault && <Button className="btn btn-outline-primary"
																			disabled={isLocked === true}
																			style={{ background: "none", color: "#4FC1E9" }}
																			onClick={() => this.setState({ attachAd: b })}>
																			{b.adId ? <Icon name="copy" size={14}
																				className="p-2" /> :
																				<Icon name={["fas", "plus"]} size={14}
																					vendor="fa"
																					className="p-2" />}
																		</Button>}
																	</td>
																	<td className="" style={{ verticalAlign: "middle" }}>
																		{!b.isDefault && <React.Fragment>
																			{b.id ?
																				<Button className="btn btn-outline-primary"
																					disabled={this.state.editVariables === true || isLocked === true}
																					style={{ background: "none", color: "#4FC1E9" }}
																					onClick={() => this.deleteBid(b.id, index)}>
																					{this.state.isDeleteInProcess && this.state.index === index ?
																						<ClipLoader
																							sizeUnit={"px"}
																							size={20}
																							color={"#4FC1E9"}
																							loading={true}
																						/> :
																						<Icon name={["fas", "trash"]} size={14}
																							vendor="fa"
																							className="p-2" />}
																				</Button> :
																				<Button className="" onClick={() => this.saveBidHandler(index)}
																					color="primary">
																					{this.state.isSaveInProcess && this.state.index === index ?
																						<ClipLoader
																							sizeUnit={"px"}
																							size={20}
																							color={"#fff"}
																							loading={true}
																						/> : <Icon size={14} name={["fas", "save"]}
																							vendor="fa"
																							className="p-2" />}
																				</Button>}
																		</React.Fragment>}
																	</td>
																</tr>
															);
														})}

														<Popover
															isOpen={this.state.popoverOpen}
															disabled={isLocked === true}
															target="PopoverFocus"
															trigger="legacy"
															toggle={this.toggle}
															backdrop={true}>
															<PopoverHeader className="d-flex justify-content-end py-5 px-5" onClick={this.toggle}><img
																src={close} alt="" /></PopoverHeader>
															<PopoverBody>
																<button className="btn-round btn btn-outline-primary mr-4" value={this.state.layout}
																	onClick={() => this.addBidHandler('simple')}>Simple Layout
																</button>
																<button className="btn-round btn btn-outline-primary" value={this.state.layout}
																	onClick={() => this.toggleQueryHandler()}>Query Layout
																</button>
															</PopoverBody>
														</Popover>
														<tr>
															<td colSpan={4 + buyerVariables.length + sellerVariables.length}>
																<Button
																	id="PopoverFocus"
																	disabled={this.state.editVariables === true || isLocked === true}
																	className="btn-uniform btn-round btn btn-outline-primary"
																	style={{ background: "none", color: "#4FC1E9" }}
																	// onClick={this.addBidHandler}
																	onClick={this.toggle}>
																	<Icon size={14} name={["fas", "plus"]} vendor="fa"
																		className="p-2" />
																</Button>
															</td>
														</tr>
													</tbody>
												</Table>
											</div>
										</div>}
								</div>
							</div>}
					</PageContent>}
			</PageWrap>
		);
	}
}
export default Bids;