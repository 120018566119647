import React, { Component } from 'react'
import { Button } from "reactstrap";
import edit2 from "../../../assets/icon/edit2.svg";

export default class QueryPopover extends Component {
	state = {
		show: false,
		isOpen: false,
	}

	toggleHover = (show) => {
		if (this.props.isLocked) return null
		else this.setState({ show });
	}
	togglePopUp = () => {
		if (this.props.isLocked) return null
		else {
			this.setState({ isOpen: false });
			this.props.isOpen(true)
		}
	}

	toggleHoverLeave = (show) => this.setState({ show });

	render() {
		const { label } = this.props;
		return (
			<div onMouseEnter={() => this.toggleHover(true)} onMouseLeave={() => this.toggleHoverLeave(false)}>
				<div className="d-flex align-items-center justify-content-center">
					<div className="cursor-pointer pr-0">
						<p className="mb-0">
							<div className="d-flex justify-content-center">
								<span>{label}</span>
							</div>
						</p>
					</div>
					<div className=" pl-0">
						<div>
							<Button
								hidden={!((this.state.show && !this.props.editVariables) || this.state.isOpen)}
								className="btn btn-link pt-0 pb-0"><img
									src={edit2} className="pr-5" onClick={this.togglePopUp} /> </Button>
						</div>
					</div>
				</div>
			</div>
		)
	}
}
